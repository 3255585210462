import { Chart } from "react-chartjs-2";
import {
  type ChartData,
  ChartOptions,
} from "chart.js";
import {
  DIRECTION_ASCENDING,
  DIRECTION_DESCENDING,
  DIRECTION_UNKNOWN,
} from "@common/model/Direction";
import {
  PHASES_IGNORE_REASON_VISIBLE,
  Phase,
} from "@common/model/Phase";
import { Serie } from "@common/model/Serie";
import { SpeedSuggestedOutput } from "@common/service/serieService";
import { Trans } from "@lingui/macro";
import {
  formatToSpeed,
  getColorByPhaseAndSpeed,
} from "@feature/chart/service/chartSerivice";
import {
  useEffect,
  useState,
} from "react";
import { useRunHalfHeight } from "@feature/run/hook/useRunHalfHeight";
import { useStyles } from "@theme/makeStyles";

type Props = {
  serie: Serie;
  minSpeed?: number;
  maxSpeed?: number;
  speedSuggested?: SpeedSuggestedOutput;
}

const emptyPhase: Phase = {
  physics: {
    speed: 0,
    duration: 0,
  },
  direction: DIRECTION_UNKNOWN,
  fromIndex: 0,
  toIndex: 0,
  fromMm: 0,
  toMm: 0,
  fromMs: 0,
  toMs: 0,
};

export const ChartSpeedBarComponent = (props: Props) => {
  const { theme } = useStyles();

  const { runHalfHeight } = useRunHalfHeight();

  const [
    height,
    setHeight,
  ] = useState(runHalfHeight);

  useEffect(() => {
    setHeight(runHalfHeight - 35);
  }, [ runHalfHeight ]);

  const serie = props.serie;
  const phases = serie.finalPhases.filter(phase => {
    return (!phase.ignoreReason || PHASES_IGNORE_REASON_VISIBLE.includes(phase.ignoreReason)) && phase.positions.isAllInRange;
  });
  const firstConcentricIndex = phases.findIndex(phase => phase.direction === DIRECTION_ASCENDING);
  const firstEccentricIndex = phases.findIndex(phase => phase.direction === DIRECTION_DESCENDING);
  const firstIsEccentric = (firstEccentricIndex !== -1 && firstConcentricIndex !== -1) ? firstEccentricIndex < firstConcentricIndex : firstEccentricIndex !== -1;

  const eccentricPhases = phases.filter(phase => phase.direction === DIRECTION_DESCENDING);
  const concentricPhases = phases.filter(phase => phase.direction === DIRECTION_ASCENDING);
  if (!firstIsEccentric && concentricPhases.length) {
    eccentricPhases.unshift(emptyPhase);
  }
  const eccentricPhasesSpeed: number[] = eccentricPhases.map(phases => Math.round(phases.physics.speed * 100));
  while (eccentricPhasesSpeed.length < 3) {
    eccentricPhasesSpeed.push(0);
  }

  const concentricPhasesSpeed: number[] = concentricPhases.map(phases => Math.round(phases.physics.speed * 100));
  while (concentricPhasesSpeed.length < 3) {
    concentricPhasesSpeed.push(0);
  }

  const minSpeed = Math.round(props.minSpeed * 100);
  const maxSpeed = Math.round(props.maxSpeed * 100);
  const limitVisibleSpeed = (Math.round(maxSpeed / 10) * 15);

  const options: ChartOptions<"bar"> = {
    animation: { duration: 0 },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: false,
      },
      y: {
        stacked: false,
        min: 0,
        max: limitVisibleSpeed,
        ticks: {
          stepSize: 1,
          callback: formatToSpeed([
            0,
            minSpeed,
            maxSpeed,
            limitVisibleSpeed,
          ]),
        },
      },
    },
    plugins: {
      tooltip: { enabled: false },
      legend: { display: false },
      annotation: {
        annotations: {
          speedRange: {
            display: (minSpeed && maxSpeed) && minSpeed > 0 && maxSpeed > minSpeed,
            type: "box",
            yMin: minSpeed > 0 ? minSpeed : null,
            yMax: maxSpeed > 0 ? maxSpeed : null,
            borderWidth: 0,
            backgroundColor: theme.colors.neonTransparent,
          },
          speedRangeBest: {
            display: Boolean(props.speedSuggested),
            type: "box",
            yMin: Boolean(props.speedSuggested) ? props.speedSuggested.min : null,
            yMax: Boolean(props.speedSuggested) ? props.speedSuggested.max : null,
            borderWidth: 0,
            backgroundColor: theme.colors.neonTransparent,
          },
        },
      },
    },
  };

  const getLabels = () => {
    if (!phases.length) {
      return [];
    }
    const length = Math.max(eccentricPhasesSpeed.length, concentricPhasesSpeed.length);
    return Array.from({ length: length }, (v, k) => k + 1);
  };

  const getBackgroundColorsByPhases = (phases: Phase[]): string[] => {
    return phases.map(phase => {
      return getColorByPhaseAndSpeed(
        phase,
        false,
        minSpeed,
        maxSpeed
      );
    });
  };

  const getDataset = () => {
    return [
      {
        data: eccentricPhasesSpeed,
        backgroundColor: getBackgroundColorsByPhases(eccentricPhases),
        borderRadius: 7,
        borderSkipped: false,
      },
      {
        data: concentricPhasesSpeed,
        backgroundColor: getBackgroundColorsByPhases(concentricPhases),
        borderRadius: 7,
        borderSkipped: false,
      },
    ];
  };

  const data: ChartData<"bar"> = {
    labels: getLabels(),
    datasets: getDataset(),
  };

  return <>
    <h4 style={{
      textAlign: "center",
      marginBottom: 0,
    }}>
      <Trans>Speed</Trans>
    </h4>
    <div style={{ height: `${ height }px` }}>
      <Chart
        type="bar"
        data={data}
        redraw={true}
        options={options} />
    </div>
  </>;
};
