import { Capacitor } from "@capacitor/core";
import { NativeAudio } from "@capacitor-community/native-audio";

export const AUDIO_FILE_COUNT_1 = "it/melissa/1.mp3";
export const AUDIO_FILE_COUNT_2 = "it/melissa/2.mp3";
export const AUDIO_FILE_COUNT_3 = "it/melissa/3.mp3";
export const AUDIO_FILE_COUNT_4 = "it/melissa/4.mp3";
export const AUDIO_FILE_COUNT_5 = "it/melissa/5.mp3";
export const AUDIO_FILE_COUNT_6 = "it/melissa/6.mp3";
export const AUDIO_FILE_COUNT_7 = "it/melissa/7.mp3";
export const AUDIO_FILE_COUNT_8 = "it/melissa/8.mp3";
export const AUDIO_FILE_COUNT_9 = "it/melissa/9.mp3";
export const AUDIO_FILE_COUNT_10 = "it/melissa/10.mp3";

export const AUDIO_FILE_COUNTUP_1_3 = "it/melissa/1-3.mp3";
export const AUDIO_FILE_COUNTDOWN_5_1 = "it/melissa/5-1.mp3";
export const AUDIO_FILE_COUNTDOWN_10_1 = "it/melissa/10-1.mp3";
export const AUDIO_FILE_ACCELERATE = "it/melissa/accelerate.mp3";
export const AUDIO_FILE_ACCELERATE_SLIGHTLY = "it/melissa/accelerate_slightly.mp3";
export const AUDIO_FILE_CALIBRATION_MIN = "it/melissa/calibration_min.mp3";
export const AUDIO_FILE_CALIBRATION_MAX = "it/melissa/calibration_max.mp3";
export const AUDIO_FILE_CALIBRATION_MIN_MAX_AUTO = "it/melissa/calibration_min_max_auto.mp3";
export const AUDIO_FILE_CALIBRATION_FAILED = "it/melissa/calibration_failed.mp3";
export const AUDIO_FILE_CALIBRATION_COMPLETED = "it/melissa/calibration_completed.mp3";
export const AUDIO_FILE_CALIBRATION_READING = "it/melissa/calibration_reading.mp3";
export const AUDIO_FILE_GO = "it/melissa/go.mp3";
export const AUDIO_FILE_PHASE_STOP = "it/melissa/phase_stop.mp3";
export const AUDIO_FILE_START = "it/melissa/start.mp3";
export const AUDIO_FILE_STOP = "it/melissa/stop.mp3";
export const AUDIO_FILE_STOOOP = "it/melissa/stooop.mp3";
export const AUDIO_FILE_SLOW_DOWN = "it/melissa/slow_down.mp3";
export const AUDIO_FILE_SLOW_DOWN_SLIGTHLY = "it/melissa/slow_down_slightly.mp3";
export const AUDIO_FILE_BEEP_BAD = "it/melissa/beep_bad.mp3";
export const AUDIO_FILE_BEEP_DONE = "it/melissa/beep_done.mp3";
export const AUDIO_FILE_BEEP_GOOD = "it/melissa/beep_good.mp3";
export const AUDIO_FILE_BEEP_WELL_DONE = "it/melissa/beep_well_done.mp3";
export const AUDIO_FILE_RETRY = "it/melissa/retry.mp3";
export const AUDIO_FILE_POWER_REFERENCE = "it/melissa/power_reference.mp3";

const getAssetPath = (fileName: string): string => {
  return Capacitor.getPlatform() !== "web" ? `public/assets/sounds/${ fileName }` : fileName;
};

const getAssetId = (fileName: string): string => {
  return fileName.replace(/[^a-zA-Z0-9]/g, "");
};

const preload = (fileName: string) => {
  NativeAudio.preload({
    assetId: getAssetId(fileName),
    assetPath: getAssetPath(fileName),
    audioChannelNum: 1,
    isUrl: false,
  });
};

const init = () => {
  if (Capacitor.getPlatform() !== "web") {
    NativeAudio.configure({
      focus: false,
      fade: false,
    }).catch(e => {
      console.info("Error in audio configure", e);
    });
  }

  [
    AUDIO_FILE_COUNTUP_1_3,
    AUDIO_FILE_COUNTDOWN_5_1,
    AUDIO_FILE_COUNTDOWN_10_1,
    AUDIO_FILE_ACCELERATE,
    AUDIO_FILE_ACCELERATE_SLIGHTLY,
    AUDIO_FILE_CALIBRATION_MIN,
    AUDIO_FILE_CALIBRATION_MAX,
    AUDIO_FILE_CALIBRATION_MIN_MAX_AUTO,
    AUDIO_FILE_CALIBRATION_FAILED,
    AUDIO_FILE_CALIBRATION_COMPLETED,
    AUDIO_FILE_CALIBRATION_READING,
    AUDIO_FILE_GO,
    AUDIO_FILE_PHASE_STOP,
    AUDIO_FILE_START,
    AUDIO_FILE_STOP,
    AUDIO_FILE_STOOOP,
    AUDIO_FILE_SLOW_DOWN,
    AUDIO_FILE_SLOW_DOWN_SLIGTHLY,
    AUDIO_FILE_BEEP_BAD,
    AUDIO_FILE_BEEP_DONE,
    AUDIO_FILE_BEEP_GOOD,
    AUDIO_FILE_BEEP_WELL_DONE,
    AUDIO_FILE_RETRY,
    AUDIO_FILE_POWER_REFERENCE,
    AUDIO_FILE_COUNT_1,
    AUDIO_FILE_COUNT_2,
    AUDIO_FILE_COUNT_3,
    AUDIO_FILE_COUNT_4,
    AUDIO_FILE_COUNT_5,
    AUDIO_FILE_COUNT_6,
    AUDIO_FILE_COUNT_7,
    AUDIO_FILE_COUNT_8,
    AUDIO_FILE_COUNT_9,
    AUDIO_FILE_COUNT_10,
  ].forEach(preload);
};
init();

const timeoutIds: any[] = [];

export const audioPlay = async(fileName: string, timeoutForce?: number): Promise<void> => {
  return new Promise(async(resolve, reject) => {
    try {
      const audioDuration = await NativeAudio.getDuration({ assetId: getAssetId(fileName) });
      const timeout = timeoutForce || audioDuration.duration * 1000;
      const timeoutId = setTimeout(async() => {
        try {
          const isPlaying = await NativeAudio.isPlaying({ assetId: getAssetId(fileName) });
          if (isPlaying) {
            resolve();
          }
        } catch (e) {
          console.info("Error in audio play timeout callback", e);
          reject();
        }
      }, timeout);
      timeoutIds.push(timeoutId);
      await NativeAudio.play({ assetId: getAssetId(fileName) });
    } catch (e) {
      console.info("Error in audio play", e);
    }
  });
};

const stop = async(fileName: string): Promise<void> => {
  try {
    NativeAudio.isPlaying({ assetId: getAssetId(fileName) }).then(() => {
      try {
        NativeAudio.stop({ assetId: getAssetId(fileName) });
      } catch (e) {
        console.info("Error in audio stop then", e);
      }
    });
  } catch (e) {
    console.info("Error in audio stop", e);
  }
};

export const audioPlayCount = (number: number): Promise<void> => {
  clearAllTimeouts();
  const count = number > 10 ? number % 10 : number;
  switch (count) {
    case 1:
      return audioPlay(AUDIO_FILE_COUNT_1);
    case 2:
      return audioPlay(AUDIO_FILE_COUNT_2);
    case 3:
      return audioPlay(AUDIO_FILE_COUNT_3);
    case 4:
      return audioPlay(AUDIO_FILE_COUNT_4);
    case 5:
      return audioPlay(AUDIO_FILE_COUNT_5);
    case 6:
      return audioPlay(AUDIO_FILE_COUNT_6);
    case 7:
      return audioPlay(AUDIO_FILE_COUNT_7);
    case 8:
      return audioPlay(AUDIO_FILE_COUNT_8);
    case 9:
      return audioPlay(AUDIO_FILE_COUNT_9);
    case 10:
      return audioPlay(AUDIO_FILE_COUNT_10);
    default:
      return Promise.resolve();
  }
};

export const audioPlayCountDown = (from: number): Promise<void> => {
  clearAllTimeouts();
  if (from === 5) {
    return audioPlay(AUDIO_FILE_COUNTDOWN_5_1, 5000);
  }
  return audioPlay(AUDIO_FILE_COUNTDOWN_10_1, 10000);
};

export const audioPlayCountUp = (to: number): Promise<void> => {
  clearAllTimeouts();
  if (to === 3) {
    return audioPlay(AUDIO_FILE_COUNTUP_1_3, 3000);
  }
  return audioPlay(AUDIO_FILE_COUNTUP_1_3, 3000);
};

export const audioPlayAccelerate = (): Promise<void> => {
  return audioPlay(AUDIO_FILE_ACCELERATE);
};

export const audioPlayAccelerateSlightly = (): Promise<void> => {
  return audioPlay(AUDIO_FILE_ACCELERATE_SLIGHTLY);
};

export const audioPlayCalibrationMin = (): Promise<void> => {
  return audioPlay(AUDIO_FILE_CALIBRATION_MIN);
};

export const audioPlayCalibrationMax = (): Promise<void> => {
  return audioPlay(AUDIO_FILE_CALIBRATION_MAX);
};

export const audioPlayCalibrationMinMaxAuto = (): Promise<void> => {
  return audioPlay(AUDIO_FILE_CALIBRATION_MIN_MAX_AUTO);
};

export const audioPlayCalibratioFailed = (): Promise<void> => {
  return audioPlay(AUDIO_FILE_CALIBRATION_FAILED);
};

export const audioPlayCalibratioCompleted = (): Promise<void> => {
  return audioPlay(AUDIO_FILE_CALIBRATION_COMPLETED);
};

export const audioPlayCalibratioReading = (): Promise<void> => {
  return audioPlay(AUDIO_FILE_CALIBRATION_READING);
};

export const audioPlayGo = (): Promise<void> => {
  return audioPlay(AUDIO_FILE_GO);
};

export const audioPlayPhaseStop = (): Promise<void> => {
  return audioPlay(AUDIO_FILE_PHASE_STOP);
};

export const audioPlayStop = (): Promise<void> => {
  return audioPlay(AUDIO_FILE_STOP);
};

export const audioPlayStart = async(): Promise<void> => {
  await audioStopAll();
  return audioPlay(AUDIO_FILE_START);
};

export const audioPlayStooop = (): Promise<void> => {
  return audioPlay(AUDIO_FILE_STOOOP);
};

export const audioPlaySlowDown = (): Promise<void> => {
  return audioPlay(AUDIO_FILE_SLOW_DOWN);
};

export const audioPlaySlowDownSlightly = (): Promise<void> => {
  return audioPlay(AUDIO_FILE_SLOW_DOWN_SLIGTHLY);
};

export const audioPlayBeepBad = (): Promise<void> => {
  return audioPlay(AUDIO_FILE_BEEP_BAD);
};

export const audioPlayBeepDone = (): Promise<void> => {
  return audioPlay(AUDIO_FILE_BEEP_DONE);
};

export const audioPlayBeepGood = (): Promise<void> => {
  return audioPlay(AUDIO_FILE_BEEP_GOOD);
};

export const audioPlayBeepWellDone = (): Promise<void> => {
  return audioPlay(AUDIO_FILE_BEEP_WELL_DONE);
};

export const audioPlayRetry = (): Promise<void> => {
  return audioPlay(AUDIO_FILE_RETRY);
};

export const audioPlayPowerReference = (): Promise<void> => {
  return audioPlay(AUDIO_FILE_POWER_REFERENCE);
};

const clearAllTimeouts = () => {
  timeoutIds.forEach(timeoutId => {
    clearTimeout(timeoutId);
  });
  timeoutIds.splice(0, timeoutIds.length);
};

export const audioStopAll = (): Promise<void[]> => {
  clearAllTimeouts();
  return Promise.all([
    stop(AUDIO_FILE_COUNT_1),
    stop(AUDIO_FILE_COUNT_2),
    stop(AUDIO_FILE_COUNT_3),
    stop(AUDIO_FILE_COUNT_4),
    stop(AUDIO_FILE_COUNT_5),
    stop(AUDIO_FILE_COUNT_6),
    stop(AUDIO_FILE_COUNT_7),
    stop(AUDIO_FILE_COUNT_8),
    stop(AUDIO_FILE_COUNT_9),
    stop(AUDIO_FILE_COUNT_10),
    stop(AUDIO_FILE_ACCELERATE),
    stop(AUDIO_FILE_ACCELERATE_SLIGHTLY),
    stop(AUDIO_FILE_CALIBRATION_MIN),
    stop(AUDIO_FILE_CALIBRATION_MAX),
    stop(AUDIO_FILE_CALIBRATION_MIN_MAX_AUTO),
    stop(AUDIO_FILE_CALIBRATION_FAILED),
    stop(AUDIO_FILE_CALIBRATION_COMPLETED),
    stop(AUDIO_FILE_CALIBRATION_READING),
    stop(AUDIO_FILE_COUNTDOWN_10_1),
    stop(AUDIO_FILE_COUNTDOWN_5_1),
    stop(AUDIO_FILE_COUNTUP_1_3),
    stop(AUDIO_FILE_GO),
    stop(AUDIO_FILE_PHASE_STOP),
    stop(AUDIO_FILE_SLOW_DOWN),
    stop(AUDIO_FILE_SLOW_DOWN_SLIGTHLY),
    stop(AUDIO_FILE_START),
    stop(AUDIO_FILE_STOOOP),
    stop(AUDIO_FILE_STOP),
    stop(AUDIO_FILE_BEEP_BAD),
    stop(AUDIO_FILE_BEEP_DONE),
    stop(AUDIO_FILE_BEEP_GOOD),
    stop(AUDIO_FILE_BEEP_WELL_DONE),
    stop(AUDIO_FILE_RETRY),
    stop(AUDIO_FILE_POWER_REFERENCE),
  ]);
};
