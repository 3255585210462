import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import moment from "moment/moment";
import { Device } from "@capacitor/device";
import { I18nProvider } from "@lingui/react";
import {
  LANGUAGE_EN,
  LANGUAGE_IT,
} from "@feature/setting/settingConstants";
import {
  Locale,
  i18n,
} from "@lingui/core";
import { messages as enMessages } from "@locale/en/messages";
import { messages as itMessages } from "@locale/it/messages";
import { selectAccountState } from "@feature/account/slice/accountSlice";
import {
  selectSettingState,
  setLanguage,
} from "@feature/setting/slice/settingSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "@core/redux/store";

i18n.load(LANGUAGE_EN, enMessages);
i18n.load(LANGUAGE_IT, itMessages);
i18n.activate(LANGUAGE_EN);

interface LanguageContextType {
  setLanguage: (languageCode: string) => void;
}

const LanguageContext = createContext<LanguageContextType>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setLanguage: () => {},
});

export const useLanguage = () => useContext(LanguageContext);

interface Props {
  children: ReactNode;
}

export const LanguageProvider = (props: Props) => {
  const dispatch = useAppDispatch();
  const authState = useAppSelector(selectAccountState);
  const settingState = useAppSelector(selectSettingState);

  // State to force re-render when language is changed
  const [
    languageVersion,
    setLanguageVersion,
  ] = useState<number>(0);

  const setNewLanguage = useCallback((languageCode: Locale) => {
    i18n.activate(languageCode);
    moment.locale(languageCode);
    document.documentElement.lang = languageCode;
    dispatch(setLanguage(languageCode));
    setLanguageVersion(prevVersion => prevVersion + 1); // Increment the version to force re-render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const setLanguageBySystem = async() => {
      if (!settingState.language) {
        const deviceLanguage = await Device.getLanguageCode();
        setNewLanguage(deviceLanguage.value);
      } else {
        setNewLanguage(settingState.language);
      }
    };

    setLanguageBySystem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ authState.isLoggedIn ]);

  return <>
    <I18nProvider
      i18n={i18n}
      key={languageVersion}
    >
      <LanguageContext.Provider
        value={{
          setLanguage: setNewLanguage,
        }}>
        {props.children}
      </LanguageContext.Provider>
    </I18nProvider>
  </>;
};
