import { t } from "@lingui/macro";

export const getExeciseTitleByCode = (code: string): string => {
  switch (code) {
    case "SENSOR_TEST":
      return t`SENSOR_TEST_TITLE`;
    case "PIANE_BENCH":
      return t`PIANE_BENCH_TITLE`;
    case "INCLINE_BENCH":
      return t`INCLINE_BENCH_TITLE`;
    case "DECLINE_BENCH":
      return t`DECLINE_BENCH_TITLE`;
    case "CABLE_FLYES":
      return t`CABLE_FLYES_TITLE`;
    case "PECTORAL_MACHINE":
      return t`PECTORAL_MACHINE_TITLE`;
    case "FACE_PULLS":
      return t`FACE_PULLS_TITLE`;
    case "PULL_UPS":
      return t`PULL_UPS_TITLE`;
    case "CHIN_UPS":
      return t`CHIN_UPS_TITLE`;
    case "ROWING_MACHINE":
      return t`ROWING_MACHINE_TITLE`;
    case "BENT_OVER_ROW":
      return t`BENT_OVER_ROW_TITLE`;
    case "LEG_EXTENSION":
      return t`LEG_EXTENSION_TITLE`;
    case "LEG_CURL":
      return t`LEG_CURL_TITLE`;
    case "LEG_PRESS":
      return t`LEG_PRESS_TITLE`;
    case "SQUAT":
      return t`SQUAT_TITLE`;
    case "FRONT_SQUAT":
      return t`FRONT_SQUAT_TITLE`;
    case "OVERHEAD_SQUAT":
      return t`OVERHEAD_SQUAT_TITLE`;
    case "LUNGES":
      return t`LUNGES_TITLE`;
    case "SINGLE_SQUAT":
      return t`SINGLE_SQUAT_TITLE`;
    case "LATERAL_SQUAT":
      return t`LATERAL_SQUAT_TITLE`;
    case "STIFF_LEGGEND_DEADLIFTS":
      return t`STIFF_LEGGEND_DEADLIFTS_TITLE`;
    case "CLIMB_ON_THE_STAIR":
      return t`CLIMB_ON_THE_STAIR_TITLE`;
    case "FORWARD_SHOULDER_PRESS":
      return t`FORWARD_SHOULDER_PRESS_TITLE`;
    case "BACKWARD_SHOULDER_PRESS":
      return t`BACKWARD_SHOULDER_PRESS_TITLE`;
    case "UPRIGHT_ROW":
      return t`UPRIGHT_ROW_TITLE`;
    case "SQUAT_JUMP":
      return t`SQUAT_JUMP_TITLE`;
    case "COUNTER_MOVEMENT_JUMP":
      return t`COUNTER_MOVEMENT_JUMP_TITLE`;
    case "DROP_JUMP":
      return t`DROP_JUMP_TITLE`;
    case "REBOUND":
      return t`REBOUND_TITLE`;
    case "HIP_THRUST":
      return t`HIP_THRUST_TITLE`;
    case "BENCH_PRESS":
      return t`BENCH_PRESS_TITLE`;
    case "STEP_UP":
      return t`STEP_UP_TITLE`;
    case "LAT_PULLDOWN":
      return t`LAT_PULLDOWN_TITLE`;
    case "ROW":
      return t`ROW_TITLE`;
    case "BARBELL_ROW":
      return t`BARBELL_ROW_TITLE`;
    case "BARBELL_CURL":
      return t`BARBELL_CURL_TITLE`;
    case "FRENCH_PRESS":
      return t`FRENCH_PRESS_TITLE`;
    case "PUSH_DOWN":
      return t`PUSH_DOWN_TITLE`;
    case "MILITARY_PRESS":
      return t`MILITARY_PRESS_TITLE`;
    case "CHEST_ROW":
      return t`CHEST_ROW_TITLE`;
    case "CLEAN":
      return t`CLEAN_TITLE`;
    case "DEADLIFT":
      return t`DEADLIFT_TITLE`;
    case "SNATCH":
      return t`SNATCH_TITLE`;
  }
  return code;
};
