import { AvatarColorUi } from "@ui/AvatarColorUi/AvatarColorUi";
import { AvatarSrcUi } from "@ui/AvatarSrcUi/AvatarSrcUi";
import {
  ButtonClick,
  Color,
  MultipleChildrens,
  Typography,
} from "@types-custom";
import { IconUi } from "@ui/IconUi/IconUi";
import { SeparatorUi } from "@ui/SeparatorUi/SeparatorUi";
import { SwitchUi } from "@ui/SwitchUi/SwitchUi";
import { useLogic } from "./useLogic";
import { useStyles } from "./useStyles";

interface Props {
  className?: string;
  href?: string;
  title?: string;
  description?: string | MultipleChildrens;
  children?: MultipleChildrens;
  avatarSrc?: string;
  avatarColor?: Color;
  onClick?: ButtonClick;
  hasSwitch?: boolean;
  checked?: boolean;
  textElement?: boolean;
  isAccordion?: boolean;
  isAccordionOpen?: boolean;
  isRequired?: boolean;
  titleTypography?: Typography;
  descriptionTypography?: Typography;
  hasSeparator?: boolean;
  itemOrder?: number;
}

export const ListItemUi = ({
  className,
  href,
  title,
  description,
  children,
  avatarColor = null,
  avatarSrc = null,
  onClick,
  hasSwitch = false,
  checked = false,
  textElement = false,
  isAccordion = false,
  isAccordionOpen = true,
  titleTypography = null,
  descriptionTypography = null,
  hasSeparator = false,
  isRequired = false,
  itemOrder = 1,
}: Props) => {
  const {
    classes, cx,
  } = useStyles({
    hasOnlyChildren: !title && !description,
    titleTypography: titleTypography,
    descriptionTypography: descriptionTypography,
    itemOrder: itemOrder,
    isAccordion: isAccordion,
    textElement: textElement,
  });

  const {
    handleClick,
  } = useLogic({
    onClick: onClick,
    href: href,
  });

  return <>
    <div
      className={cx({
        [classes.listItemWrapper]: true,
        [classes.navigationItem]: (href !== undefined && href !== "") || (onClick !== undefined),
        // [classes.pressed]: ((isAccordion && isPressed) || isPressed) && href !== null && href !== "" && !children,
        [classes.hasChildrens]: children !== undefined,
        [className]: className !== "",
      })}
      onClick={handleClick}
    >
      {
        avatarSrc &&
        <AvatarSrcUi src={avatarSrc} />
      }
      {
        avatarColor &&
        <AvatarColorUi color={avatarColor} />
      }
      <div className={cx({
        [classes.listItemTextContent]: true,
        [classes.hasImage]: avatarSrc !== null || avatarColor !== null,
        [classes.hasNoImage]: href !== undefined && avatarSrc === null && avatarColor === null,
        [classes.fullWidth]: href === undefined && avatarSrc === null && avatarColor === null,
        [classes.hasSwitch]: hasSwitch,
      })}>
        {
          title &&
	        <h3 className={classes.title}>
	          {title}
	          {isRequired && <span className={classes.requiredAsterisk}>*</span>}
	        </h3>
        }
        {
          description &&
          <span className={classes.description}>{description}</span>
        }
      </div>

      {
        (
          !hasSwitch &&
          (
            ((isAccordion || href !== undefined) && (href !== "") && children === undefined) ||
            onClick !== undefined
          )
        ) &&
        <IconUi
          className={classes.listItemIcon}
          isToggled
          branded
          position="left"
          icon={isAccordion ? "expand-down" : "expand-right"}
          hidden={false}
          isAccordion={isAccordion}
          isAccordionOpen={isAccordionOpen} />
      }

      {
        hasSwitch &&
        <SwitchUi
          checked={checked}
          onChange={() => {
            onClick(null);
          }}
          className={cx({
            [classes.listItemIcon]: true,
            [classes.switch]: true,
          })}
        />
      }

      {
        children &&
        <div className={classes.childrenWrapper}>
          {children}
        </div>
      }

      {
        hasSeparator &&
        <SeparatorUi
          className={cx({
            [classes.listItemSeparator]: true,
            "separator": true,
          })}
          background="darkTint" />
      }
    </div>
  </>;
};
