/* eslint-disable max-len */
export const CONFIGURTION_TYPE_INTEGER = "integer";
export const CONFIGURTION_TYPE_NUMBER = "number";
export const CONFIGURTION_TYPE_BOOLEAN = "boolean";
export const CONFIGURTION_TYPE_PERCENTAGE = "%";
export const CONFIGURTION_TYPE_MILLISECOND = "ms";
export const CONFIGURTION_TYPE_MILLIMETER = "mm";
export const CONFIGURTION_TYPE_MILLIMETER_ON_MILLISECOND = "mm/ms";
export const CONFIGURTION_TYPE_KILOGRAM = "kg";
export const CONFIGURTION_TYPE_SECOND = "s";
export const CONFIGURTION_TYPE_WATT = "W";

// To keep sync with Configuration table
export const CONFIGURATION_CODE_GENERATE_FLY_POINTS = "GENERATE_FLY_POINTS"; // Genera i punti fly
export const CONFIGURATION_CODE_LOAD_WEIGHT_MAXIMAL = "LOAD_WEIGHT_MAXIMAL"; // Il massimale dichiarato durante quella serie
export const CONFIGURATION_CODE_LOAD_WEIGHT_MIN_PERCENT = "LOAD_WEIGHT_MIN_PERCENT"; // La percentuale di peso minima suggerita
export const CONFIGURATION_CODE_LOAD_WEIGHT_MAX_PERCENT = "LOAD_WEIGHT_MAX_PERCENT"; // La percentuale di peso massima suggerita
export const CONFIGURATION_CODE_LOAD_WEIGHT_INITIAL = "LOAD_WEIGHT_INITIAL"; // Il peso dichiarato in fase di compilazione
export const CONFIGURATION_CODE_LOAD_WEIGHT_USED = "LOAD_WEIGHT_USED"; // Il peso effettivo usato
export const CONFIGURATION_CODE_POWER_MAX = "POWER_MAX"; // La percentuale di potenza massima consigliata
export const CONFIGURATION_CODE_POWER_MIN = "POWER_MIN"; // La percentuale di potenza minima consigliata
export const CONFIGURATION_CODE_CONSECUTIVE_BAD_REPETITIONS_COUNT = "POWER_CONSECUTIVE_BAD"; // Quanti fallimenti consecutivi servono per lo stop
export const CONFIGURATION_CODE_RECOVERY_TIME_MIN = "RECOVERY_TIME_MIN"; // Tempo di recupero predefinito minimo
export const CONFIGURATION_CODE_RECOVERY_TIME_MAX = "RECOVERY_TIME_MAX"; // Tempo di recupero predefinito massimo
export const CONFIGURATION_CODE_RECOVERY_TIME_INITIAL = "RECOVERY_TIME_INITIAL"; // Tempo di recupero predefinito
export const CONFIGURATION_CODE_RECOVERY_TIME_USED = "RECOVERY_TIME_USED"; // Tempo di recupero impostato e quello utilizzato
export const CONFIGURATION_CODE_REMOVE_OUT_OF_RANGE = "REMOVE_OUT_OF_RANGE"; // Determina se i punti fuori range devono essere rimossi
export const CONFIGURATION_CODE_AUTOSTOP_TIME = "AUTOSTOP_TIME"; // Dopo quanti secondi di inattività si deve interrompere la serie
export const CONFIGURATION_CODE_SHUTUP_SECONDS = "SHUTUP_SECONDS"; // Per quanti secondi non dovrà essere dato il comando di Stop nonostante i valor di potenza bassi
export const CONFIGURATION_CODE_SPEED_MIN = "SPEED_MIN"; // La velocità minima consigliata
export const CONFIGURATION_CODE_SPEED_MAX = "SPEED_MAX"; // La velocità massima consigliata
export const CONFIGURATION_CODE_KALMAN_Q = "KALMAN_Q"; // Parametro Q per il filtro di Kalman
export const CONFIGURATION_CODE_KALMAN_R = "KALMAN_R"; // Parametro R per il filtro di Kalman
export const CONFIGURATION_CODE_MERGE_STALL_TIME_UNDER = "MERGE_STALL_TIME_UNDER"; // Quanto può durare uno stallo al massimo per essere mergiato in mezzo a due movimenti di stessa direzione // @todo questo va limitato a quando si è lontani dal margine inferiore per evitare di mergiare roba che in realtà è una fase ferma
export const CONFIGURATION_CODE_STATIC_PHASE_1_TIME = "STATIC_PHASE_1_TIME"; // Tempo di fase statica 1
export const CONFIGURATION_CODE_STATIC_PHASE_2_TIME = "STATIC_PHASE_2_TIME"; // Tempo di fase statica 2
export const CONFIGURATION_CODE_STATIC_PHASE_3_TIME = "STATIC_PHASE_3_TIME"; // Tempo di fase statica 3
export const CONFIGURATION_CODE_CONCENTRIC_MIN_PERCENT = "CONCENTRIC_MIN_PERCENT"; // Minimo in percentuale per il movimento concentrico @todo da implementare forse
export const CONFIGURATION_CODE_ECCENTRIC_MIN_PERCENT = "ECCENTRIC_MIN_PERCENT"; // Minimo in percentuale per il movimento eccentrico @todo da implementare forse
export const CONFIGURATION_CODE_MAX_DISTANCE_TOO_FAR_FROM_MEDIAN_SPIKES = "MAX_DISTANCE_TOO_FAR_FROM_MEDIAN_SPIKES"; // Minimo in percentuale per il movimento eccentrico @todo da implementare forse
export const CONFIGURATION_CODE_READS_PER_SECONDS = "READS_PER_SECONDS"; // Rappresenta il numero di lettura al secondo // 30 va bene per i movimenti molto lenti, 100 è il valore di default, si potrebbe salire fino a 125
export const CONFIGURATION_CODE_SPEED_THRESHOLD_UP = "SPEED_THRESHOLD_UP"; // Determina quale velocità deve essere superata per considerare un movimento come in salita
export const CONFIGURATION_CODE_SPEED_THRESHOLD_DOWN = "SPEED_THRESHOLD_DOWN"; // Determina quale velocità deve essere superata per considerare un movimento come in discesa
export const CONFIGURATION_CODE_POWER_REFERENCE_AUTO_UPDATE = "POWER_REFERENCE_AUTO_UPDATE"; // Aggiorna in tempo reale la potenza di riferimento quando viene superata
export const CONFIGURATION_CODE_USE_POWER = "USE_POWER"; // Utilizza la potenza per il calcolo
export const CONFIGURATION_CODE_USE_SPEED = "USE_SPEED"; // Utilizza la velocità per il calcolo
export const CONFIGURATION_CODE_USE_BODY_WEIGHT = "USE_BODY_WEIGHT"; // Utilizza il peso corporeo
export const CONFIGURATION_CODE_BODY_WEIGHT_USED = "BODY_WEIGHT_USED"; // Il peso del corpo utilizzato durante quella serie
export const CONFIGURATION_CODE_POWER_REFERENCE_USED = "POWER_REFERENCE_USED"; // La potenza di riferimento utilizzata durante quella serie
export const CONFIGURATION_CODE_RANGE_MAX_USED = "RANGE_MAX_USED"; // Il range massimo di movimento
export const CONFIGURATION_CODE_RANGE_MIN_USED = "RANGE_MIN_USED"; // Il range minimo di movimento
export const CONFIGURATION_CODE_RANGE_EXISTING = "RANGE_EXISTING"; // Specifica se questa serie è stata fatta con o senza range ( riscaldamento )
export const CONFIGURATION_CODE_FEEDBACK_GO_TOLLERANCE_PERCENT = "FEEDBACK_GO_TOLLERANCE_PERCENT"; // Dice VAI anzichè STOP con questa percentuale di tolleranza
export const CONFIGURATION_CODE_MERGE_AFTER_PERCENT = "MERGE_AFTER_PERCENT"; // Dopo quanta percentuale di movimento si può fare il merge
export const CONFIGURATION_CODE_RANGE_MIN_REDUCTION_PERCENT = "RANGE_MIN_REDUCTION_PERCENT"; // Quanta ridurre il range minimo in percentuale
export const CONFIGURATION_CODE_RANGE_MAX_REDUCTION_PERCENT = "RANGE_MAX_REDUCTION_PERCENT"; // Quanta ridurre il range massimo in percentuale
export const CONFIGURATION_CODE_RANGE_MIN_REDUCTION_MM = "RANGE_MIN_REDUCTION_MM"; // Quanto ridurre il range minimo in mm
export const CONFIGURATION_CODE_RANGE_MAX_REDUCTION_MM = "RANGE_MAX_REDUCTION_MM"; // Quanto ridurre il range massimo in mm
export const CONFIGURATION_CODE_RANGE_EXTENDED_READ_MIN_MM = "RANGE_EXTENDED_READ_MIN_MM"; // Quanto il sensore può leggere oltre la calibrazione
export const CONFIGURATION_CODE_RANGE_EXTENDED_READ_MAX_MM = "RANGE_EXTENDED_READ_MAX_MM"; // Quanto il sensore può leggere oltre la calibrazione

export type ConfigurationCode =
  | typeof CONFIGURATION_CODE_GENERATE_FLY_POINTS
  | typeof CONFIGURATION_CODE_LOAD_WEIGHT_MAXIMAL
  | typeof CONFIGURATION_CODE_LOAD_WEIGHT_MIN_PERCENT
  | typeof CONFIGURATION_CODE_LOAD_WEIGHT_MAX_PERCENT
  | typeof CONFIGURATION_CODE_LOAD_WEIGHT_INITIAL
  | typeof CONFIGURATION_CODE_LOAD_WEIGHT_USED
  | typeof CONFIGURATION_CODE_POWER_MAX
  | typeof CONFIGURATION_CODE_POWER_MIN
  | typeof CONFIGURATION_CODE_CONSECUTIVE_BAD_REPETITIONS_COUNT
  | typeof CONFIGURATION_CODE_RECOVERY_TIME_MIN
  | typeof CONFIGURATION_CODE_RECOVERY_TIME_MAX
  | typeof CONFIGURATION_CODE_RECOVERY_TIME_INITIAL
  | typeof CONFIGURATION_CODE_RECOVERY_TIME_USED
  | typeof CONFIGURATION_CODE_REMOVE_OUT_OF_RANGE
  | typeof CONFIGURATION_CODE_AUTOSTOP_TIME
  | typeof CONFIGURATION_CODE_SHUTUP_SECONDS
  | typeof CONFIGURATION_CODE_SPEED_MIN
  | typeof CONFIGURATION_CODE_SPEED_MAX
  | typeof CONFIGURATION_CODE_KALMAN_Q
  | typeof CONFIGURATION_CODE_KALMAN_R
  | typeof CONFIGURATION_CODE_MERGE_STALL_TIME_UNDER
  | typeof CONFIGURATION_CODE_STATIC_PHASE_1_TIME
  | typeof CONFIGURATION_CODE_STATIC_PHASE_2_TIME
  | typeof CONFIGURATION_CODE_STATIC_PHASE_3_TIME
  | typeof CONFIGURATION_CODE_CONCENTRIC_MIN_PERCENT
  | typeof CONFIGURATION_CODE_ECCENTRIC_MIN_PERCENT
  | typeof CONFIGURATION_CODE_MAX_DISTANCE_TOO_FAR_FROM_MEDIAN_SPIKES
  | typeof CONFIGURATION_CODE_READS_PER_SECONDS
  | typeof CONFIGURATION_CODE_SPEED_THRESHOLD_UP
  | typeof CONFIGURATION_CODE_SPEED_THRESHOLD_DOWN
  | typeof CONFIGURATION_CODE_POWER_REFERENCE_AUTO_UPDATE
  | typeof CONFIGURATION_CODE_USE_POWER
  | typeof CONFIGURATION_CODE_USE_SPEED
  | typeof CONFIGURATION_CODE_USE_BODY_WEIGHT
  | typeof CONFIGURATION_CODE_BODY_WEIGHT_USED
  | typeof CONFIGURATION_CODE_POWER_REFERENCE_USED
  | typeof CONFIGURATION_CODE_RANGE_MAX_USED
  | typeof CONFIGURATION_CODE_RANGE_MIN_USED
  | typeof CONFIGURATION_CODE_RANGE_EXISTING
  | typeof CONFIGURATION_CODE_FEEDBACK_GO_TOLLERANCE_PERCENT
  | typeof CONFIGURATION_CODE_MERGE_AFTER_PERCENT
  | typeof CONFIGURATION_CODE_RANGE_MIN_REDUCTION_PERCENT
  | typeof CONFIGURATION_CODE_RANGE_MAX_REDUCTION_PERCENT
  | typeof CONFIGURATION_CODE_RANGE_MIN_REDUCTION_MM
  | typeof CONFIGURATION_CODE_RANGE_MAX_REDUCTION_MM
  | typeof CONFIGURATION_CODE_RANGE_EXTENDED_READ_MIN_MM
  | typeof CONFIGURATION_CODE_RANGE_EXTENDED_READ_MAX_MM
