import React, {
  useEffect,
  useRef,
  useState,
} from "react";
import {
  CHART_ZOOM_MIN_X,
  CHART_ZOOM_MIN_Y,
} from "@feature/chart/chartConstants";
import {
  Chart,
  ChartOptions,
} from "chart.js";
import { ChartControllerComponenet } from "@feature/chart/component/ChartControllerComponent";
import {
  DEVICE_RANGE_MAX,
} from "@common/service/constants";
import { Phase } from "@common/model/Phase";
import { Point } from "@common/model/Point";
import { RangeMinMax } from "@common/model/Range";
import { Scatter } from "react-chartjs-2";
import { Trans } from "@lingui/macro";
import {
  convertPhasesToPoints,
  formatToDistance,
  formatToTime,
  getMaxY,
  getMinY,
  getTotalMaxXByPoints,
} from "@feature/chart/service/chartSerivice";
import { selectSettingState } from "@feature/setting/slice/settingSlice";
import { useAppSelector } from "@core/redux/store";
import { useRunHalfHeight } from "@feature/run/hook/useRunHalfHeight";
import { useStyles } from "@theme/makeStyles";

type Props = {
  finalPoints: Point[];
  simplifiedPoints: Point[];
  finalPhases: Phase[];
  range?: RangeMinMax;
  rangeExtended?: RangeMinMax;
  minPower?: number;
  maxPower?: number;
  minSpeed?: number;
  maxSpeed?: number;
  showController?: boolean;
}

export const ChartJumpComponent = (props: Props) => {
  const { theme } = useStyles();
  const settingState = useAppSelector(selectSettingState);

  const simplified = props.simplifiedPoints;
  const hasData = simplified.length > 0;

  const minPower = props.minPower ?? 0;
  const maxPower = props.maxPower ?? 0;
  const minSpeed = props.minSpeed ?? 0;
  const maxSpeed = props.maxSpeed ?? 0;

  const minY = getMinY(props.rangeExtended, simplified);
  const maxY = getMaxY(props.rangeExtended, simplified);

  const maxTotalX = getTotalMaxXByPoints(simplified);

  const getXTickStepSize = () => {
    if (maxTotalX <= 5000) {
      return 1000;
    } else if (maxTotalX <= 20000) {
      return 2000;
    } else if (maxTotalX <= 40000) {
      return 5000;
    } else if (maxTotalX <= 120000) {
      return 10000;
    }
    return 30000;
  };

  const getYTickStepSize = () => {
    if (maxY === DEVICE_RANGE_MAX) {
      return 500;
    }
    return 100;
  };

  const [
    isAnalyzing,
    setIsAnalyzing,
  ] = useState(false);

  const { runHalfHeight } = useRunHalfHeight();

  const [
    height,
    setHeight,
  ] = useState(runHalfHeight);

  useEffect(() => {
    setHeight(runHalfHeight - 35);
  }, [ runHalfHeight ]);

  const options: ChartOptions<"scatter"> = {
    animation: { duration: 0 },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        min: minY,
        max: maxY,
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          color: "rgba(255,255,255,0.2)",
          tickBorderDash: [
            5,
            5,
          ],
          lineWidth: 1,
        },
        ticks: {
          stepSize: getYTickStepSize(),
          callback: formatToDistance,
        },
      },
      x: {
        ticks: {
          stepSize: getXTickStepSize(),
          callback: formatToTime,
        },
      },
    },
    plugins: {
      legend: { display: false },
      tooltip: { enabled: false },
      zoom: {
        zoom: {
          wheel: { enabled: isAnalyzing },
          pinch: { enabled: isAnalyzing },
          mode: "xy",
        },
        pan: {
          enabled: isAnalyzing,
          mode: "xy",
        },
        limits: {
          x: {
            min: 0,
            max: maxTotalX,
            minRange: CHART_ZOOM_MIN_X,
          },
          y: {
            min: minY,
            max: maxY,
            minRange: CHART_ZOOM_MIN_Y,
          },
        },
      },
      annotation: {
        annotations: {
          rangeMin: {
            display: props.range && props.range.min > 0,
            type: "line",
            yMin: props.range?.min,
            yMax: props.range?.min,
            borderColor: theme.colors.orange,
            borderWidth: 2,
            borderDash: [
              5,
              5,
            ],
          },
          rangeMax: {
            display: props.range && props.range.max > 0,
            type: "line",
            yMin: props.range?.max,
            yMax: props.range?.max,
            borderColor: theme.colors.orange,
            borderWidth: 2,
            borderDash: [
              5,
              5,
            ],
          },
        },
      },
    },
  };

  const finalPoints = props.finalPoints;
  const phases = props.finalPhases;

  const data = {
    datasets: [
      ...settingState.isShowMovementDetails ? convertPhasesToPoints(finalPoints, simplified, phases, false, minPower, maxPower, minSpeed, maxSpeed) : [],
      {
        label: "Movement elaborated",
        pointRadius: 0,
        borderColor: settingState.isShowMovementDetails ? theme.colors.pureWhiteTransparent : theme.colors.neon,
        lineTension: 0.1,
        showLine: true,
        data: simplified,
      },
    ],
  };

  const chartRef = useRef<Chart<"scatter">>(null);

  useEffect(() => {
    const chart = chartRef.current;
    // setInterval(() => {
    //   chart.zoom(100000);
    // }, 5000);
  }, []);

  return <>
    <h4 style={{
      textAlign: "center",
      marginBottom: 0,
    }}>
      <Trans>Position</Trans>
      {
        hasData &&
        props.showController &&
			  <ChartControllerComponenet
				  isEnabled={isAnalyzing}
				  isEnabledHandler={setIsAnalyzing}
				  height={height}
				  heightHandler={setHeight}
			  />
      }
    </h4>

    <div style={{ height: `${ height }px` }}>
      <Scatter
        redraw={true}
        data={data}
        options={options}
        ref={chartRef}
      />
    </div>
  </>;
};
