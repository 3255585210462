import {
  ACCOUNT_ROLE_ADMIN,
  ACCOUNT_ROLE_GUEST,
} from "@common/model/Account";
import {
  Account,
} from "@common/type-graphql/generated";
import { ApiData } from "@feature/api/component/ApiData";
import { DeviceDevelpomentFabComponent } from "@feature/device/component/deviceDevelpomentFabComponent";
import { FooterComponent } from "@feature/layout/component/FooterComponent";
import { HeaderUi } from "@ui/HeaderUi/HeaderUi";
import { IOptions } from "@ui/SelectUi/SelectUi";
import {
  IonContent,
  IonPage,
  useIonActionSheet,
} from "@ionic/react";
import { ListItemUi } from "@ui/ListItemUi/ListItemUi";
import { PrimaryButtonUi } from "@ui/PrimaryButtonUi/PrimaryButtonUi";
import { SectionUi } from "@ui/SectionUi/SectionUi";
import { SelectUi } from "@ui/SelectUi/SelectUi";
import { confirmDestructive } from "@feature/confirm/service/confirmDestructive";
import { i18n } from "@lingui/core";
import {
  router,
  useAppDispatch,
  useAppSelector,
} from "@core/redux/store";
import { routes } from "@core/route";
import {
  selectSettingState,
  setBeepEnabled,
  setCounterEnabled,
  setIsDebug,
  setIsShowChartDebug,
  setIsShowMovementDetails,
  setLanguage,
  setShowPostDirection,
  setShowPreDirection,
} from "@feature/setting/slice/settingSlice";
import { t } from "@lingui/macro";
import { useAccountGetQuery } from "@feature/account/api/accountApi";
import { useLanguage } from "@feature/setting/component/LanguageProvider";

const getLanguageOptions = (): Array<IOptions> => {
  return [
    {
      label: "Italiano",
      value: "it",
    },
    {
      label: "English",
      value: "en",
    },
  ];
};

export const SettingsPage = () => {
  const accountGetApi = useAccountGetQuery();

  return <>
    <ApiData
      endpoint={[ accountGetApi ]}
    >
      <ChildComponent
        account={accountGetApi.data}
      />
    </ApiData>
  </>;
};

type PropsChild = {
  account: Account;
}
const ChildComponent = (props: PropsChild) => {
  const dispatch = useAppDispatch();

  const language = useLanguage();
  const settingState = useAppSelector(selectSettingState);
  const [ present ] = useIonActionSheet();

  const goToLogout = () => {
    confirmDestructive({
      header: t`Are you sure to log out?`,
      present: present,
      action: async() => {
        router.replace(routes.accountLogout.redirectPath);
      },
    });
  };

  const goToMyAccount = () => {
    router.replace(routes.accountView.redirectPath);
  };

  // const toggleEncryption = async() => {
  //   await setPreferenceToggleAccountEncrypted();
  // };
  //
  // const [
  //   stringToCrypt,
  //   setStringToCrypt,
  // ] = useState("");
  //
  // const [
  //   toggleToCrypt,
  //   setToggleCrypt,
  // ] = useState(false);

  const setNewLanguage = (value: string) => {
    dispatch(setLanguage(value));
    language.setLanguage(value);
  };

  return <>
    <IonPage>
      <HeaderUi
        pageTitle={i18n._(routes.settings.title)}
        backToPath={routes.dashboard.routePath}
        backToTitle={routes.dashboard.title}
        rightChildren={<DeviceDevelpomentFabComponent />}
      />
      <IonContent>
        <SectionUi title={t`General`} rounded={true}>
          <ListItemUi
            itemOrder={10}
            title={t`Interface language`}
            description={t`Choose the language in which to view the information within the application`}
          >
            <SelectUi
              name={"language"}
              placeholder={t`SelectUi language`}
              options={getLanguageOptions()}
              selectedOptionId={settingState.language}
              setValue={ (name, value) => {
                setNewLanguage(value);
              }}
            />
          </ListItemUi>
          <ListItemUi
            title={t`Enable beep`}
            description={t`Play a beep sound when a good repetition is detected`}
            itemOrder={60}
            hasSwitch={true}
            checked={settingState.isBeepEnabled}
            onClick={() => {
              dispatch(setBeepEnabled(!settingState.isBeepEnabled));
            }}
          />
          <ListItemUi
            title={t`Enable counter`}
            description={t`Count every good repetition`}
            itemOrder={60}
            hasSwitch={true}
            checked={settingState.isCounterEnabled}
            onClick={() => {
              dispatch(setCounterEnabled(!settingState.isCounterEnabled));
            }}
          />
          <ListItemUi
            title={t`Show movment details`}
            description={t`Show details in the movment chart`}
            itemOrder={42}
            hasSwitch={true}
            checked={settingState.isShowMovementDetails}
            onClick={() => {
              dispatch(setIsShowMovementDetails(!settingState.isShowMovementDetails));
            }}
          />
          <ListItemUi>
            {
              props.account.role !== ACCOUNT_ROLE_GUEST &&
			        <PrimaryButtonUi
				        onClick={goToMyAccount}
				        label={t`My Account`}
			        />
            }
            <br />
            <PrimaryButtonUi
              onClick={goToLogout}
              label={t`Logout`}
            />
          </ListItemUi>
          {/*<ListItemUi*/}
          {/*  title={t`Vocal coach language`}*/}
          {/*  description={t`Choose the language for the application`}*/}
          {/*  itemOrder={30}*/}
          {/*  hasSwitch={true}*/}
          {/*  onClick={dispatchTodoToast}*/}
          {/*  href=""*/}
          {/*/>*/}
          {/*<ListItemUi*/}
          {/*  title={t`Encrypt data`}*/}
          {/*  description={t`By activating this option your data will be crypted`}*/}
          {/*  itemOrder={50}*/}
          {/*  hasSwitch={true}*/}
          {/*  checked={toggleToCrypt}*/}
          {/*  onClick={toggleEncryption}>*/}
          {/*  <InputUi*/}
          {/*    type="text"*/}
          {/*    value={stringToCrypt}*/}
          {/*    id="string-to-crypt"*/}
          {/*    onChange={e => {*/}
          {/*      setStringToCrypt(e.target.value);*/}
          {/*    }}*/}
          {/*    placeholder={t`insert here the string to crypt....`}*/}
          {/*    autoComplete="off"*/}
          {/*  />*/}
          {/*</ListItemUi>*/}
          {/*<ListItemUi*/}
          {/*  title={t`Delete your data`}*/}
          {/*  description={t`Request complete deletion of your data. You will be notified when the deletion is complete.`}*/}
          {/*  itemOrder={5}>*/}
          {/*  <ButtonUi*/}
          {/*    onClick={dispatchTodoToast}*/}
          {/*    type="primary"*/}
          {/*    label={t`Request data deletion`}*/}
          {/*    backgroundColor="black"*/}
          {/*    appearance="rounded"*/}
          {/*    fontStyle="RR16"*/}
          {/*    width="100%"*/}
          {/*    iconPosition="left"*/}
          {/*    labelColor="red"*/}
          {/*  /></ListItemUi>*/}
        </SectionUi>
        {
          props.account.role === ACCOUNT_ROLE_ADMIN &&
          <SectionUi title={t`Development`} rounded={true}>
            <ListItemUi
              title={t`Enable debug`}
              description={t`Show detailed chart and stub device`}
              itemOrder={30}
              hasSwitch={true}
              checked={settingState.isDebug}
              onClick={() => {
                dispatch(setIsDebug(!settingState.isDebug));
              }}
            />
            {
              settingState.isDebug &&
              <>
                <ListItemUi
                  title={t`Show debug chart`}
                  description={t`Show detailed chart`}
                  itemOrder={40}
                  hasSwitch={true}
                  checked={settingState.isShowChartDebug}
                  onClick={() => {
                    dispatch(setIsShowChartDebug(!settingState.isShowChartDebug));
                  }}
                />

                <ListItemUi
                  title={t`Show pre direction`}
                  itemOrder={61}
                  hasSwitch={true}
                  checked={settingState.showPreDirection}
                  onClick={() => {
                    dispatch(setShowPreDirection(!settingState.showPreDirection));
                  }}
                />
                <ListItemUi
                  title={t`Show post direction`}
                  itemOrder={62}
                  hasSwitch={true}
                  checked={settingState.showPostDirection}
                  onClick={() => {
                    dispatch(setShowPostDirection(!settingState.showPostDirection));
                  }}
                />
              </>
            }
          </SectionUi>
        }
        <FooterComponent />
      </IonContent>
    </IonPage>
  </>;
};
