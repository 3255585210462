import React from "react";
import { EXHIBITION_RIMINI_2024 } from "@common/constants";
import { LINK_SHOP } from "@feature/account/accountConstants";
import { PrimaryAccentPositiveButtonUi } from "@ui/PrimaryAccentPositiveButtonUi/PrimaryAccentPositiveButtonUi";
import { PrimaryButtonUi } from "@ui/PrimaryButtonUi/PrimaryButtonUi";
import { SeparatorUi } from "@ui/SeparatorUi/SeparatorUi";
import {
  Trans,
  t,
} from "@lingui/macro";
import {
  router,
  useAppDispatch,
} from "@core/redux/store";
import { routes } from "@core/route";
import { setActiveRoute } from "@feature/tab/slice/tabsSlice";
import { useCreateAccountGuestWithSampleDataMutation } from "@feature/account/api/accountApi";

export const LoginOrBuyButtonsComponent = () => {
  const dispatch = useAppDispatch();

  const [ createAccountGuestWithSampleData ] = useCreateAccountGuestWithSampleDataMutation();

  const goToAccountLogin = () => {
    router.replace(routes.accountLogin.redirectPath);
  };

  const goToBuyDevice = () => {
    window.open(LINK_SHOP);
  };

  const goToAccountSignupRimini2024 = () => {
    router.replace(routes.accountSignup.routePath.replace(":exhibition", EXHIBITION_RIMINI_2024));
  };

  const createGuest = async() => {
    try {
      await createAccountGuestWithSampleData().unwrap();
      router.replace(routes.dashboard.redirectPath);
      dispatch(setActiveRoute(routes.dashboard.code));
    } catch (err) {
      console.log(err);
    }
  };

  return <>
    <PrimaryButtonUi
      label={<Trans>Log in</Trans>}
      onClick={goToAccountLogin}
    />
    <SeparatorUi
      background={"darkTint"}
      label={t`or`}
      marginBottom={16}
      marginTop={16}
    />
    {/*<PrimaryPositiveButtonUi*/}
    {/*  label={t`Try at Rimini Welness 2024`}*/}
    {/*  onClick={goToAccountSignupRimini2024}*/}
    {/*/>*/}
    <SeparatorUi />
    <PrimaryAccentPositiveButtonUi
      label={<Trans>Buy the Bodygon device</Trans>}
      onClick={goToBuyDevice} />
    {/*<SeparatorUi />*/}
    {/*<PrimaryButtonUi*/}
    {/*  label={t`Continue in trial mode`}*/}
    {/*  onClick={createGuest} />*/}
  </>;
};
