import { ApiData } from "@feature/api/component/ApiData";
import {
  Athlete,
  Sheet,
  Training,
} from "@common/type-graphql/generated";
import { useAlgorithmListQuery } from "@feature/algorithm/api/algorithmApi";
import {
  useAthleteGetQuery,
} from "@feature/athlete/api/athleteApi";
import { useChartListQuery } from "@feature/chart/api/chartApi";
import { useConfigurationListQuery } from "@feature/configuration/api/configurationApi";
import { useExerciseListQuery } from "@feature/exercise/api/exerciseApi";
import {
  useProfileGetQuery,
  useProfileListQuery,
} from "@feature/profile/api/profileApi";
import { useProtocolListQuery } from "@feature/protocol/api/protocolApi";
import { useRangeListQuery } from "@feature/range/api/rangeApi";
import { useRelationListQuery } from "@feature/relation/api/relationApi";
import {
  useSheetGetQuery,
} from "@feature/sheet/api/sheetApi";
import {
  useTrainingGetQuery,
  useTrainingListQuery,
} from "@feature/training/api/trainingApi";

export const PrewarmComponent = () => {
  useAlgorithmListQuery();
  useProtocolListQuery();
  useExerciseListQuery();
  useRelationListQuery();
  useConfigurationListQuery();
  useChartListQuery();

  // Comment out because of memory usage
  // const sheetListApi = useSheetListQuery();
  // const athleteListApi = useAthleteListQuery();

  return <>
    {/*<ApiData*/}
    {/*  endpoint={sheetListApi}*/}
    {/*  hideLoader={true}*/}
    {/*>*/}
    {/*  <SheetPrewarmComponent*/}
    {/*    sheetList={sheetListApi.data}*/}
    {/*  />*/}
    {/*</ApiData>*/}

    {/*<ApiData*/}
    {/*  endpoint={athleteListApi}*/}
    {/*  hideLoader={true}*/}
    {/*>*/}
    {/*  <AthletePrewarmComponent*/}
    {/*    athleteList={athleteListApi.data}*/}
    {/*  />*/}
    {/*</ApiData>*/}
  </>;
};

type SheetPrewarmComponentProps = {
  sheetList: Sheet[];
}
const SheetPrewarmComponent = (props: SheetPrewarmComponentProps) => {
  return <>
    {
      props.sheetList.map(data => (
        <SheetPrewarmComponentChild
          key={`prewarm-sheet-${ data.uuid }`}
          sheet={data}
        />
      ))
    }
  </>;
};

type AthletePrewarmComponentProps = {
  athleteList: Athlete[];
}
const AthletePrewarmComponent = (props: AthletePrewarmComponentProps) => {
  return <>
    {
      props.athleteList.map(data => (
        <div key={`prewarm-athlete-${ data.uuid }`}>
          <AthletePrewarmComponentChild athlete={data} />
          <ProfileListPrewarmComponent athlete={data} />
          <RangeListPrewarmComponent athlete={data} />
        </div>
      ))
    }
  </>;
};

type AthleteListPrewarmProps = {
  athlete: Athlete;
}
const AthletePrewarmComponentChild = (props: AthleteListPrewarmProps) => {
  useAthleteGetQuery({ where:{ uuid: props.athlete.uuid } });
  return <></>;
};

type ProfileListPrewarmProps = {
  athlete: Athlete;
}
const ProfileListPrewarmComponent = (props: ProfileListPrewarmProps) => {
  useProfileListQuery({ where:{ uuid: props.athlete.uuid } });
  return <></>;
};

type RangeListPrewarmProps = {
  athlete: Athlete;
}
const RangeListPrewarmComponent = (props: RangeListPrewarmProps) => {
  useRangeListQuery({ where:{ uuid: props.athlete.uuid } });
  return <></>;
};

type SheetPrewarmProps = {
  sheet: Sheet;
}
const SheetPrewarmComponentChild = (props: SheetPrewarmProps) => {
  useSheetGetQuery({ where:{ uuid: props.sheet.uuid } });

  const trainingListQuery = useTrainingListQuery({ where:{ uuid: props.sheet.uuid } });

  return <>
    <ApiData
      endpoint={trainingListQuery}
      hideLoader={true}
    >
      <TrainingPrewarmComponentChild
        trainingList={trainingListQuery.data}
      />
    </ApiData>
  </>;
};

type TrainingPrewarmComponentChildProps = {
  trainingList: Training[];
}
const TrainingPrewarmComponentChild = (props: TrainingPrewarmComponentChildProps) => {
  return <>
    {
      props.trainingList.map(data => (
        <TrainingPrewarmComponent
          key={`prewarm-training-${ data.uuid }`}
          training={data} />
      ))
    }
  </>;
};

type TrainingPrewarmProps = {
  training: Training;
}
const TrainingPrewarmComponent = (props: TrainingPrewarmProps) => {
  useTrainingGetQuery({ where:{ uuid: props.training.uuid } });
  useAthleteGetQuery({ where:{ uuid: props.training.athleteUuid } });
  useProfileGetQuery({ where:{ uuid: props.training.profileUuid } });
  return <></>;
};
