import { ApiData } from "@feature/api/component/ApiData";
import { Exercise } from "@common/type-graphql/generated";
import {
  IonChip,
  IonIcon,
  IonLabel,
} from "@ionic/react";
import { barbellOutline } from "ionicons/icons";
import { getExeciseTitleByCode } from "@feature/exercise/service/execiseService";
import { useExerciseListQuery } from "@feature/exercise/api/exerciseApi";

type Props = {
  exerciseUuid: string;
}

export const ExerciseChipComponent = (props: Props) => {
  const exerciseListApi = useExerciseListQuery();

  return <>
    <ApiData endpoint={exerciseListApi}>
      <ExerciseChip exercise={exerciseListApi.data?.find(exercise => exercise.uuid === props.exerciseUuid)} />
    </ApiData>
  </>;
};

type ExerciseProps = {
  exercise: Exercise;
}

const ExerciseChip = (props: ExerciseProps) => {
  return <>
    <IonChip
      class="ionChipStyle"
      color="primary">
      <IonIcon
        icon={barbellOutline}
        size="small" />
      <IonLabel style={{
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        width: "100%",
      }}>
        {
          getExeciseTitleByCode(props.exercise.code)
        }
      </IonLabel>
    </IonChip>
  </>;
};
