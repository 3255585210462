import { AthleteConnectForm } from "@feature/athlete/component/form/athleteConnectForm";
import { HeaderUi } from "@ui/HeaderUi/HeaderUi";
import {
  IonContent,
  IonPage,
} from "@ionic/react";
import { i18n } from "@lingui/core";
import { router } from "@core/redux/store";
import { routes } from "@core/route";
import { useParams } from "react-router-dom";

type Params = {
  athleteUuid: string;
}

export const AthleteConnectPage = () => {
  const params = useParams<Params>();

  if (!params.athleteUuid) {
    router.replace(routes.dashboard.redirectPath);
  }

  return <>
    <IonPage>
      <HeaderUi
        pageTitle={i18n._(routes.athleteConnect.title)}
        backToRoute={routes.dashboard} />
      <IonContent>
        <AthleteConnectForm
          athleteUuid={params.athleteUuid}
        />
      </IonContent>
    </IonPage>
  </>;
};
