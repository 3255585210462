import {
  DIRECTION_ASCENDING,
  DIRECTION_DESCENDING,
} from "@common/model/Direction";
import {
  PHASES_IGNORE_REASON_VISIBLE,
  PHASE_IGNORE_REASON_OVER_TOP,
  PHASE_IGNORE_REASON_UNDER_BOTTOM,
  Phase,
} from "@common/model/Phase";
import { ReactNode } from "react";
import { Serie } from "@common/model/Serie";
import {
  Trans,
  t,
} from "@lingui/macro";
import { getUniqueId } from "@util/getUniqueId";
import { roundToDecimals } from "@util/roundToDecimals";
import {
  selectSettingState,
  switchPhysicsUnitAverageSpeed,
  switchPhysicsUnitDisplacement,
  switchPhysicsUnitDuration,
  switchPhysicsUnitPower,
} from "@feature/setting/slice/settingSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "@core/redux/store";
import { useStyles } from "@ui/TableUi/useStyles";

type Props = {
  serie: Serie;
}

export const TablePhasesComponent = (props: Props) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();

  const settingState = useAppSelector(selectSettingState);

  const concentricPhases = getVisibleConcentricPhases(props.serie, settingState.showPreDirection, settingState.showPostDirection);

  const getPower = (value: number): number => {
    switch (settingState.physicsUnits.power) {
      case "kW":
        return value / 1000;
      default:
        return value;
    }
  };

  const getDisplacement = (value: number): number => {
    switch (settingState.physicsUnits.displacement) {
      case "cm":
        return value / 10;
      case "m":
        return value / 1000;
      default:
        return value;
    }
  };

  const getDuration = (value: number): number => {
    switch (settingState.physicsUnits.duration) {
      case "s":
        return value / 1000;
      default:
        return value;
    }
  };

  const getAverageSpeed = (value: number): number => {
    switch (settingState.physicsUnits.averageSpeed) {
      case "cm/s":
        return roundToDecimals(value * 100);
      default:
        return roundToDecimals(value);
    }
  };

  if (!concentricPhases.length) {
    return <></>;
  }

  return <>
    <div className={classes.tableRoot}>
      <table>
        <thead>
          <tr>
            <th>
              <Trans>N</Trans>
              <br />
            </th>
            <th onClick={() => {
              dispatch(switchPhysicsUnitPower());
            }}>
              <Trans>Power</Trans><br />
              ({settingState.physicsUnits.power})
            </th>
            <th onClick={() => {
              dispatch(switchPhysicsUnitAverageSpeed());
            }}>
              <Trans>Average speed</Trans><br />
              ({ settingState.physicsUnits.averageSpeed })
            </th>
            <th onClick={() => {
              dispatch(switchPhysicsUnitDisplacement());
            }}>
              <Trans>Displacement</Trans><br />
              ({settingState.physicsUnits.displacement})
            </th>
            <th onClick={() => {
              dispatch(switchPhysicsUnitDuration());
            }}>
              <Trans>Duration</Trans><br />
              ({settingState.physicsUnits.duration})
            </th>
            <th>
              <Trans>Direction</Trans>
            </th>
          </tr>
        </thead>
        <tbody>
          {
            concentricPhases.map(phase =>
              <tr key={getUniqueId()}>
                <td className={classes.tdCell}>
                  { getPhaseNumber(concentricPhases, phase) }
                </td>
                <td className={classes.tdCell}>
                  { getPower(phase.physics.power) } { settingState.physicsUnits.power }
                </td>
                <td className={classes.tdCell}>
                  { getAverageSpeed(phase.physics.speed) } { settingState.physicsUnits.averageSpeed }
                </td>
                <td className={classes.tdCell}>
                  { getDisplacement(phase.physics.displacement) } { settingState.physicsUnits.displacement }
                </td>
                <td className={classes.tdCell}>
                  { getDuration(phase.physics.duration) } { settingState.physicsUnits.duration }
                </td>
                <td className={classes.tdCell}>
                  { formatDirection(phase) }
                </td>
              </tr>
            )
          }
        </tbody>
      </table>
    </div>
  </>;
};

const getPhaseNumber = (phases: Phase[], phase: Phase): number => {
  const currentPhaseIndex = phases.indexOf(phase);

  let index = 1;
  phases.forEach((phase, i) => {
    if (
      phase.direction === DIRECTION_ASCENDING &&
      phase.positions.isAllInRange &&
      i < currentPhaseIndex
    ) {
      index++;
    }
  });

  if (
    phase.direction === DIRECTION_ASCENDING &&
    phase.positions.isAllInRange
  ) {
    return index;
  }

  if (
    phase.direction === DIRECTION_ASCENDING &&
    phase.positions.endsInMinRange
  ) {
    return index;
  }

  if (
    phase.direction === DIRECTION_ASCENDING &&
    phase.physics.elevation
  ) {
    return index - 1;
  }

  if (
    phase.direction === DIRECTION_DESCENDING
  ) {
    return index;
  }

  return 666;
};

const getVisibleConcentricPhases = (
  serie: Serie,
  showPreDirection: boolean,
  showPostDirection: boolean
): Phase[] => {
  const concentricPhases = serie.finalPhases.filter(phase => {
    return (
      !phase.ignoreReason ||
      PHASES_IGNORE_REASON_VISIBLE.includes(phase.ignoreReason) ||
      (
        phase.ignoreReason === PHASE_IGNORE_REASON_UNDER_BOTTOM &&
        showPreDirection
      ) ||
      (
        phase.ignoreReason === PHASE_IGNORE_REASON_OVER_TOP &&
        showPostDirection
      )
    ) &&
      (
        phase.direction === DIRECTION_ASCENDING //||
        //phase.direction === DIRECTION_DESCENDING
      );
  });

  if (concentricPhases[0]?.ignoreReason === PHASE_IGNORE_REASON_OVER_TOP) {
    concentricPhases.splice(0, 1);
  }

  return concentricPhases;
};

const formatDirection = (phase: Phase): ReactNode => {
  const direction = phase.direction;
  const ignoreReason = phase.ignoreReason;
  switch (direction) {
    case DIRECTION_ASCENDING:
      if (ignoreReason) {
        if (ignoreReason === PHASE_IGNORE_REASON_UNDER_BOTTOM) {
          return <Trans>Pre ascending</Trans>;
        }
        if (ignoreReason === PHASE_IGNORE_REASON_OVER_TOP) {
          return <Trans>Post ascending</Trans>;
        }
        return t`Ascending ${ ignoreReason }`;
      }
      if (phase.physics.elevation) {
        return <Trans>Flying</Trans>;
      }
      return <Trans>Ascending</Trans>;
    case DIRECTION_DESCENDING:
      if (ignoreReason) {
        if (ignoreReason === PHASE_IGNORE_REASON_OVER_TOP) {
          return <Trans>Pre descending</Trans>;
        }
        if (ignoreReason === PHASE_IGNORE_REASON_UNDER_BOTTOM) {
          return <Trans>Post descending</Trans>;
        }
        return t`Descending ${ ignoreReason }`;
      }
      return <Trans>Descending</Trans>;
    default:
      return `${ direction } ${ ignoreReason }`;
  }
};
