import React from "react";
import { ApiDataList } from "@feature/api/component/ApiDataList";
import { ButtonUi } from "@ui/ButtonUi/ButtonUi";
import {
  HistoryWorkout,
  HistoryWorkoutOrderByWithRelationInput,
} from "@common/type-graphql/generated";
import { HistoryWorkoutItemComponent } from "@feature/history/component/historyWorkoutItemComponent";
import { ListItemUi } from "@ui/ListItemUi/ListItemUi";
import { confirmDelete } from "@feature/confirm/service/delete";
import { deletePayloadInput } from "@util/deletePayloadInput";
import { getUniqueId } from "@util/getUniqueId";
import {
  router,
  useAppDispatch,
} from "@core/redux/store";
import { routes } from "@core/route";
import { t } from "@lingui/macro";
import { toast } from "@feature/toast/slice/toastSlice";
import {
  useHistoryTrainingSessionEditMutation,
  useHistoryWorkoutListQuery,
} from "@feature/history/api/historyApi";
import { useIonActionSheet } from "@ionic/react";

type Props = {
  historyTrainingSessionUuid: string;
}

export const HistoryWorkoutListComponent = (props: Props) => {
  const orderBy: [HistoryWorkoutOrderByWithRelationInput] = [ { dateTime: "desc" } ];
  const historyWorkoutListApi = useHistoryWorkoutListQuery({
    where: { historyTrainingSessionUuid: { equals: props.historyTrainingSessionUuid } },
    orderBy: orderBy,
  });

  return <>
    <ApiDataList
      endpoint={historyWorkoutListApi}
    >
      <HistoryWorkoutListComponentChild
        historyTrainingSessionUuid={props.historyTrainingSessionUuid}
        historyWorkouts={historyWorkoutListApi.data}
      />
    </ApiDataList>
  </>;
};

type PropsChild = {
  historyTrainingSessionUuid: string;
  historyWorkouts: HistoryWorkout[];
}

const HistoryWorkoutListComponentChild = (props: PropsChild) => {
  const dispatch = useAppDispatch();
  const [ edit ] = useHistoryTrainingSessionEditMutation();
  const [ present ] = useIonActionSheet();

  const onDelete = async() => {
    confirmDelete({
      header: t`Delete this training session`,
      present: present,
      action: async() => {
        try {
          await edit({
            data: deletePayloadInput(),
            where: { uuid: props.historyTrainingSessionUuid },
          }).unwrap();
          dispatch(toast({ message: t`Training session deleted successfully.` }));
          router.replace(routes.historyList.redirectPath);
        } catch (error) {}
      },
    });
  };

  return <>
    <ListItemUi>
      {
        props.historyWorkouts.map(historyWorkout =>
          <HistoryWorkoutItemComponent
            key={getUniqueId()}
            uuid={historyWorkout.uuid}
          />
        )
      }

      <ButtonUi
        appearance="rounded"
        backgroundColor="black"
        fontStyle="RR16"
        labelColor="red"
        type="link"
        isActive={false}
        label={t`Delete this session`}
        onClick={onDelete}
      />
    </ListItemUi>
  </>;
};
