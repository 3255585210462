import {
  ButtonClick,
  Callback,
  HeaderBackground,
  MultipleChildrens,
} from "@types-custom";
import { FeedbackBoxUi } from "@ui/FeedbackBoxUi/FeedbackBoxUi";
import { IconUi } from "@ui/IconUi/IconUi";
import { RunStatus } from "@feature/run/slice/runSlice";
import { TimerUi } from "@ui/TimerUi/TimerUi";
import { Trans } from "@lingui/macro";
import { useStyles } from "./useStyles";

interface Props {
  timerDate: number | null;
  runStatus: RunStatus;
  backgroundColor: HeaderBackground;
  isRecoverPhase: boolean;
  recoverAmount?: number;
  // subMenuItems: ReactNode[] | ReactNode;
  isAutoStartEnabled: boolean;
  isDeviceConnected: boolean;
  isRunning: boolean;
  feedbackText: string;
  timerCallback?: Callback;
  details?: MultipleChildrens;
  onAutoButtonClick?: ButtonClick;
  onDeviceButtonClick?: ButtonClick;
  headerTextColor: string;
  feedbackTextColor: string;
}

export const HeaderRunUi = (props: Props) => {
  const {
    classes,
  } = useStyles({
    background: props.backgroundColor,
    isAutoStartEnabled: props.isAutoStartEnabled,
    headerTextColor: props.headerTextColor,
  });

  // const {
  //   $subMenu,
  //   handleSubMenuToggle,
  //   setSubMenuVisible,
  // } = useLogic();

  return <>
    <div className={classes.headerRunPlaceholder} />
    <div className={classes.actionHeaderRoot}>
      <div className={classes.actionHeader}>
        <div className={classes.infoWrapper}>
          <div className={classes.timerAndFeedback}>
            <TimerUi
              runStatus={props.runStatus}
              timerDate={props.timerDate}
              background={props.backgroundColor}
              isRecoverPhase={props.isRecoverPhase}
              isAutoStartEnabled={props.isAutoStartEnabled}
              recoverAmount={props.recoverAmount}
              callback={props.timerCallback}
              headerTextColor={props.headerTextColor}
            />
            <FeedbackBoxUi
              headerTextColor={props.headerTextColor}
              feedbackTextColor={props.feedbackTextColor}
              runStatus={props.runStatus}
              feedback={props.feedbackText}
              background={props.backgroundColor}
              isRecoverPhase={props.isRecoverPhase}
            />
          </div>

          {
            props.details !== undefined &&
            <div className={classes.valueLabelText}>
              { props.details }
            </div>
          }
        </div>

        {
          !props.isRunning &&
          <div className={classes.controlsWrapper}>
            <strong
	            className={classes.autoButton}
              onClick={props.onAutoButtonClick}
            >
              <Trans>AUTO</Trans>
            </strong>

	          <strong
		          className={classes.autoButton}
		          onClick={props.onDeviceButtonClick}
	          >
		          <IconUi
                isToggled={false}
                branded={false}
                iconColor={props.isDeviceConnected ? "neon" : "white"}
                icon={"bodygon-devices"}
              />
	          </strong>

            {/*<ButtonUi*/}
            {/*  label=""*/}
            {/*  type="link"*/}
            {/*  backgroundColor="transparent"*/}
            {/*  icon="bodygon-devices"*/}
            {/*  iconColor={props.isDeviceConnected ? "neon" : "white"}*/}
            {/*  iconPosition="left"*/}
            {/*  fontStyle="RB12"*/}
            {/*  appearance="rounded"*/}
            {/*  hasToggle={true}*/}
            {/*  hasPressed={true}*/}
            {/*  onClick={props.onDeviceButtonClick}*/}
            {/*/>*/}
            {/*<ButtonUi*/}
            {/*  label=""*/}
            {/*  type="primary"*/}
            {/*  labelColor="pureWhite"*/}
            {/*  backgroundColor="darkTint"*/}
            {/*  icon="expand-down"*/}
            {/*  iconColor="grey"*/}
            {/*  iconPosition="top"*/}
            {/*  fontStyle="RR16"*/}
            {/*  appearance="circle"*/}
            {/*  hasToggle={true}*/}
            {/*  hasPressed={true}*/}
            {/*  onClick={handleSubMenuToggle}*/}
            {/*  isActive={false}*/}
            {/*/>*/}
          </div>
        }
      </div>

      {/*<div className={classes.actionHeaderMenuRoot} ref={$subMenu}>*/}
      {/*  <SectionUi*/}
      {/*    onClick={() => {*/}
      {/*      setSubMenuVisible(false);*/}
      {/*    }}>*/}
      {/*    {props.subMenuItems}*/}
      {/*  </SectionUi>*/}
      {/*</div>*/}
    </div>
  </>;
};
