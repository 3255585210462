import {
  CHART_CODE_AVERAGE_SPEED_BAR,
  CHART_CODE_ECCENTRIC_CONCENTRIC,
  CHART_CODE_FULL_CONTINUOUS,
  CHART_CODE_JUMP,
  CHART_CODE_POWER_AND_FLY_BAR,
  CHART_CODE_POWER_BAR,
  CHART_CODE_SOLO_CONCENTRIC,
  ChartCode,
} from "@common/model/Chart";
import { Chart } from "@common/type-graphql/generated";
import { ChartConcentricComponent } from "@feature/chart/component/ChartConcentricComponent";
import { ChartConcentricLiveComponent } from "@feature/chart/component/ChartConcentricLiveComponent";
import { ChartDebugComponent } from "@feature/chart/component/ChartDebugComponent";
import { ChartEccentricConcentricComponent } from "@feature/chart/component/ChartEccentricConcentricComponent";
import { ChartEccentricConcentricLiveComponent } from "@feature/chart/component/ChartEccentricConcentricLiveComponent";
import { ChartJumpComponent } from "@feature/chart/component/ChartJumpComponent";
import { ChartJumpLiveComponent } from "@feature/chart/component/ChartJumpLiveComponent";
import { ChartPowerAndFlyBarComponent } from "@feature/chart/component/ChartPowerAndFlyBarComponent";
import { ChartPowerAndFlyBarLiveComponent } from "@feature/chart/component/ChartPowerAndFlyBarLiveComponent";
import { ChartPowerBarComponent } from "@feature/chart/component/ChartPowerBarComponent";
import { ChartPowerBarLiveComponent } from "@feature/chart/component/ChartPowerBarLiveComponent";
import { ChartSpeedBarComponent } from "@feature/chart/component/ChartSpeedBarComponent";
import { ChartSpeedBarLiveComponent } from "@feature/chart/component/ChartSpeedBarLiveComponent";
import { PowerReferenceValue } from "@common/model/PowerReferenceValue";
import { RangeMinMax } from "@common/model/Range";
import { Serie } from "@common/model/Serie";
import { getSpeedSuggested } from "@common/service/serieService";
import { selectSettingState } from "@feature/setting/slice/settingSlice";
import {
  useAppSelector,
} from "@core/redux/store";

type Props = {
  serie: Serie;
  isLive: boolean;
  charts: Chart[];
  liveCharts: Chart[];
  athleteUuid: string;
  exerciseUuid: string;
  rangeExtended?: RangeMinMax;
  range?: RangeMinMax;
  powerReference?: PowerReferenceValue;
  usePower: boolean;
  minPower?: number;
  maxPower?: number;
  useSpeed: boolean;
  minSpeed?: number;
  maxSpeed?: number;
  serieUuid?: string;
};

export const ChartsComponent = (props: Props) => {
  const settingState = useAppSelector(selectSettingState);

  const goToChartDetails = (chartCode: ChartCode) => () => {
    // if (!props.serieUuid) {
    //   return;
    // }
    // historyController.push(routes.historyViewChart.routePath
    //   .replace(getRouteUuidPlaceholder("historySerie"), props.serieUuid)
    //   .replace(getRouteUuidPlaceholder("chartCode"), chartCode)
    // );
  };

  return <>
    {
      settingState.isShowChartDebug &&
		  <ChartDebugComponent
			  serie={props.serie}
			  range={props.range}
			  rangeExtended={props.rangeExtended}
		  />
    }

    {
      props.isLive &&
      props.liveCharts.find(c => c.code === CHART_CODE_POWER_BAR) &&
      props.range &&
        <ChartPowerBarLiveComponent
          serie={props.serie}
          powerReference={props.powerReference}
          minPower={props.minPower}
          maxPower={props.maxPower}
        />
    }

    {
      !props.isLive &&
      props.charts.find(c => c.code === CHART_CODE_POWER_BAR) &&
      props.range &&
	    <div onClick={goToChartDetails(CHART_CODE_POWER_BAR)}>
	      <ChartPowerBarComponent
	        serie={props.serie}
	        powerReference={props.powerReference}
	        minPower={props.minPower}
	        maxPower={props.maxPower}
	      />
	    </div>
    }

    {
      !props.isLive &&
      props.charts.find(c => c.code === CHART_CODE_POWER_AND_FLY_BAR) &&
      props.range &&
	    <div onClick={goToChartDetails(CHART_CODE_POWER_AND_FLY_BAR)}>
	      <ChartPowerAndFlyBarComponent
	        serie={props.serie}
	        powerReference={props.powerReference}
	        minPower={props.minPower}
	        maxPower={props.maxPower}
	      />
	    </div>
    }

    {
      props.isLive &&
      props.charts.find(c => c.code === CHART_CODE_POWER_AND_FLY_BAR) &&
      props.range &&
	    <div onClick={goToChartDetails(CHART_CODE_POWER_AND_FLY_BAR)}>
	      <ChartPowerAndFlyBarLiveComponent
	        serie={props.serie}
	        powerReference={props.powerReference}
	        minPower={props.minPower}
	        maxPower={props.maxPower}
	      />
	    </div>
    }

    {
      props.isLive &&
      props.liveCharts.find(c => c.code === CHART_CODE_AVERAGE_SPEED_BAR) &&
      props.range &&
      props.useSpeed &&
      (
        props.minSpeed ||
        props.maxSpeed
      ) &&
		  <ChartSpeedBarLiveComponent
			  serie={props.serie}
			  minSpeed={props.minSpeed}
			  maxSpeed={props.maxSpeed}
			  speedSuggested={getSpeedSuggested(props.range)}
		  />
    }

    {
      !props.isLive &&
      props.charts.find(c => c.code === CHART_CODE_AVERAGE_SPEED_BAR) &&
      props.range &&
      props.useSpeed &&
      (
        props.minSpeed ||
        props.maxSpeed
      ) &&
	    <div onClick={goToChartDetails(CHART_CODE_AVERAGE_SPEED_BAR)}>
	      <ChartSpeedBarComponent
	        serie={props.serie}
	        minSpeed={props.minSpeed}
	        maxSpeed={props.maxSpeed}
	        speedSuggested={getSpeedSuggested(props.range)}
	      />
	    </div>
    }

    {
      props.isLive &&
      props.liveCharts.find(c => c.code === CHART_CODE_SOLO_CONCENTRIC) &&
      <ChartConcentricLiveComponent
        serie={props.serie}
        range={props.range}
        rangeExtended={props.rangeExtended}
        minPower={props.minPower}
        maxPower={props.maxPower}
        minSpeed={props.minSpeed}
        maxSpeed={props.maxSpeed}
      />
    }

    {
      !props.isLive &&
      props.charts.find(c => c.code === CHART_CODE_SOLO_CONCENTRIC) &&
	    <div onClick={goToChartDetails(CHART_CODE_SOLO_CONCENTRIC)}>
	      <ChartConcentricComponent
	        finalPoints={props.serie.finalPoints}
	        simplifiedPoints={props.serie.simplifiedPoints}
	        finalPhases={props.serie.finalPhases}
	        range={props.range}
	        rangeExtended={props.rangeExtended}
	        minPower={props.minPower}
	        maxPower={props.maxPower}
	        minSpeed={props.minSpeed}
	        maxSpeed={props.maxSpeed}
	      />
	    </div>
    }

    {
      props.isLive &&
      props.liveCharts.find(c => c.code === CHART_CODE_JUMP) &&
		  <ChartJumpLiveComponent
			  serie={props.serie}
			  range={props.range}
			  rangeExtended={props.rangeExtended}
			  minPower={props.minPower}
			  maxPower={props.maxPower}
			  minSpeed={props.minSpeed}
			  maxSpeed={props.maxSpeed}
		  />
    }

    {
      !props.isLive &&
      props.charts.find(c => c.code === CHART_CODE_JUMP) &&
		  <div onClick={goToChartDetails(CHART_CODE_JUMP)}>
			  <ChartJumpComponent
				  finalPoints={props.serie.finalPoints}
				  simplifiedPoints={props.serie.simplifiedPoints}
				  finalPhases={props.serie.finalPhases}
				  range={props.range}
				  rangeExtended={props.rangeExtended}
				  minPower={props.minPower}
				  maxPower={props.maxPower}
				  minSpeed={props.minSpeed}
				  maxSpeed={props.maxSpeed}
			  />
		  </div>
    }

    {
      props.isLive &&
      props.liveCharts.find(c => c.code === CHART_CODE_ECCENTRIC_CONCENTRIC) &&
		  <ChartEccentricConcentricLiveComponent
			  serie={props.serie}
			  range={props.range}
			  rangeExtended={props.rangeExtended}
			  minPower={props.minPower}
			  maxPower={props.maxPower}
			  minSpeed={props.minSpeed}
			  maxSpeed={props.maxSpeed}
		  />
    }

    {
      !props.isLive &&
      props.charts.find(c => c.code === CHART_CODE_ECCENTRIC_CONCENTRIC) &&
		  <div onClick={goToChartDetails(CHART_CODE_ECCENTRIC_CONCENTRIC)}>
			  <ChartEccentricConcentricComponent
				  finalPoints={props.serie.finalPoints}
				  simplifiedPoints={props.serie.simplifiedPoints}
				  finalPhases={props.serie.finalPhases}
				  range={props.range}
				  rangeExtended={props.rangeExtended}
				  minPower={props.minPower}
				  maxPower={props.maxPower}
				  minSpeed={props.minSpeed}
				  maxSpeed={props.maxSpeed}
			  />
		  </div>
    }

    {
      props.isLive &&
      props.liveCharts.find(c => c.code === CHART_CODE_FULL_CONTINUOUS) &&
		  <ChartEccentricConcentricLiveComponent
			  serie={props.serie}
			  range={props.range}
			  rangeExtended={props.rangeExtended}
			  minPower={props.minPower}
			  maxPower={props.maxPower}
			  minSpeed={props.minSpeed}
			  maxSpeed={props.maxSpeed}
		  />
    }

    {
      !props.isLive &&
      props.charts.find(c => c.code === CHART_CODE_FULL_CONTINUOUS) &&
		  <div onClick={goToChartDetails(CHART_CODE_FULL_CONTINUOUS)}>
			  <ChartEccentricConcentricComponent
				  finalPoints={props.serie.finalPoints}
				  simplifiedPoints={props.serie.simplifiedPoints}
				  finalPhases={props.serie.finalPhases}
				  range={props.range}
				  rangeExtended={props.rangeExtended}
				  minPower={props.minPower}
				  maxPower={props.maxPower}
				  minSpeed={props.minSpeed}
				  maxSpeed={props.maxSpeed}
			  />
		  </div>
    }
  </>;
};
