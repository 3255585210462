import CapacitorStorage from "redux-persist-capacitor";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistCombineReducers,
  persistStore,
} from "redux-persist";
import { STORE_ACCOUNT_KEY } from "@feature/account/accountConstants";
import {
  STORE_DEVICE_AUTO_CONNECT_KEY,
  STORE_DEVICE_KEY,
} from "@feature/device/deviceConstants";
import { STORE_HISTORY_KEY } from "@feature/history/historyConstants";
import { STORE_LOADING_KEY } from "@feature/loading/loadingConstants";
import {
  STORE_OFFLINE_KEY,
  STORE_ONLINE_KEY,
} from "@feature/offline/offlineConstants";
import { STORE_ROUTER_KEY } from "@feature/router/routerConstants";
import {
  STORE_RUN_CALIBRATION_KEY,
  STORE_RUN_KEY,
  STORE_TIMER_KEY,
} from "@feature/run/runConstants";
import { STORE_SETTING_KEY } from "@feature/setting/settingConstants";
import { STORE_SUSPENSION_KEY } from "@feature/suspension/suspensionConstants";
import { STORE_TABS_KEY } from "@feature/tab/tabsConstants";
import { STORE_TOAST_KEY } from "@feature/toast/toastConstants";
import {
  TypedUseSelectorHook,
  useDispatch,
  useSelector,
} from "react-redux";
import { accountSlice } from "@feature/account/slice/accountSlice";
import { api } from "@core/api";
import {
  autoBatchEnhancer,
  configureStore,
} from "@reduxjs/toolkit";
import { createBrowserHistory } from "history";
import { createReduxHistoryContext } from "redux-first-history";
import { deviceAutoConnectSlice } from "@feature/device/slice/deviceAutoConnectSlice";
import { deviceSlice } from "@feature/device/slice/deviceSlice";
import { errorHandlerMiddleware } from "@core/redux/errorHandlerMiddleware";
import { historySlice } from "@feature/history/slice/historySlice";
import { loadingSlice } from "@feature/loading/slice/loadingSlice";
import { offlineMiddleware } from "@feature/offline/redux/offlineMiddleware";
import { offlineSlice } from "@feature/offline/slice/offlineSlice";
import { onlineSlice } from "@feature/offline/slice/onlineSlice";
import { runCalibrationSlice } from "@feature/run/slice/runCalibrationSlice";
import { runSlice } from "@feature/run/slice/runSlice";
import { runTimerSlice } from "@feature/run/slice/runTimerSlice";
import { settingSlice } from "@feature/setting/slice/settingSlice";
import { suspensionSlice } from "@feature/suspension/slice/suspensionSlice";
import { tabsSlice } from "@feature/tab/slice/tabsSlice";
import { toastSlice } from "@feature/toast/slice/toastSlice";

export const STORE_ROOT_KEY = "root";

const routerSlice = createReduxHistoryContext({
  history: createBrowserHistory(),
  routerReducerKey: STORE_ROUTER_KEY,
  reduxTravelling: true,
  // selectRouterState: null,
  // savePreviousLocations: 0,
  // batch: null,
  // reachGlobalHistory: null,
});

export const persistConfig = {
  key: STORE_ROOT_KEY,
  storage: CapacitorStorage,
  timeout: 2000,
  throttle: 0,
  version: 1,
  whitelist: [
    api.reducerPath,
    STORE_ACCOUNT_KEY,
    STORE_RUN_KEY,
    STORE_HISTORY_KEY,
    STORE_TIMER_KEY,
    STORE_DEVICE_AUTO_CONNECT_KEY,
    STORE_SETTING_KEY,
  ],
};

const rootReducer = {
  [STORE_ROUTER_KEY]: routerSlice.routerReducer,
  [STORE_TOAST_KEY]: toastSlice.reducer,
  [STORE_RUN_KEY]: runSlice.reducer,
  [STORE_RUN_CALIBRATION_KEY]: runCalibrationSlice.reducer,
  [STORE_HISTORY_KEY]: historySlice.reducer,
  [STORE_TIMER_KEY]: runTimerSlice.reducer,
  [STORE_TABS_KEY]: tabsSlice.reducer,
  [STORE_ACCOUNT_KEY]: accountSlice.reducer,
  [STORE_LOADING_KEY]: loadingSlice.reducer,
  [STORE_ONLINE_KEY]: onlineSlice.reducer,
  [STORE_OFFLINE_KEY]: offlineSlice.reducer,
  [STORE_DEVICE_KEY]: deviceSlice.reducer,
  [STORE_DEVICE_AUTO_CONNECT_KEY]: deviceAutoConnectSlice.reducer,
  [STORE_SETTING_KEY]: settingSlice.reducer,
  [STORE_SUSPENSION_KEY]: suspensionSlice.reducer,
  api: api.reducer,
};

const persistedReducer = persistCombineReducers(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  // devTools: process.env.NODE_ENV !== "production",
  enhancers: existingEnhancers => {
    return existingEnhancers.concat(autoBatchEnhancer());
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
        ],
        ignoredActionPaths: [
          new RegExp(".*.dateTime"),
          "payload.expiresAt",
          "meta.baseQueryMeta.response.headers",
        ],
      },
      // immutableCheck: false, // disable because of slow performance on development @see https://redux-toolkit.js.org/api/getDefaultMiddleware
      // actionCreatorCheck: false, // disable because of slow performance on development @see https://redux-toolkit.js.org/api/getDefaultMiddleware
    })
      .concat(routerSlice.routerMiddleware)
      .concat(api.middleware)
      .concat(offlineMiddleware)
      .concat(errorHandlerMiddleware),
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const router = routerSlice.createReduxHistory(store);
