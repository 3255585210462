import { Bar } from "react-chartjs-2";
import {
  type ChartData,
  ChartOptions,
} from "chart.js";
import { DIRECTION_ASCENDING } from "@common/model/Direction";
import {
  PHASES_IGNORE_REASON_VISIBLE,
  Phase,
} from "@common/model/Phase";
import { PowerReferenceValue } from "@common/model/PowerReferenceValue";
import { Serie } from "@common/model/Serie";
import { Trans } from "@lingui/macro";
import {
  formatToPower,
  getColorByPhaseAndPower,
} from "@feature/chart/service/chartSerivice";
import { roundUpToHigherHundred } from "@util/roundUpToHigherHundred";
import {
  useEffect,
  useState,
} from "react";
import { useRunHalfHeight } from "@feature/run/hook/useRunHalfHeight";
import { useStyles } from "@theme/makeStyles";

type Props = {
  serie: Serie;
  powerReference?: PowerReferenceValue;
  minPower?: number;
  maxPower?: number;
}

export const ChartPowerAndFlyBarLiveComponent = (props: Props) => {
  const { theme } = useStyles();

  const serie = props.serie;
  const concentricPhases = serie.finalPhases.filter(phase => {
    return (
      !phase.ignoreReason ||
      PHASES_IGNORE_REASON_VISIBLE.includes(phase.ignoreReason)
    ) &&
      phase.direction === DIRECTION_ASCENDING &&
      !Boolean(phase.ignoreReason) &&
      phase.positions &&
      phase.positions.isAllInRange;
  });
  const minPower = props.minPower ?? 0;
  const maxPower = props.maxPower ?? 0;

  let maxY = props.powerReference ? roundUpToHigherHundred(props.powerReference) : 100;
  maxY = concentricPhases.reduce((value, phase) => {
    return Math.max(value, roundUpToHigherHundred(phase.physics.power));
  }, maxY);

  const backgroundColors = (): string[] => {
    return concentricPhases.map(phase => {
      return getColorByPhaseAndPower(phase, false, minPower, maxPower);
    });
  };

  const { runHalfHeight } = useRunHalfHeight();

  const [
    height,
    setHeight,
  ] = useState(runHalfHeight);

  useEffect(() => {
    setHeight(runHalfHeight - 35);
  }, [ runHalfHeight ]);

  const powers = concentricPhases.map(p => p.physics.power);
  const labels: string[] = powers.map((p, i) => `${ i + 1 }) ${ p }w`);

  const powerColumns = getPowerColumns(powers);
  const powerLabels = getPowerLabels(labels);

  const options: ChartOptions<"bar"> = {
    animation: { duration: 0 },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        min: 0,
        max: maxY,
        ticks: {
          stepSize: 100,
          callback: formatToPower,
        },
      },
    },
    plugins: {
      tooltip: { enabled: false },
      legend: { display: false },
      annotation: {
        annotations: {
          // maxPower: {
          //   display: maxPower && maxPower > 0,
          //   type: "line",
          //   yMin: maxPower,
          //   yMax: maxPower,
          //   borderColor: theme.colors.yellow,
          //   borderWidth: 1,
          // },
          powerRange: {
            display:  (
              ((minPower && maxPower) && minPower > 0 && maxPower > minPower) ||
              ((minPower && props.powerReference) && minPower > 0 && props.powerReference > minPower)
            ),
            type: "box",
            yMin: minPower > 0 ? minPower : maxPower,
            yMax: maxPower > 0 ? maxPower : props.powerReference,
            borderWidth: 0,
            backgroundColor: theme.colors.neonTransparent,
          },
          powerReferenceMax: {
            display: props.powerReference > 0,
            type: "line",
            yMin: props.powerReference,
            yMax: props.powerReference,
            borderColor: theme.colors.orange,
            borderWidth: 2,
          },
          // minPowerLabel: {
          //   type: "label",
          //   xValue: 0,
          //   yValue: 522,
          //   backgroundColor: theme.colors.pureWhite,
          //   color: theme.colors.black,
          //   content: "MIN",
          //   padding: 4,
          //   borderRadius: 4,
          //   font: {
          //     ...theme.typography.RM10,
          //     size: 10,
          //   },
          // },
          // maxPowerLabel: {
          //   type: "label",
          //   xValue: 0,
          //   yValue: 680,
          //   backgroundColor: theme.colors.pureWhite,
          //   color: theme.colors.red,
          //   content: "MAX",
          //   padding: 4,
          //   borderRadius: 4,
          //   font: {
          //     ...theme.typography.RM10,
          //     size: 10,
          //   },
          // },
          ...getVerticalDottedLines(concentricPhases, theme, maxY),
        },
      },
    },
  };

  const data: ChartData<"bar"> = {
    labels: powerLabels,
    datasets: [
      {
        label: "Powers",
        borderWidth: 2,
        data: powerColumns,
        backgroundColor: backgroundColors(),
        borderRadius: 7,
        borderSkipped: false,
      },
    ],
  };

  return <>
    <h4 style={{
      textAlign: "center",
      marginBottom: 0,
    }}>
      <Trans>Power</Trans>
    </h4>
    <div style={{ height: `${ height }px` }}>
      <Bar
        redraw={false}
        data={data}
        options={options} />
    </div>
  </>;
};

const getVerticalDottedLines = (
  concentricPhases: Phase[],
  theme: any,
  maxY: number
): any[] => {
  const verticalDottedLines = concentricPhases.map((phase, i) => {
    return {
      drawTime: "beforeDatasetsDraw",
      type: "line",
      xMin: i,
      xMax: i,
      yMin: 0,
      yMax: roundUpToHigherHundred(maxY),
      borderColor: theme.colors.grey,
      borderDash: [
        2,
        5,
      ],
      borderWidth: 2,
    };
  });
  if (verticalDottedLines.length < 3) {
    const initialLenght = verticalDottedLines.length - 1;
    for (let i = 2; i > initialLenght; i--) {
      verticalDottedLines.push({
        drawTime: "beforeDatasetsDraw",
        type: "line",
        xMin: i,
        xMax: i,
        yMin: 0,
        yMax: roundUpToHigherHundred(maxY),
        borderColor: theme.colors.grey,
        borderDash: [
          2,
          5,
        ],
        borderWidth: 2,
      });
    }
  }
  return verticalDottedLines;
};

const getPowerColumns = (powers: number[]) => {
  if (powers.length >= 3) {
    return powers;
  }
  const result = [
    0,
    0,
    0,
  ];
  return result.map((n, i) => {
    return powers[i] ?? n;
  });
};

const getPowerLabels = (labels: string[]) => {
  if (labels.length >= 3) {
    return labels;
  }
  const result = [
    "1",
    "2",
    "3",
  ];
  return result.map((n, i) => {
    return labels[i] ?? n;
  });
};
