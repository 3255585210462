import {
  Account,
  Athlete,
  Profile,
} from "@common/type-graphql/generated";
import { AccountListLinkedToAthleteComponent } from "@feature/athlete/component/accountListLinkedToAthleteComponent";
import { ApiData } from "@feature/api/component/ApiData";
import { AthleteAccountDisconnectComponent } from "@feature/athlete/component/athleteAccountDisconnectComponent";
import { ListItemUi } from "@ui/ListItemUi/ListItemUi";
import { PrimaryButtonUi } from "@ui/PrimaryButtonUi/PrimaryButtonUi";
import { PrimaryDestructiveButtonUi } from "@ui/PrimaryDestructiveButtonUi/PrimaryDestructiveButtonUi";
import { SectionUi } from "@ui/SectionUi/SectionUi";
import { confirmDelete } from "@feature/confirm/service/delete";
import { deletePayloadInput } from "@util/deletePayloadInput";
import {
  getRouteUuidPlaceholder,
  routes,
} from "@core/route";
import { getUniqueId } from "@util/getUniqueId";
import {
  router,
  useAppDispatch,
} from "@core/redux/store";
import { t } from "@lingui/macro";
import { toast } from "@feature/toast/slice/toastSlice";
import { typography } from "@theme/typography";
import { useAccountGetQuery } from "@feature/account/api/accountApi";
import {
  useAthleteEditMutation,
  useAthleteGetQuery,
} from "@feature/athlete/api/athleteApi";
import { useIonActionSheet } from "@ionic/react";
import { useProfileListQuery } from "@feature/profile/api/profileApi";

type Props = {
  uuid?: string;
}

export const AthleteViewComponent = (props: Props) => {
  const accountApi = useAccountGetQuery();
  const athleteApi = useAthleteGetQuery({ where: { uuid: props.uuid } });
  const profileListApi = useProfileListQuery({ where:{ uuid: props.uuid } });

  return <>
    <ApiData endpoint={[
      accountApi,
      athleteApi,
      profileListApi,
    ]}>
      <AthleteViewComponentChild
        {...props}
        account={accountApi.data}
        athlete={athleteApi.data}
        profileList={profileListApi.data}
      />
    </ApiData>
  </>;
};

type PropsChild = {
  uuid?: string;
  account: Account;
  athlete: Athlete;
  profileList: Profile[];
}
const AthleteViewComponentChild = (props: PropsChild) => {
  const dispatch = useAppDispatch();

  const [ edit ] = useAthleteEditMutation();
  const [ present ] = useIonActionSheet();

  const onDelete = async() => {
    confirmDelete({
      header: t`Delete athlete: ${ props.athlete.name }`,
      subHeader: t`By deleting this athlete you'll delete also the profiles data related to workouts done with this athlete's profile`,
      present: present,
      action: async() => {
        const deleteResult = await edit({
          data: deletePayloadInput(),
          where: { uuid: props.uuid },
        });

        if ("error" in deleteResult) {
          return;
        }

        dispatch(toast({ message: t`Profile deleted successfully.` }));
        router.replace(routes.athleteList.redirectPath);
      },
    });
  };

  return <>
    <SectionUi rounded>
      <ListItemUi
        title={t`Name`}
        description={props.athlete.name}
        titleTypography={typography.RB24}
        textElement={true}
      />
      {
        props.athlete.email &&
        <ListItemUi
          title={t`Email`}
          description={props.athlete.email}
          hasSeparator={false}
          textElement={true}
        />
      }
      {
        props.athlete.bodyHeight &&
        <ListItemUi
          title={t`Height`}
          description={`${ props.athlete.bodyHeight.toString() } cm`}
          hasSeparator={false}
          textElement={true}
        />
      }
      {
        props.athlete.bodyWeight &&
        <ListItemUi
          title={t`Weight`}
          description={`${ props.athlete.bodyWeight.toString() } kg`}
          hasSeparator={false}
          textElement={true}
        />
      }

      <ListItemUi>
        <PrimaryButtonUi
          onClick={() => router.replace(routes.athleteEdit.routePath.replace(getRouteUuidPlaceholder(), props.uuid))}
          label={t`Edit athlete`}
        />
      </ListItemUi>
    </SectionUi>

    <SectionUi rounded>
      <ListItemUi
        title={t`Profiles`}
        description={<>
          {
            props.profileList.map(profile =>
              <p key={getUniqueId()}>
                - {profile.name}
              </p>
            )
          }
        </>}
        hasSeparator={false}
      />
      <ListItemUi>
        <PrimaryButtonUi
          label={t`Go to profile list`}
          onClick={() => router.replace(routes.profileList.routePath.replace(getRouteUuidPlaceholder("athlete"), props.uuid))}
        />
      </ListItemUi>
    </SectionUi>

    <AccountListLinkedToAthleteComponent
      athleteUuid={props.uuid}
    />

    <AthleteAccountDisconnectComponent
      athleteUuid={props.uuid}
    />

    {
      props.athlete.ownerUuid === props.account.uuid &&
	    <SectionUi rounded>
		    <ListItemUi
			    title={t`Delete athlete`}
			    description={t`By deleting this athlete, the training data recorded with his profile will also be automatically deleted.`}
	      >
			    <PrimaryDestructiveButtonUi
				    label={t`Delete athlete`}
				    onClick={onDelete}
			    />
		    </ListItemUi>
	    </SectionUi>
    }

  </>;
};
