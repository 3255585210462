/* Core */
import {
  IonApp,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

/* Redux */
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import {
  persistor,
  router,
  store,
} from "../redux/store";

/* GSAP */
import gsap, { CustomEase } from "gsap/all";
import {
  GSAP_CUSTOM_EASE_INOUTQUINT,
  GSAP_CUSTOM_EASE_OUTCIRC,
  GSAP_CUSTOM_EASE_OUTQUINT,
} from "@feature/gsap/gsapConstants";

/* Style */
import SVGSprite from "@ui/SVGSpriteUi/SVGSpriteUi";
import { GlobalStyles } from "tss-react";
import { globals } from "@theme/globals";
import "@ionic/react/css/core.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@theme/typography.css";
import "@theme/variables.css";

/* Chartjs */
import annotationPlugin from "chartjs-plugin-annotation";
import zoomPlugin from "chartjs-plugin-zoom";
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart,
  Filler,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  ScatterController,
  TimeScale,
  Title,
  Tooltip,
} from "chart.js";

/* Components */
import AppUrlListener from "@core/app/AppUrlListener";
import { AccountCheckOutlet } from "@feature/account/component/accountCheckOutlet";
import { DeviceAutoConnectOutlet } from "@feature/device/component/deviceAutoConnectOutlet";
import { LanguageProvider } from "@feature/setting/component/LanguageProvider";
import { LoaderUi } from "@ui/LoaderUi/LoaderUi";
import { LoadingOutlet } from "@feature/loading/component/loadingOutlet";
import { OfflineProvider } from "@feature/offline/component/offlineProvider";
import { RouterOutlet } from "@feature/router/component/routerOutlet";
import { TabsOutlet } from "@feature/tab/component/tabsOutlet";
import { ToastOutlet } from "@feature/toast/component/toastOutlet";

/* Configure Ionic */
setupIonicReact({ mode: "ios" });

/* Configure GSAP */
gsap.registerPlugin(CustomEase);
CustomEase.create(GSAP_CUSTOM_EASE_OUTCIRC, "0.6, 0.55, 0.45, 1"); // Interactive 0.4s
CustomEase.create(GSAP_CUSTOM_EASE_OUTQUINT, "0.25, 1, 0.35, 1"); // Texts 0.8s
CustomEase.create(GSAP_CUSTOM_EASE_INOUTQUINT, "0.7, 0, 0.2, 1"); // Layout 0.8s

/* Configure Chartjs */
Chart.register(
  annotationPlugin,
  TimeScale,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  LineController,
  ScatterController,
  BarController,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  zoomPlugin
);

export const App = () => {
  return <>
    <GlobalStyles styles={{ ...globals }} />
    <SVGSprite />
    <Provider store={store}>
      <PersistGate
        loading={<LoaderUi appearance={"fullscreen"} background={"neon"} />}
        persistor={persistor}
      >
        {/*<GoogleOutlet>*/}
        <IonApp>
          <LanguageProvider>
            <IonReactRouter history={router}>
              <RouterOutlet />
              <AccountCheckOutlet />
              <AppUrlListener />
              <TabsOutlet />
              <OfflineProvider />
              <DeviceAutoConnectOutlet />
              <LoadingOutlet />
              <ToastOutlet />
            </IonReactRouter>
          </LanguageProvider>
        </IonApp>
        {/*</GoogleOutlet>*/}
      </PersistGate>
    </Provider>
  </>;
};
