import {
  IonLabel,
  IonSegment,
  IonSegmentButton,
  SegmentChangeEventDetail,
} from "@ionic/react";
import { IonSegmentCustomEvent } from "@ionic/core/dist/types/components";
import { Locale } from "@lingui/core";
import { selectSettingState } from "@feature/setting/slice/settingSlice";
import { useAppSelector } from "@core/redux/store";
import { useLanguage } from "@feature/setting/component/LanguageProvider";

export const LanguageSwitchComponent = () => {
  const language = useLanguage();

  const settingState = useAppSelector(selectSettingState);

  const switchLanguage = (event: IonSegmentCustomEvent<SegmentChangeEventDetail>) => {
    const detail = event.detail;
    const value = detail.value.toString() as Locale;
    language.setLanguage(value);
  };

  return <>
    <IonSegment
      value={settingState.language}
      onIonChange={switchLanguage}
    >
      <IonSegmentButton value="it">
        <IonLabel>Italiano</IonLabel>
      </IonSegmentButton>
      <IonSegmentButton value="en">
        <IonLabel>English</IonLabel>
      </IonSegmentButton>
    </IonSegment>
  </>;
};
