import {
  RUN_STATUS_READY,
  RUN_STATUS_RUNNING,
  selectRunState,
} from "@feature/run/slice/runSlice";

import { runCalibrationStop } from "@feature/run/slice/runCalibrationSlice";
import { runStopThunk } from "@feature/run/thunk/runThunk";
import { selectDeviceState } from "@feature/device/slice/deviceSlice";
import { t } from "@lingui/macro";
import { toastUntilOk } from "@feature/toast/slice/toastSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "@core/redux/store";
import {
  useEffect,
  useRef,
} from "react";

export const useRunStop = () => {
  const dispatch = useAppDispatch();

  const runState = useAppSelector(selectRunState);
  const deviceState = useAppSelector(selectDeviceState);

  const prevRunStatusRef = useRef(runState.runStatus);

  useEffect(() => {
    const prevRunStatus = prevRunStatusRef.current;

    if (
      prevRunStatus === RUN_STATUS_RUNNING &&
      runState.runStatus === RUN_STATUS_READY &&
      deviceState.connectedDevice === null
    ) {
      stop(0);

      dispatch(toastUntilOk({
        message: t`Device disconnected, please verify it is turned on and connected.`,
        color: "danger",
        position: "middle",
      }));
    }

    prevRunStatusRef.current = runState.runStatus;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    runState.runStatus,
    deviceState.connectedDevice,
  ]);

  const stop = (recoverAmount: number) => {
    dispatch(runStopThunk({
      recoverAmount: recoverAmount,
    }));
    dispatch(runCalibrationStop());
  };

  return { runStop: stop };
};
