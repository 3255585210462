import React from "react";
import {
  API_CACHE_TAG_HISTORY_SERIE,
  API_CACHE_TAG_HISTORY_WORKOUT,
} from "@feature/history/historyConstants";
import {
  API_CACHE_TAG_LIST_VALUE,
  api,
} from "@core/api";
import { ApiDataList } from "@feature/api/component/ApiDataList";
import {
  HistoryTrainingSession,
} from "@common/type-graphql/generated";
import { HistoryTrainingSessionItemComponent } from "@feature/history/component/historyTrainingSessionItemComponent";
import { PageCountOutput } from "@common/type-graphql/pagination/output-type/page-count-output";
import { PaginationComponent } from "@feature/pagination/component/paginationComponent";
import { getUniqueId } from "@util/getUniqueId";

import { SectionUi } from "@ui/SectionUi/SectionUi";
import {
  getRoutePagePlaceholder,
  routes,
} from "@core/route";
import {
  router,
  useAppDispatch,
} from "@core/redux/store";
import { setHistoryTrainingSessionListPage } from "@feature/history/slice/historySlice";
import {
  useHistoryTrainingSessionListQuery,
  useHistoryTrainingSessionPageCountQuery,
} from "@feature/history/api/historyApi";

type Props = {
  urlPage?: string;
}

export const HistoryTrainingSessionListComponent = (props: Props) => {
  const dispatch = useAppDispatch();

  const urlPage = props.urlPage || "1";
  const historyTrainingSessionListApi = useHistoryTrainingSessionListQuery({ page: urlPage });
  const historyTrainingSessionPageCountApi = useHistoryTrainingSessionPageCountQuery();

  const afterRefresh = () => {
    dispatch(api.util.invalidateTags([
      {
        type: API_CACHE_TAG_HISTORY_WORKOUT,
        id: API_CACHE_TAG_LIST_VALUE,
      },
      {
        type: API_CACHE_TAG_HISTORY_SERIE,
        id: API_CACHE_TAG_LIST_VALUE,
      },
    ]));
  };

  return <>
    <ApiDataList
      endpoint={historyTrainingSessionListApi}
      useRefresher={true}
      afterRefreshCallback={afterRefresh}
      showEmptyListMessageUi={true}
    >
      <HistoryTrainingSessionListComponentChild
        urlPage={urlPage}
        historyTrainingSessions={historyTrainingSessionListApi.data}
        historyTrainingSessionPageCount={historyTrainingSessionPageCountApi.data}
      />
    </ApiDataList>
  </>;
};

type PropsChild = {
  historyTrainingSessions: HistoryTrainingSession[];
  historyTrainingSessionPageCount: PageCountOutput;
} & Props;

const HistoryTrainingSessionListComponentChild = (props: PropsChild) => {
  const dispatch = useAppDispatch();

  return <>
    <SectionUi rounded sectionListing={true}>
      {
        props.historyTrainingSessions.map(historyTrainingSession =>
          <HistoryTrainingSessionItemComponent
            uuid={historyTrainingSession.uuid}
            key={getUniqueId()}
          />
        )
      }
      {
        props.historyTrainingSessionPageCount &&
        props.historyTrainingSessionPageCount.pageCount > 1 &&
        <PaginationComponent
          currentPage={props.urlPage}
          pageCount={props.historyTrainingSessionPageCount.pageCount}
          onChange={(page: number) => {
            dispatch(setHistoryTrainingSessionListPage(page.toString()));
            router.replace(routes.historyList.routePath.replace(getRoutePagePlaceholder(), page.toString()));
          }}
        />
      }
    </SectionUi>
  </>;
};
