
import {
  DEVICE_RANGE_MAX,
  DEVICE_RANGE_MIN,
  RANGE_EXTENDED_READ_MM_DEFAULT,
} from "@common/service/constants";
import {
  DEVICE_RANGE_MIN_MAX_DEFAULT,
  RangeMinMax,
} from "@common/model/Range";
import { confirm } from "@feature/confirm/service/confirm";
import { deviceBleSetRangeThunk } from "@feature/device/thunk/deviceThunk";
import {
  getConfigurationValueFormattedRangeExtendedReadMaxMm,
  getConfigurationValueFormattedRangeExtendedReadMinMm,
  getRangeMinMaxExtended,
} from "@common/service/configurationService";
import { t } from "@lingui/macro";
import { useAppDispatch } from "@core/redux/store";
import { useConfiguration } from "@feature/configuration/hook/useConfiguration";
import { useIonActionSheet } from "@ionic/react";
import { useRangeSetMutation } from "@feature/range/api/rangeApi";

type SetRangeProps = {
  athleteUuid: string;
  protocolUuid: string;
  exerciseUuid: string;
  rangeMinMax: RangeMinMax | null;
}

export const useRange = () => {
  const dispatch = useAppDispatch();

  const [ present ] = useIonActionSheet();
  const [ create ] = useRangeSetMutation();

  const {
    endpoints: configurationEndpoints,
    getRelationConfigurationValuesByProtocolAndExercise,
  } = useConfiguration();

  const setRange = (props: SetRangeProps) => {
    const min = props.rangeMinMax?.min;
    const max = props.rangeMinMax?.max;

    if (
      min < DEVICE_RANGE_MIN ||
      min > DEVICE_RANGE_MAX ||
      max < DEVICE_RANGE_MIN ||
      max > DEVICE_RANGE_MAX ||
      max < min
    ) {
      console.error("Invalid range", min, max);
      return;
    }

    if (min && max) {
      const relationConfigurationValues = getRelationConfigurationValuesByProtocolAndExercise(props.protocolUuid, props.exerciseUuid);
      const rangeExtendedReadMinMm = getConfigurationValueFormattedRangeExtendedReadMinMm(relationConfigurationValues) ?? RANGE_EXTENDED_READ_MM_DEFAULT;
      const rangeExtendedReadMaxMm = getConfigurationValueFormattedRangeExtendedReadMaxMm(relationConfigurationValues) ?? RANGE_EXTENDED_READ_MM_DEFAULT;
      const range: RangeMinMax = {
        min: min,
        max: max,
      };
      const rangeExtended = getRangeMinMaxExtended({
        range: range,
        minExtensionMm: rangeExtendedReadMinMm,
        maxExtensionMm: rangeExtendedReadMaxMm,
      });
      dispatch(deviceBleSetRangeThunk(rangeExtended));
    } else {
      dispatch(deviceBleSetRangeThunk(DEVICE_RANGE_MIN_MAX_DEFAULT));
    }

    create({
      data: {
        data: JSON.stringify(props.rangeMinMax),
        Athlete: { connect: { uuid: props.athleteUuid } },
        Exercise: { connect: { uuid: props.exerciseUuid } },
      },
    });
  };

  const setRangeConfirm = (props: SetRangeProps) => {
    return () => {
      if (
        !props.athleteUuid ||
        !props.exerciseUuid
      ) {
        return;
      }

      confirm({
        header: t`Do you want to reset the range?`,
        subHeader: t`This action will reset the range to the default value.`,
        present: present,
        action: () => setRange(props),
      });
    };
  };

  return {
    setRange: setRange,
    setRangeConfirm: setRangeConfirm,
  };
};
