
export interface Route {
  code: string;
  routePath: string;
  basePath: string;
  redirectPath: string;
  title: string;
  isTabsVisible?: boolean;
  isPublic?: boolean;
  icon?: string;
  activeTab?: string;
}

type RouteList = {
  root: Route;
  maintenance: Route;
  maintenanceVersion: Route;
  development: Route;
  welcome: Route;
  dashboard: Route;
  settings: Route;
  accountLogin: Route;
  accountLogout: Route;
  accountLoginOrBuy: Route;
  accountSignup: Route;
  accountForgotPassword: Route;
  accountChangePassword: Route;
  accountView: Route;
  sheetList: Route;
  sheetView: Route;
  sheetEdit: Route;
  sheetCreate: Route;
  athleteList: Route;
  athleteView: Route;
  athleteEdit: Route;
  athleteCreate: Route;
  athleteConnect: Route;
  profileList: Route;
  profileEdit: Route;
  profileCreate: Route;
  quickEdit: Route;
  trainingEdit: Route;
  trainingCreate: Route;
  historyList: Route;
  historyView: Route;
  historyViewChart: Route;
  run: Route;
  resume: Route;
  deviceList: Route;
  infoRange: Route;
  infoPowerReference: Route;
};

/**
 * Returns the placeholder for a UUID.
 * For example :athleteUuid or :uuid?
 * @param type
 * @param isOptional
 */
export const getRouteUuidPlaceholder = (type?: string, isOptional?: boolean) => {
  let placeholder = ":uuid";

  if (type) {
    placeholder = `:${ type }Uuid`;
  }

  if (Boolean(isOptional)) {
    placeholder = `${ placeholder }?`;
  }

  return placeholder;
};

/**
 * Returns the placeholder for a page.
 */
export const getRoutePagePlaceholder = () => {
  return ":page?";
};

export const routes: RouteList = {
  root: {
    code: "ROOT",
    routePath: "/",
    basePath: "/",
    redirectPath: "/",
    title: "Root",
    isPublic: true,
  },
  maintenance: {
    code: "maintenance",
    routePath: "/maintenance",
    basePath: "/maintenance",
    redirectPath: "/maintenance",
    title: "Maintenance",
    isPublic: true,
  },
  maintenanceVersion: {
    code: "maintenanceVersion",
    routePath: "/maintenance/version",
    basePath: "/maintenance/version",
    redirectPath: "/maintenance/version",
    title: "Maintenance Version",
    isPublic: true,
  },
  development: {
    code: "development",
    routePath: "/development",
    basePath: "/development",
    redirectPath: "/development",
    title: "Development",
    isTabsVisible: true,
    icon: "workout",
    activeTab: "development",
  },
  welcome: {
    code: "welcome",
    routePath: "/welcome",
    basePath: "/welcome",
    redirectPath: "/welcome",
    title: "Welcome",
    isPublic: true,
  },
  dashboard: {
    code: "dashboard",
    routePath: "/dashboard",
    basePath: "/dashboard",
    redirectPath: "/dashboard",
    title: "Home",
    isTabsVisible: true,
    icon: "home",
    activeTab: "dashboard",
  },
  settings: {
    code: "settings",
    routePath: "/settings",
    basePath: "/settings",
    redirectPath: "/settings",
    title: "Settings",
    isTabsVisible: true,
    icon: "settings",
    activeTab: "settings",
  },
  accountLogin: {
    code: "accountLogin",
    routePath: "/account/login/:autoLoginWith?",
    basePath: "/account/login",
    redirectPath: "/account/login",
    title: "Login",
    isPublic: true,
  },
  accountLogout: {
    code: "accountLogout",
    routePath: "/account/logout",
    basePath: "/account/logout",
    redirectPath: "/account/logout",
    title: "Logout",
    isPublic: true,
  },
  accountLoginOrBuy: {
    code: "accountLoginOrBuy",
    routePath: "/account/login-or-buy",
    basePath: "/account/login-or-buy",
    redirectPath: "/account/login-or-buy",
    title: "Login or signin",
    isPublic: true,
  },
  accountSignup: {
    code: "accountSignup",
    routePath: "/account/signup/:exhibition?",
    basePath: "/account/signup",
    redirectPath: "/account/signup",
    title: "Signup",
    isPublic: true,
  },
  accountForgotPassword: {
    code: "accountForgotPassword",
    routePath: "/account/forgot-password",
    basePath: "/account/forgot-password",
    redirectPath: "/account/forgot-password",
    title: "Recover password",
    isPublic: true,
  },
  accountChangePassword: {
    code: "accountChangePassword",
    routePath: "/account/change-password",
    basePath: "/account/change-password",
    redirectPath: "/account/change-password",
    title: "Change password",
    isPublic: true,
  },
  accountView: {
    code: "accountView",
    routePath: "/account/view",
    basePath: "/account/view",
    redirectPath: "/account/view",
    title: "My Account",
  },
  sheetList: {
    code: "sheetList",
    routePath: "/sheet/list",
    basePath: "/sheet/list",
    redirectPath: "/sheet/list",
    title: "Sheets",
    isTabsVisible: true,
    icon: "add-round",
    activeTab: "dashboard",
  },
  sheetView: {
    code: "sheetView",
    routePath: `/sheet/view/${ getRouteUuidPlaceholder() }`,
    basePath: "/sheet/view",
    redirectPath: "/sheet/list",
    isTabsVisible: true,
    title: "Sheet",
    activeTab: "dashboard",
  },
  sheetEdit: {
    code: "sheetEdit",
    routePath: `/sheet/edit/${ getRouteUuidPlaceholder() }`,
    basePath: "/sheet/edit",
    redirectPath: "/sheet/list",
    title: "Sheet",
    isTabsVisible: true,
    activeTab: "dashboard",
  },
  sheetCreate: {
    code: "sheetCreate",
    routePath: "/sheet/create",
    basePath: "/sheet/create",
    redirectPath: "/sheet/create",
    title: "Create sheet",
    isTabsVisible: true,
    activeTab: "dashboard",
  },
  athleteList: {
    code: "athleteList",
    routePath: "/athlete/list",
    basePath: "/athlete/list",
    redirectPath: "/athlete/list",
    title: "Athletes",
    isTabsVisible: true,
    activeTab: "dashboard",
  },
  athleteView: {
    code: "athleteView",
    routePath: `/athlete/view/${ getRouteUuidPlaceholder() }`,
    basePath: "/athlete/view",
    redirectPath: "/athlete/view",
    title: "Athlete",
    isTabsVisible: true,
    activeTab: "dashboard",
  },
  athleteEdit: {
    code: "athleteEdit",
    routePath: `/athlete/edit/${ getRouteUuidPlaceholder() }`,
    basePath: "/athlete/edit",
    redirectPath: "/athlete/list",
    title: "Edit athlete",
    isTabsVisible: true,
    activeTab: "dashboard",
  },
  athleteCreate: {
    code: "athleteCreate",
    routePath: "/athlete/create",
    basePath: "/athlete/create",
    redirectPath: "/athlete/create",
    title: "Create athlete",
    isTabsVisible: true,
    activeTab: "dashboard",
  },
  athleteConnect: {
    code: "athleteConnect",
    routePath: `/athlete/connect/${ getRouteUuidPlaceholder("athlete") }`,
    basePath: "/athlete/connect",
    redirectPath: "/athlete/list",
    title: "Connect athlete",
  },
  profileList: {
    code: "profileList",
    routePath: `/profile/list/${ getRouteUuidPlaceholder("athlete") }`,
    basePath: "/profile/list",
    redirectPath: "/profile/list",
    title: "Profiles",
    isTabsVisible: true,
    activeTab: "dashboard",
  },
  profileEdit: {
    code: "profileEdit",
    routePath: `/profile/edit/${ getRouteUuidPlaceholder("athlete") }/${ getRouteUuidPlaceholder() }`,
    basePath: "/profile/edit",
    redirectPath: "/athlete/list",
    title: "Edit profile",
    isTabsVisible: true,
    activeTab: "dashboard",
  },
  profileCreate: {
    code: "profileCreate",
    routePath: `/profile/create/${ getRouteUuidPlaceholder("athlete") }`,
    basePath: "/profile/create",
    redirectPath: "/athlete/list",
    title: "Create profile",
    isTabsVisible: true,
    activeTab: "dashboard",
  },
  quickEdit: {
    code: "quickEdit",
    routePath: "/quick/edit",
    basePath: "/quick/edit",
    redirectPath: "/quick/edit",
    title: "Quick workout",
    isTabsVisible: true,
    activeTab: "dashboard",
  },
  trainingEdit: {
    code: "trainingEdit",
    routePath: `/training/edit/${ getRouteUuidPlaceholder("sheet") }/${ getRouteUuidPlaceholder() }`,
    basePath: "/training/edit",
    redirectPath: "/training/list",
    title: "Edit training",
    isTabsVisible: true,
    activeTab: "dashboard",
  },
  trainingCreate: {
    code: "trainingCreate",
    routePath: `/training/create/${ getRouteUuidPlaceholder("sheet") }`,
    basePath: "/training/create",
    redirectPath: "/training/list",
    title: "Create training",
    isTabsVisible: true,
    activeTab: "dashboard",
  },
  historyList: {
    code: "historyList",
    routePath: `/history/list/${ getRoutePagePlaceholder() }`,
    basePath: "/history/list",
    redirectPath: "/history/list",
    title: "History",
    icon: "history",
    isTabsVisible: true,
    activeTab: "historyList",
  },
  historyView: {
    code: "historyView",
    routePath: `/history/view/${ getRouteUuidPlaceholder("historySerie") }`,
    basePath: "/history/view",
    redirectPath: "/history/list",
    title: "History serie",
    isTabsVisible: true,
    activeTab: "historyList",
  },
  historyViewChart: {
    code: "historyViewChart",
    routePath: `/history/view/chart/${ getRouteUuidPlaceholder("historySerie") }/${ getRouteUuidPlaceholder("chartCode") }`,
    basePath: "/history/view/chart",
    redirectPath: "/history/list",
    title: "Chart detail",
    isTabsVisible: true,
    activeTab: "historyList",
  },
  run: {
    code: "run",
    routePath: `/run/${ getRouteUuidPlaceholder("historySerie", true) }`,
    basePath: "/run",
    redirectPath: "/run",
    title: "Run",
    icon: "expand-right",
    activeTab: "run",
  },
  resume: {
    code: "resume",
    routePath: "/run",
    basePath: "/run",
    redirectPath: "/run",
    title: "Resume",
    icon: "expand-right",
    activeTab: "run",
  },
  deviceList: {
    code: "deviceList",
    routePath: "/device/list",
    basePath: "/device/list",
    redirectPath: "/device/list",
    title: "Devices",
    icon: "bodygon-devices",
    isTabsVisible: true,
    activeTab: "deviceList",
  },
  infoRange: {
    code: "infoRange",
    routePath: "/info/range",
    basePath: "/info/range",
    redirectPath: "/info/range",
    title: "The range",
    isTabsVisible: true,
  },
  infoPowerReference: {
    code: "infoPowerReference",
    routePath: "/info/power_reference",
    basePath: "/info/power_reference",
    redirectPath: "/info/power_reference",
    title: "The power reference",
    isTabsVisible: true,
  },
};
