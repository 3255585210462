import {
  Account,
  Athlete,
} from "@common/type-graphql/generated";
import { ApiData } from "@feature/api/component/ApiData";
import { ListItemUi } from "@ui/ListItemUi/ListItemUi";
import { PrimaryDestructiveButtonUi } from "@ui/PrimaryDestructiveButtonUi/PrimaryDestructiveButtonUi";
import { SectionUi } from "@ui/SectionUi/SectionUi";
import {
  Trans,
  t,
} from "@lingui/macro";
import { confirmDestructive } from "@feature/confirm/service/confirmDestructive";
import {
  router,
} from "@core/redux/store";
import { routes } from "@core/route";
import { typography } from "@theme/typography";
import { useAccountGetQuery } from "@feature/account/api/accountApi";
import {
  useAthleteDisconnectMutation,
  useAthleteGetQuery,
} from "@feature/athlete/api/athleteApi";
import { useIonActionSheet } from "@ionic/react";

type Props = {
  athleteUuid?: string;
}

export const AthleteAccountDisconnectComponent = (props: Props) => {
  const accountGetApi = useAccountGetQuery();
  const athleteApi = useAthleteGetQuery({ where: { uuid: props.athleteUuid } });

  return <>
    <ApiData
      endpoint={[
        accountGetApi,
        athleteApi,
      ]}
    >
      <ChildComponent
        account={accountGetApi.data}
        athlete={athleteApi.data}
        {...props}
      />
    </ApiData>
  </>;
};

type PropsChild = Props & {
  account: Account;
  athlete: Athlete;
}
const ChildComponent = (props: PropsChild) => {
  const [ disconnect ] = useAthleteDisconnectMutation();
  const [ present ] = useIonActionSheet();

  if (props.athlete.ownerUuid === props.account.uuid) {
    return null;
  }

  const unlink = async() => {
    confirmDestructive({
      header: t`Do you want to remove this Athlete?`,
      subHeader: t`This will remove the Athlete from your Account`,
      present: present,
      action: async() => {
        router.replace(routes.athleteList.redirectPath);
        await disconnect({
          data: { athleteUuid: props.athleteUuid },
        });
      },
    });
  };

  return <>
    <SectionUi rounded>
      <ListItemUi
        title={t`Athlete shared`}
        titleTypography={typography.RB24}
      >
        <p>
          <Trans>This Athelte belongs to another Account an is shared with You</Trans>
        </p>
        <PrimaryDestructiveButtonUi
          onClick={unlink}
          label={t`Remove this Athlete`}
        />
      </ListItemUi>
    </SectionUi>
  </>;
};
