import React, { useState } from "react";
import { ApiData } from "@feature/api/component/ApiData";
import {
  Athlete,
  Sheet,
  Training,
} from "@common/type-graphql/generated";
import { Color } from "@types-custom";
import { ListActionBarUi } from "@ui/ListActionBarUi/ListActionBarUi";
import { ListItemUi } from "@ui/ListItemUi/ListItemUi";
import { SectionUi } from "@ui/SectionUi/SectionUi";
import { clearSheetFormPreferences } from "@feature/sheet/service/sheetService";
import {
  getRouteUuidPlaceholder,
  routes,
} from "@core/route";
import { getUniqueId } from "@util/getUniqueId";
import {
  router,
} from "@core/redux/store";
import { t } from "@lingui/macro";
import { useAthleteGetQuery } from "@feature/athlete/api/athleteApi";
import { useSheetGetQuery } from "@feature/sheet/api/sheetApi";
import {
  useTrainingGetQuery,
  useTrainingListQuery,
} from "@feature/training/api/trainingApi";

type Props = {
  sheetList?: Sheet[];
}

export const SheetListComponent = (props: Props) => {
  const [
    filteredData,
    setFilteredData,
  ] = useState<Sheet[]>(props.sheetList.filter(element => !element.isQuick));

  const searchChange = (value: string) => {
    setFilteredData(props.sheetList.filter(element => !element.isQuick && element.name.toLowerCase().includes(value.toLowerCase())));
  };

  const goToSheetCreate = async() => {
    await clearSheetFormPreferences();
    router.replace(routes.sheetCreate.redirectPath);
  };

  if (!props.sheetList || !props.sheetList.length) {
    return <></>;
  }

  return <>
    <ListActionBarUi
      addButtonLabel={t`Sheet`}
      onAddButtonClick={goToSheetCreate}
      onSearchChange={searchChange}
    />
    <SectionUi rounded sectionListing={true}>
      {
        filteredData.map(item =>
          <SheetItemComponentChild
            sheetUuid={item.uuid}
            key={getUniqueId()}
          />
        )
      }
    </SectionUi>
  </>;
};

type PropsChild = {
  sheetUuid: string;
  key: string;
}

const SheetItemComponentChild = (props: PropsChild) => {
  const sheetGetApi = useSheetGetQuery({ where: { uuid: props.sheetUuid } });

  return <>
    <ApiData endpoint={sheetGetApi}>
      <SheetAthletesNameComponent
        sheet={sheetGetApi.data}
      />
    </ApiData>
  </>;
};

type PropsChildChildChild = {
  sheet: Sheet;
}

const SheetAthletesNameComponent = (props: PropsChildChildChild) => {
  const trainingListApi = useTrainingListQuery({ where: { uuid: props.sheet.uuid } });

  return <>
    <ApiData endpoint={trainingListApi}>
      <ListItemUi
        key={props.sheet.uuid}
        title={props.sheet.name}
        description={
          <SheetAthletesNameComponentChild
            trainings={trainingListApi.data}
          />
        }
        avatarColor={props.sheet.color as Color}
        href={routes.sheetEdit.routePath.replace(getRouteUuidPlaceholder(), props.sheet.uuid)}
      />

    </ApiData>
  </>;
};

type PropsSheetAthletesNameComponentChild = {
  trainings: Training[];
}

const SheetAthletesNameComponentChild = (props: PropsSheetAthletesNameComponentChild) => {
  return (
    <>
      {
        props.trainings.map((training, index) => (
          <span key={getUniqueId()}>
            <SheetAthleteNameComponent trainingUuid={training.uuid} key={getUniqueId()} />
            {index !== props.trainings.length - 1}
          </span>
        ))
      }
    </>
  );
};

type PropsSheetAthleteNameComponent = {
  trainingUuid: string;
}

const SheetAthleteNameComponent = (props: PropsSheetAthleteNameComponent) => {
  const trainingGetApi = useTrainingGetQuery({ where:{ uuid: props.trainingUuid } });

  return <>
    <ApiData endpoint={trainingGetApi}>
      <SheetAthleteNameComponentChild
        training={trainingGetApi.data}
      />
    </ApiData>
  </>;
};

type PropsSheetAthleteNameComponentChild = {
  training: Training;
}

const SheetAthleteNameComponentChild = (props: PropsSheetAthleteNameComponentChild) => {
  const athleteApi = useAthleteGetQuery({ where: { uuid: props.training.athleteUuid } });

  return <>
    <ApiData endpoint={athleteApi}>
      <AthleteNameComponent
        athlete={athleteApi.data}
      />
    </ApiData>
  </>;
};

type PropsAthleteNameComponent = {
  athlete: Athlete;
}

const AthleteNameComponent = (props: PropsAthleteNameComponent) => {
  return <>
    {props.athlete.name}
  </>;
};
