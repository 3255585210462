// To keep sync with Calibration table
export const CALIBRATION_CODE_MIN_MAX_AUTO = "MIN_MAX_AUTO";
export const CALIBRATION_CODE_MIN = "MIN";
export const CALIBRATION_CODE_MAX = "MAX";
export const CALIBRATION_CODE_ALT = "ALT";
export const CALIBRATION_CODE_SQUAT_MIN = "SQUAT_MIN";
export const CALIBRATION_CODE_SQUAT_MAX = "SQUAT_MAX";

export type CalibrationCode =
    | typeof CALIBRATION_CODE_MIN_MAX_AUTO
    | typeof CALIBRATION_CODE_MIN
    | typeof CALIBRATION_CODE_MAX
    | typeof CALIBRATION_CODE_ALT
    | typeof CALIBRATION_CODE_SQUAT_MIN
    | typeof CALIBRATION_CODE_SQUAT_MAX
