import {
  BLUETOOTH_DEVICE_STATUS_CONNECTED,
  BLUETOOTH_DEVICE_STATUS_DISCONNECTED,
  BLUETOOTH_STATUS_IDLE,
  BLUETOOTH_STATUS_SCANNING,
  DEVICE_FAKE_ID,
  STORE_DEVICE_KEY,
} from "@feature/device/deviceConstants";
import {
  BleClient,
} from "@capacitor-community/bluetooth-le";

import {
  PayloadAction,
  createSlice,
  isAnyOf,
} from "@reduxjs/toolkit";
import { RESET_APP_STATE } from "@core/api";
import {
  RootState,
} from "@core/redux/store";
import {
  deviceBleConnectByBrowserThunk,
  deviceBleScanStartThunk,
  deviceBleScanStopThunk,
  deviceBleSetBatteryLevelThunk,
  deviceBleSetNewNameThunk,
  deviceScanTimeoutId,
} from "@feature/device/thunk/deviceThunk";

export type BluetoothDeviceStatus = typeof BLUETOOTH_DEVICE_STATUS_CONNECTED | typeof BLUETOOTH_DEVICE_STATUS_DISCONNECTED;
export type BluetoothStatus = typeof BLUETOOTH_STATUS_IDLE | typeof BLUETOOTH_STATUS_SCANNING;

export type BluetoothDevice = {
  id: string;
  name: string;
  rssi: number;
  status: BluetoothDeviceStatus;
  batteryLevel?: number;
}

type InitialStateModelInterface = {
  bluetoothStatus: BluetoothStatus;
  isConnecting: boolean;
  connectedDevice: BluetoothDevice | null;
  availableDevices: BluetoothDevice[];
  lastReceived: number;
}

const initialState: InitialStateModelInterface = {
  bluetoothStatus: BLUETOOTH_STATUS_IDLE,
  isConnecting: false,
  connectedDevice: null,
  availableDevices: [],
  lastReceived: 0,
};

export const deviceSlice = createSlice({
  name: STORE_DEVICE_KEY,
  initialState: initialState,
  reducers: {
    resetAvailableDevices: state => {
      state.availableDevices = [];
    },
    addBleDevice: (state, action: PayloadAction<BluetoothDevice>) => {
      if (!state.availableDevices.find(d => d.id === action.payload.id)) {
        state.availableDevices.push(action.payload);
      }
    },
    setIsConnecting: (state, action: PayloadAction<boolean>) => {
      state.isConnecting = action.payload;
    },
    deviceConnect: (state, action: PayloadAction<BluetoothDevice>) => {
      const bleDevice = JSON.parse(JSON.stringify(action.payload));
      if (
        !state.connectedDevice ||
        state.connectedDevice.id !== bleDevice.id
      ) {
        bleDevice.status = BLUETOOTH_DEVICE_STATUS_CONNECTED;
        state.connectedDevice = bleDevice;
      }
      state.availableDevices = [];
    },
    deviceDisconnect: (state, action: PayloadAction<BluetoothDevice>) => {
      const device = action.payload;
      state.connectedDevice = null;

      if (device.id === DEVICE_FAKE_ID) {
        return;
      }
      try {
        BleClient.disconnect(device.id);
      } catch (error) {
        console.error("Error during disconnect BLE:", error);
      }
    },
    deviceDisconnectAll: state => {
      const connectedDevice = state.connectedDevice;
      const deviceId = connectedDevice.id;
      state.connectedDevice = null;
      if (deviceId === DEVICE_FAKE_ID) {
        return;
      }
      try {
        BleClient.disconnect(connectedDevice.id);
      } catch (error) {
        console.error("Error during disconnect BLE:", error);
      }
    },
    deviceReadsReceived: state => {
      state.lastReceived = Date.now();
    },
  },
  extraReducers: builder => {
    builder.addCase(RESET_APP_STATE, () => initialState);
    builder
      .addCase(deviceBleConnectByBrowserThunk.pending, state => {
        state.bluetoothStatus = BLUETOOTH_STATUS_SCANNING;
      })
      .addCase(deviceBleConnectByBrowserThunk.fulfilled, (state, action) => {
        const device = JSON.parse(JSON.stringify(action.payload)) as BluetoothDevice;
        if (!device) {
          state.bluetoothStatus = BLUETOOTH_STATUS_IDLE;
          return;
        }
        device.status = BLUETOOTH_DEVICE_STATUS_CONNECTED;
        state.bluetoothStatus = BLUETOOTH_STATUS_IDLE;
        state.connectedDevice = device;
      })
      .addCase(deviceBleConnectByBrowserThunk.rejected, (state, action) => {
        state.bluetoothStatus = BLUETOOTH_STATUS_IDLE;
      })
      .addMatcher(
        isAnyOf(
          deviceBleScanStopThunk.fulfilled,
          deviceBleScanStartThunk.rejected
        ), (state, action) => {
          clearTimeout(deviceScanTimeoutId); //@todo move in thunks
          state.bluetoothStatus = BLUETOOTH_STATUS_IDLE;
        }
      )
      .addMatcher(
        isAnyOf(
          deviceBleScanStartThunk.pending
        ), (state, action) => {
          state.bluetoothStatus = BLUETOOTH_STATUS_SCANNING;
        }
      )
      .addMatcher(
        isAnyOf(
          deviceBleSetNewNameThunk.fulfilled
        ), (state, action) => {
          state.connectedDevice = null;
        }
      )
      .addMatcher(
        isAnyOf(
          deviceBleSetBatteryLevelThunk.fulfilled
        ), (state, action) => {
          const connectedDevice = state.connectedDevice;
          if (connectedDevice) {
            connectedDevice.batteryLevel = action.payload;
          }
        }
      );
  },
});

export const {
  resetAvailableDevices,
  setIsConnecting,
  deviceConnect,
  deviceDisconnect,
  deviceDisconnectAll,
  deviceReadsReceived,
} = deviceSlice.actions;

export const selectDeviceState = (state: RootState) => state[STORE_DEVICE_KEY];
