import { router } from "@core/redux/store";

export const useLogic = (props: any) => {
  const handleClick = () => {
    if (props.onClick) {
      props.onClick();
    }
    if (props.href) {
      router.replace(props.href);
    }
  };

  return {
    handleClick: handleClick,
  };
};
