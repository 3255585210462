import React, {
  useEffect,
  useMemo,
  useState,
} from "react";
import useFormPersist from "react-hook-form-persist";
import { AddItemButtonUi } from "@ui/AddItemButtonUi/AddItemButtonUi";
import { AthleteChooseModal } from "@feature/athlete/component/modal/athleteChooseModal";
import { AthleteListElementComponent } from "@feature/athlete/component/athleteListElementComponent";
import { ConfigurationsForm } from "@feature/training/component/form/configurationsForm";
import { ExerciseChooseModal } from "@feature/exercise/component/modal/exerciseChooseModal";
import { ExerciseListElementComponent } from "@feature/exercise/component/exerciseListElementComponent";
import { FormErrorUi } from "@ui/FormErrorUi/FormErrorUi";
import { FormUi } from "@ui/FormUi/FormUi";
import { ListItemUi } from "@ui/ListItemUi/ListItemUi";
import { PrimaryAccentPositiveButtonUi } from "@ui/PrimaryAccentPositiveButtonUi/PrimaryAccentPositiveButtonUi";
import { PrimaryButtonUi } from "@ui/PrimaryButtonUi/PrimaryButtonUi";
import { PrimaryDestructiveButtonUi } from "@ui/PrimaryDestructiveButtonUi/PrimaryDestructiveButtonUi";
import { PrimaryPositiveButtonUi } from "@ui/PrimaryPositiveButtonUi/PrimaryPositiveButtonUi";
import { ProfileChooseModal } from "@feature/profile/component/modal/profileChooseModal";
import { ProfileListElementComponent } from "@feature/profile/component/profileListElementComponent";
import { ProtocolChooseModal } from "@feature/protocol/component/modal/protocolChooseModal";
import { ProtocolListElementComponent } from "@feature/protocol/component/protocolListElementComponent";
import { STORE_FORM_TRAINING } from "@feature/training/trainingConstants";
import { SectionUi } from "@ui/SectionUi/SectionUi";
import {
  TrainingConfigurationCreateManyTrainingInput,
  TrainingConfigurationUpdateManyWithWhereWithoutTrainingInput,
  TrainingCreateInput,
  TrainingUpdateInput,
} from "@common/type-graphql/generated";
import { confirmDelete } from "@feature/confirm/service/delete";
import { deletePayloadInput } from "@util/deletePayloadInput";
import {
  getRouteUuidPlaceholder,
  routes,
} from "@core/route";
import { resetCurrentSerie } from "@feature/run/data/currentSerie";
import {
  router,
  useAppDispatch,
} from "@core/redux/store";
import {
  runSetSheetUuid,
  runSetTrainingUuid,
} from "@feature/run/slice/runSlice";
import { t } from "@lingui/macro";
import { toast } from "@feature/toast/slice/toastSlice";
import { useAthleteListQuery } from "@feature/athlete/api/athleteApi";
import { useForm } from "react-hook-form";
import { useIonActionSheet } from "@ionic/react";
import { useProfileListQuery } from "@feature/profile/api/profileApi";
import { useRelationListQuery } from "@feature/relation/api/relationApi";
import { useSheetGetQuery } from "@feature/sheet/api/sheetApi";
import { useStyles } from "@feature/training/component/form/useStyles";
import {
  useTrainingCreateMutation,
  useTrainingDuplicateMutation,
  useTrainingEditMutation,
  useTrainingGetQuery,
} from "@feature/training/api/trainingApi";

type Props = {
  sheetUuid: string;
  trainingUuid?: string;
}

type FormData = {
  sheetUuid?: string;
  athleteUuid?: string;
  profileUuid?: string;
  protocolUuid?: string;
  exerciseUuid?: string;
  TrainingConfiguration?: TrainingConfigurationCreateManyTrainingInput[];
}

export const TrainingForm = (props: Props) => {
  const {
    classes,
    cx,
  } = useStyles();

  const dispatch = useAppDispatch();

  const trainingApi = useTrainingGetQuery({ where: { uuid: props.trainingUuid } }, { skip: !props.trainingUuid });
  const relationListApi = useRelationListQuery();

  const form = useForm<FormData>({
    defaultValues: useMemo(() => {
      return trainingApi.data;
    }, [ trainingApi.data ]),
  });

  const sheetApi = useSheetGetQuery({ where: { uuid: props.sheetUuid } });
  const athleteListApi = useAthleteListQuery();
  const profileListApi = useProfileListQuery({ where: { uuid: form.getValues("athleteUuid") } }, { skip: !form.getValues("athleteUuid") });

  const [ create ] = useTrainingCreateMutation();
  const [ edit ] = useTrainingEditMutation();
  const [ duplicate ] = useTrainingDuplicateMutation();

  const [
    athleteModal,
    setAthleteModal,
  ] = useState(false);
  const athleteModalOpen = () => {
    setAthleteModal(true);
  };
  const athleteModalClose = () => {
    setAthleteModal(false);
  };

  const [
    profileModal,
    setProfileModal,
  ] = useState(false);
  const profileModalOpen = () => {
    setProfileModal(true);
  };
  const profileModalClose = () => {
    setProfileModal(false);
  };

  const [
    protocolModal,
    setProtocolModal,
  ] = useState(false);
  const protocolModalOpen = () => {
    setProtocolModal(true);
  };
  const protocolModalClose = () => {
    setProtocolModal(false);
  };

  const [
    exerciseModal,
    setExerciseModal,
  ] = useState(false);
  const exerciseModalOpen = () => {
    setExerciseModal(true);
  };
  const exerciseModalClose = () => {
    setExerciseModal(false);
  };

  useEffect(() => {
    form.reset(trainingApi.data);
  }, [
    trainingApi.data,
    form,
  ]);

  useFormPersist(`${ STORE_FORM_TRAINING }.uuid:${ props.trainingUuid ?? "crete" }`, {
    watch: form.watch,
    setValue: form.setValue,
  });

  const [
    onlyOneProfile,
    setOnlyOneProfile,
  ] = useState(false);

  // Preselect the only profile if there is only one
  useEffect(() => {
    if (
      profileListApi.isSuccess &&
      profileListApi.data.length === 1
    ) {
      const theOnlyOneProfileUuid = profileListApi.data[0].uuid;
      form.setValue("profileUuid", theOnlyOneProfileUuid);
      setOnlyOneProfile(true);
    } else if (
      profileListApi.isSuccess &&
      athleteListApi.isSuccess &&
      profileListApi.data.length > 0
    ) {
      const theOnlyOneProfileUuid = profileListApi.data[0].uuid;
      form.setValue("profileUuid", theOnlyOneProfileUuid);
      setOnlyOneProfile(false);
    } else if (
      profileListApi.isSuccess &&
      athleteListApi.isSuccess &&
      profileListApi.data.length === 0
    ) {
      form.setValue("profileUuid", null);
      setOnlyOneProfile(false);
    } else {
      setOnlyOneProfile(false);
    }
  }, [
    form,
    profileListApi,
    athleteListApi,
  ]);

  const [
    onlyOneAthlete,
    setOnlyOneAthlete,
  ] = useState(false);

  useEffect(() => {
    if (
      athleteListApi.isSuccess &&
      athleteListApi.data.length === 1
    ) {
      const theOnlyOneAthleteUuid = athleteListApi.data[0].uuid;
      form.setValue("athleteUuid", theOnlyOneAthleteUuid);
      setOnlyOneAthlete(true);
    } else {
      setOnlyOneAthlete(false);
    }
  }, [
    form,
    athleteListApi.data,
    athleteListApi.isSuccess,
  ]);

  const formatFormConfigurationForCreate = (trainingConfiguration: TrainingConfigurationCreateManyTrainingInput): TrainingConfigurationCreateManyTrainingInput => {
    return {
      configurationUuid: trainingConfiguration.configurationUuid,
      value: trainingConfiguration.value,
    };
  };

  const formatFormConfigurationForEdit = (trainingConfiguration: TrainingConfigurationCreateManyTrainingInput): TrainingConfigurationUpdateManyWithWhereWithoutTrainingInput => {
    return {
      data: { value: { set: trainingConfiguration.value } },
      where: { configurationUuid: { equals: trainingConfiguration.configurationUuid } },
    };
  };

  const onSubmit = async(formData: FormData) => {
    const configurations = [];

    try {
      if (trainingApi.data) {
        for (const formConfiguration in formData.TrainingConfiguration) {
          configurations.push(formatFormConfigurationForEdit(formData.TrainingConfiguration[formConfiguration]));
        }
        await edit({
          data: {
            Athlete: { connect: { uuid: formData.athleteUuid } },
            Profile: { connect: { uuid: formData.profileUuid } },
            Protocol: { connect: { uuid: formData.protocolUuid } },
            Exercise: { connect: { uuid: formData.exerciseUuid } },
            TrainingConfiguration: { updateMany: configurations },
          } as TrainingUpdateInput,
          where: { uuid: props.trainingUuid },
        }).unwrap();
      } else {
        for (const formConfiguration in formData.TrainingConfiguration) {
          configurations.push(formatFormConfigurationForCreate(formData.TrainingConfiguration[formConfiguration]));
        }
        await create({
          data: {
            Athlete: { connect: { uuid: formData.athleteUuid } },
            Profile: { connect: { uuid: formData.profileUuid } },
            Protocol: { connect: { uuid: formData.protocolUuid } },
            Exercise: { connect: { uuid: formData.exerciseUuid } },
            Sheet: { connect: { uuid: props.sheetUuid } },
            TrainingConfiguration: { createMany: { data: configurations } },
          } as TrainingCreateInput,
        }).unwrap();
      }

      form.reset();
      if (sheetApi.data && sheetApi.data.isQuick) {
        dispatch(runSetSheetUuid(sheetApi.data.uuid));
        dispatch(runSetTrainingUuid(trainingApi.data.uuid));
        router.replace(routes.run.redirectPath);
        resetCurrentSerie();
      } else {
        router.replace(routes.sheetEdit.routePath.replace(getRouteUuidPlaceholder(), props.sheetUuid));
      }
    } catch (err) {
      console.error(err);
    }
  };

  const [ present ] = useIonActionSheet();
  const onDelete = async() => {
    confirmDelete({
      header: t`Delete training`,
      present: present,
      action: async() => {
        await edit({
          data: deletePayloadInput(),
          where: { uuid: props.trainingUuid },
        }).unwrap();
        dispatch(toast({ message: t`Training deleted successfully.` }));
        router.replace(routes.sheetEdit.routePath.replace(getRouteUuidPlaceholder(), props.sheetUuid));
      },
    });
  };

  const onDuplicate = async() => {
    const training = props.trainingUuid;
    await duplicate({ where: { uuid: training } }).unwrap();
    router.replace(routes.sheetEdit.routePath.replace(getRouteUuidPlaceholder(), props.sheetUuid));
    dispatch(toast({ message: t`Training duplicated` }));
  };

  // If no athlete nor profile found, redirect to the create page
  useEffect(() => {
    if (
      !athleteListApi.isSuccess ||
      !athleteListApi.data
    ) {
      return;
    }
    const athleteCount = athleteListApi.data.length;
    if (athleteCount === 0) {
      router.replace(routes.athleteCreate.redirectPath);
      dispatch(toast({ message: t`Please create an Athlete to proceed` }));
      return;
    }

    if (
      !profileListApi.isSuccess ||
      !profileListApi.data
    ) {
      return;
    }

    const profileCount = profileListApi.data.length;
    if (profileCount === 0) {
      router.replace(routes.profileCreate.routePath.replace(getRouteUuidPlaceholder("athlete"), form.getValues("athleteUuid")));
      dispatch(toast({ message: t`Please create a Profile to proceed` }));
    }
    // eslint-disable-next-line
  }, [
    athleteListApi.data,
    athleteListApi.isSuccess,
    profileListApi.data,
    profileListApi.isSuccess,
  ]);

  return <>
    <FormUi onSubmit={form.handleSubmit(onSubmit)}>
      <SectionUi rounded title={t`Training`}>
        <ListItemUi
          title={t`Athlete`}
          itemOrder={1}
          className={cx({ [classes.onlyOneAthlete]: onlyOneAthlete })}>
          {
            form.getValues("athleteUuid") ?
              <AthleteListElementComponent
                uuid={form.getValues("athleteUuid")}
                onClick={athleteModalOpen}
              /> :
              <AddItemButtonUi
                label={t`Select Athlete`}
                onClick={athleteModalOpen}
              />
          }
          <input type="hidden" {...form.register("athleteUuid", { required: true })} />
          {
            form.formState.errors.athleteUuid &&
		          <FormErrorUi error={t`This field is required`} />
          }
        </ListItemUi>
        {
          form.getValues("athleteUuid") &&
              profileListApi.isSuccess &&
              profileListApi.data &&
              <ListItemUi
                title={t`Profile`}
                itemOrder={1}
                className={cx({ [classes.onlyOneProfile]: onlyOneProfile })}
              >
                {
                  form.getValues("profileUuid") ?
                    <ProfileListElementComponent
                      uuid={form.getValues("profileUuid")}
                      onClick={profileModalOpen}
                    /> :
                    <AddItemButtonUi
                      label={t`Select Profile`}
                      onClick={profileModalOpen}
                    />
                }
                <input type="hidden" {...form.register("profileUuid", { required: true })} />
                {
                  form.formState.errors.profileUuid &&
                  <FormErrorUi error={t`This field is required`} />
                }
              </ListItemUi>
        }

        {/*this is protocol but for an understand UI we call it Protocol, need to rename it also for backend*/}
        <ListItemUi
          title={t`Protocol`}
          itemOrder={3}
          className={cx({ [classes.onlyOneAthleteAndOneProfile]: onlyOneAthlete && onlyOneProfile })}
        >
          {
            form.getValues("protocolUuid") ?
              <ProtocolListElementComponent
                uuid={form.getValues("protocolUuid")}
                onClick={protocolModalOpen}
              /> :
              <AddItemButtonUi
                label={t`Select Protocol`}
                onClick={protocolModalOpen}
              />
          }
          <input type="hidden" {...form.register("protocolUuid", { required: true })} />
          {
            form.formState.errors.protocolUuid &&
			        <FormErrorUi error={t`This field is required`} />
          }
        </ListItemUi>

        {
          form.getValues("protocolUuid") &&
            <ListItemUi title={t`Exercise`} itemOrder={4}>
              {
                form.getValues("exerciseUuid") ?
                  <ExerciseListElementComponent
                    uuid={form.getValues("exerciseUuid")}
                    onClick={exerciseModalOpen}
                  /> :
                  <AddItemButtonUi
                    label={t`Select Exercise`}
                    onClick={exerciseModalOpen}
                  />
              }
              <input type="hidden" {...form.register("exerciseUuid", { required: true })} />
              {
                form.formState.errors.exerciseUuid &&
                <FormErrorUi error={t`This field is required`} />
              }
            </ListItemUi>
        }

        {
          form.getValues("protocolUuid") &&
            form.getValues("exerciseUuid") &&
	          <ConfigurationsForm
	            protocolUuid={form.getValues("protocolUuid")}
	            exerciseUuid={form.getValues("exerciseUuid")}
	            form={form} />
        }

        <ListItemUi itemOrder={6}>
          {
            sheetApi.data &&
            sheetApi.data.isQuick &&
	          <PrimaryAccentPositiveButtonUi
		          formType={"submit"}
		          disabled={!form.formState.isValid}
		          label={t`START`}
	          />
          }

          {
            (
              !sheetApi.data ||
              !sheetApi.data.isQuick
            ) &&
            <PrimaryPositiveButtonUi
              formType={"submit"}
              disabled={!form.formState.isValid}
              label={sheetApi.data && sheetApi.data.isQuick ? t`Start` : trainingApi.data?.uuid ? t`Save` : t`Create`}
            />
          }
        </ListItemUi>

        {
          sheetApi.data &&
            !sheetApi.data.isQuick &&
            trainingApi.data?.uuid &&
	          <ListItemUi
	            title={t`Delete Training`}
	            description={t`All data related to this Training will be lost.`}
	            itemOrder={5}>
		          <PrimaryDestructiveButtonUi
	              label={t`Delete Training`}
	              onClick={onDelete} />
	          </ListItemUi>
        }

        {
          sheetApi.data &&
            !sheetApi.data.isQuick &&
            trainingApi.data?.uuid &&
	          <ListItemUi title={t`Duplicate Training`} itemOrder={4}>
		          <PrimaryButtonUi
	              label={t`Duplicate Training`}
	              onClick={onDuplicate} />
	          </ListItemUi>
        }
      </SectionUi>
    </FormUi>

    <AthleteChooseModal
      isOpen={athleteModal}
      onClose={athleteModalClose}
      onClick={athleteUuid => {
        if (form.getValues("athleteUuid") !== athleteUuid) {
          form.setValue("athleteUuid", athleteUuid);
          form.setValue("profileUuid", null);
        }
        form.clearErrors();
        athleteModalClose();
      }} />

    {
      form.getValues("athleteUuid") &&
        <ProfileChooseModal
          athleteUuid={form.getValues("athleteUuid")}
          isOpen={profileModal}
          onClose={profileModalClose}
          onClick={profileUuid => {
            form.setValue("profileUuid", profileUuid);
            form.clearErrors();
            profileModalClose();
          }} />
    }

    <ProtocolChooseModal
      isOpen={protocolModal}
      onClose={protocolModalClose}
      onClick={protocolUuid => {
        if (form.getValues("protocolUuid") !== protocolUuid) {
          form.setValue("protocolUuid", protocolUuid);
          const currentExerciseUuid = form.getValues("exerciseUuid");
          const relations = relationListApi.data;
          const currentRelations = relations.filter(relation => relation.protocolUuid === protocolUuid);
          let found = false;
          for (const currentRelation of currentRelations) {
            if (currentRelation.RelationExercise.find(exercise => exercise.exerciseUuid === currentExerciseUuid)) {
              found = true;
            }
          }
          if (!found) {
            form.setValue("exerciseUuid", null);
          }
        }
        form.clearErrors();
        protocolModalClose();
      }} />

    <ExerciseChooseModal
      isOpen={exerciseModal}
      protocolUuid={form.getValues("protocolUuid")}
      onClose={exerciseModalClose}
      onClick={exerciseUuid => {
        form.setValue("exerciseUuid", exerciseUuid);
        form.clearErrors();
        exerciseModalClose();
      }} />
  </>;
};
