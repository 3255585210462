import { LoadWeight } from "@common/model/LoadWeight";
import {
  PHASE_FEEDBACK_NULL,
  PhaseFeedback,
} from "@common/model/Phase";
import {
  PayloadAction,
  createSlice,
  isAnyOf,
} from "@reduxjs/toolkit";
import { RESET_APP_STATE } from "@core/api";
import { RootState } from "@core/redux/store";
import { STORE_RUN_KEY } from "@feature/run/runConstants";
import { deviceBleConnectByBrowserThunk } from "@feature/device/thunk/deviceThunk";
import {
  deviceConnect,
  deviceDisconnect,
  deviceDisconnectAll,
} from "@feature/device/slice/deviceSlice";

export const RUN_STATUS_READY = "READY";
export const RUN_STATUS_PREPARING = "PREPARING";
export const RUN_STATUS_RUNNING = "RUNNING";
export const RUN_STATUS_RESTING = "RESTING";
export type RunStatus =
  | typeof RUN_STATUS_READY
  | typeof RUN_STATUS_PREPARING
  | typeof RUN_STATUS_RUNNING
  | typeof RUN_STATUS_RESTING

type InitialStateModelInterface = {
  lastCalculation: number;
  sheetUuid: string;
  hasSheetUuid: boolean;
  trainingUuid: string;
  loadWeights: LoadWeight[];
  lastTrainingUuid: string;
  runStatus: RunStatus;
  phaseFeedback: PhaseFeedback;
  isAutoStartEnabled: boolean;
  isPowerReferenceAvailableAtStart: boolean;
}

const initialState: InitialStateModelInterface = {
  lastCalculation: 0,
  sheetUuid: null,
  hasSheetUuid: false,
  trainingUuid: null,
  loadWeights: [],
  lastTrainingUuid: null,
  runStatus: RUN_STATUS_READY,
  phaseFeedback: PHASE_FEEDBACK_NULL,
  isAutoStartEnabled: true,
  isPowerReferenceAvailableAtStart: false,
};

export const runSlice = createSlice({
  name: STORE_RUN_KEY,
  initialState: initialState,
  reducers: {
    updateLastCalculation: state => {
      state.lastCalculation = Date.now();
    },
    runSetSheetUuid: (state, action: PayloadAction<string>) => {
      state.sheetUuid = action.payload;
      state.hasSheetUuid = true;
      state.trainingUuid = null;
      state.lastTrainingUuid = null;
      state.runStatus = RUN_STATUS_READY;
      state.phaseFeedback = PHASE_FEEDBACK_NULL;
    },
    runUnsetSheetUuid: state => {
      state.sheetUuid = null;
      state.hasSheetUuid = false;
      state.trainingUuid = null;
      state.lastTrainingUuid = null;
      state.runStatus = RUN_STATUS_READY;
      state.phaseFeedback = PHASE_FEEDBACK_NULL;
    },
    runSetTrainingUuid: (state, action: PayloadAction<string>) => {
      state.trainingUuid = action.payload;
    },
    runSetLoadWeight: (state, action: PayloadAction<LoadWeight>) => {
      const existing = state.loadWeights.find(loadWeight => loadWeight.trainingUuid === action.payload.trainingUuid);
      if (existing) {
        existing.value = action.payload.value;
      } else {
        state.loadWeights.push(action.payload);
      }
    },
    runStart: state => {
      state.runStatus = RUN_STATUS_RUNNING;
      state.phaseFeedback = PHASE_FEEDBACK_NULL;
    },
    runStop: state => {
      state.runStatus = RUN_STATUS_READY;
      state.phaseFeedback = PHASE_FEEDBACK_NULL;
    },
    runRecover: state => {
      state.runStatus = RUN_STATUS_RESTING;
      state.phaseFeedback = PHASE_FEEDBACK_NULL;
    },
    runPrepare: (state, action /* action is used by matcher */: PayloadAction<number>) => {
      state.runStatus = RUN_STATUS_PREPARING;
      state.phaseFeedback = PHASE_FEEDBACK_NULL;
    },
    setIsAutostartEnabled: (state, action: PayloadAction<boolean>) => {
      state.isAutoStartEnabled = action.payload;
    },
    setPhaseFeedback: (state, action: PayloadAction<PhaseFeedback>) => {
      if (action.payload !== PHASE_FEEDBACK_NULL) {
        state.phaseFeedback = action.payload;
      }
    },
    setIsPowerReferenceAvailableAtStart: (state, action: PayloadAction<boolean>) => {
      state.isPowerReferenceAvailableAtStart = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(RESET_APP_STATE, () => initialState);
    builder
      .addMatcher(
        isAnyOf(
          deviceBleConnectByBrowserThunk.fulfilled,
          deviceConnect
        ), state => {
          state.runStatus = RUN_STATUS_READY;
          state.phaseFeedback = PHASE_FEEDBACK_NULL;
        })
      .addMatcher(
        isAnyOf(
          deviceDisconnect,
          deviceDisconnectAll
        ), state => {
          state.runStatus = RUN_STATUS_READY;
          state.phaseFeedback = PHASE_FEEDBACK_NULL;
        });
  },
});

export const {
  updateLastCalculation,
  runStart,
  runStop,
  runRecover,
  runPrepare,
  runSetSheetUuid,
  runUnsetSheetUuid,
  runSetTrainingUuid,
  runSetLoadWeight,
  setIsAutostartEnabled,
  setPhaseFeedback,
  setIsPowerReferenceAvailableAtStart,
} = runSlice.actions;

export const selectRunState = (state: RootState) => state[STORE_RUN_KEY];
