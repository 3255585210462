import { AlgorithmCode } from "@common/model/Algorithm";
import { ConfigurationValue } from "@common/model/ConfigurationValue";
import {
  HistorySerie,
  HistoryWorkout,
} from "@common/type-graphql/generated";
import { Point } from "@common/model/Point";
import { Serie } from "@common/model/Serie";
import { addPoints } from "@common/service/serieService";
import { calculateAlgorithm } from "@common/service/algorithmService";
import { selectRunCalibrationState } from "@feature/run/slice/runCalibrationSlice";
import { selectSettingState } from "@feature/setting/slice/settingSlice";
import { useAlgorithm } from "@feature/algorithm/hook/useAlgorithm";
import { useApiDataLogic } from "@feature/api/hook/useApiDataLogic";
import { useAppSelector } from "@core/redux/store";
import { useConfiguration } from "@feature/configuration/hook/useConfiguration";
import { useRunTraining } from "@feature/run/hook/useRunTraining";

export const useSerieCalculate = () => {
  const settingState = useAppSelector(selectSettingState);
  const runCalibrationState = useAppSelector(selectRunCalibrationState);

  const {
    endpoints: runTrainingEndpoints,
    runTraining,
  } = useRunTraining();

  const {
    endpoints: algorithmEndpoints,
    getAlgorithmByProtocolAndExercise,
  } = useAlgorithm();

  const {
    endpoints: configurationEndpoints,
    getRelationConfigurationValuesByProtocolAndExercise,
  } = useConfiguration();

  const {
    getTrainingConfigurationValues,
    getRunConfigurationValues,
    getHistoryWorkoutConfigurationValues,
    getHistorySerieConfigurationValues,
  } = useConfiguration();

  const {
    endpoints,
    isApiDataReady,
  } = useApiDataLogic([
    ...runTrainingEndpoints,
    ...algorithmEndpoints,
    ...configurationEndpoints,
  ]);

  const calculateSerie = (props: {
    points: Point[];
    protocolUuid: string;
    exerciseUuid: string;
    trainingConfigurationValues: ConfigurationValue[];
    serieConfigurationValues: ConfigurationValue[];
  }): Serie | null => {
    if (!isApiDataReady) {
      return null;
    }

    const algorithm = getAlgorithmByProtocolAndExercise(props.protocolUuid, props.exerciseUuid);
    if (!algorithm) {
      return null;
    }

    const algorithmCode = algorithm.code as AlgorithmCode;

    const serie = new Serie();
    addPoints(serie, props.points);

    const relationConfigurationValues = getRelationConfigurationValuesByProtocolAndExercise(props.protocolUuid, props.exerciseUuid);

    calculateAlgorithm({
      isLive: true,
      isCalibrating: false,
      isDebug: settingState.isDebug,
      algorithmCode: algorithmCode,
      serie: serie,
      relationConfigurationValues: relationConfigurationValues,
      trainingOrHistoryWorkoutConfigurationValues: props.trainingConfigurationValues,
      serieConfigurationValues: props.serieConfigurationValues,
    });

    return serie;
  };

  const calculateRunSerie = (props: {
    serie: Serie;
    newPoints: Point[];
  }): {
    serie: Serie;
    isCalculated: boolean;
  } => {
    const serie = props.serie;

    if (
      !isApiDataReady ||
      !runTraining
    ) {
      return {
        isCalculated: false,
        serie: serie,
      };
    }

    const protocolUuid = runTraining.protocolUuid;
    const exerciseUuid = runTraining.exerciseUuid;

    const algorithm = getAlgorithmByProtocolAndExercise(protocolUuid, exerciseUuid);
    if (!algorithm) {
      return {
        isCalculated: false,
        serie: serie,
      };
    }

    const algorithmCode = algorithm.code as AlgorithmCode;

    if (props.newPoints) {
      addPoints(serie, props.newPoints);
    }

    const relationConfigurationValues = getRelationConfigurationValuesByProtocolAndExercise(protocolUuid, exerciseUuid);
    const trainingConfigurationValues = getTrainingConfigurationValues(runTraining);
    const runConfigurationValues = getRunConfigurationValues();

    const isCalculated = calculateAlgorithm({
      isLive: true,
      isCalibrating: runCalibrationState.isCalibrating,
      isDebug: settingState.isDebug,
      algorithmCode: algorithmCode,
      serie: serie,
      relationConfigurationValues: relationConfigurationValues,
      trainingOrHistoryWorkoutConfigurationValues: trainingConfigurationValues,
      serieConfigurationValues: runConfigurationValues,
    });

    return {
      isCalculated: isCalculated,
      serie: serie,
    };
  };

  const calculateHistorySerie = (
    historySerie: HistorySerie,
    historyWorkout: HistoryWorkout
  ): Serie | null => {
    if (!isApiDataReady) {
      return null;
    }

    const protocolUuid = historyWorkout.protocolUuid;
    const exerciseUuid = historyWorkout.exerciseUuid;

    const algorithm = getAlgorithmByProtocolAndExercise(protocolUuid, exerciseUuid);
    if (!algorithm) {
      return null;
    }

    const algorithmCode = algorithm.code as AlgorithmCode;

    const serie = new Serie();
    addPoints(serie, JSON.parse(historySerie.data));

    const relationConfigurationValues = getRelationConfigurationValuesByProtocolAndExercise(protocolUuid, exerciseUuid);
    const historyWorkoutConfigurationValues = getHistoryWorkoutConfigurationValues(historyWorkout);
    const historySerieConfigurationValues = getHistorySerieConfigurationValues(historySerie);

    calculateAlgorithm({
      isLive: false,
      isCalibrating: true, // @todo put false
      isDebug: settingState.isDebug,
      algorithmCode: algorithmCode,
      serie: serie,
      relationConfigurationValues: relationConfigurationValues,
      trainingOrHistoryWorkoutConfigurationValues: historyWorkoutConfigurationValues,
      serieConfigurationValues: historySerieConfigurationValues,
    });

    return serie;
  };

  return {
    endpoints: endpoints,
    calculateSerie: calculateSerie,
    calculateRunSerie: calculateRunSerie,
    calculateHistorySerie: calculateHistorySerie,
  };
};
