
import React from "react";
import { HistorySerieUi } from "@ui/HistorySerieUi/HistorySerieUi";
import {
  getRouteUuidPlaceholder,
  routes,
} from "@core/route";
import {
  router,
} from "@core/redux/store";

type Props = {
  uuid: string;
  n: number;
}

export const HistorySerieItemComponent = (props: Props) => {
  const handleClick = () => {
    router.replace(routes.historyView.routePath.replace(getRouteUuidPlaceholder("historySerie"), props.uuid));
  };

  return <>
    <HistorySerieUi
      historySerieUuid={props.uuid}
      n={props.n}
      onClick={handleClick}
    />
  </>;
};
