import React, {
  useEffect,
  useState,
} from "react";
import useFormPersist from "react-hook-form-persist";
import { AccountCreateInputType } from "@common/type-graphql/account/input-type/account-create.input-type";
import { AccountLoginInputType } from "@common/type-graphql/account/input-type/account-login.input-type";
import { AccountLoginOtpInputType } from "@common/type-graphql/account/input-type/account-login-otp.input-type";
import { FormErrorUi } from "@ui/FormErrorUi/FormErrorUi";
import { FormUi } from "@ui/FormUi/FormUi";
import { InputTelUi } from "@ui/InputTelUi/InputUi";
import { InputUi } from "@ui/InputUi/InputUi";
import {
  IonCol,
  IonRow,
  useIonActionSheet,
} from "@ionic/react";
import {
  LINK_MARKETING,
  LINK_PRECTICAL_TEST_TERMS_AND_CONDITIONS_RIMINI_WELNESS_2024,
  STORE_FORM_ACCOUNT,
} from "@feature/account/accountConstants";
import { ListItemUi } from "@ui/ListItemUi/ListItemUi";
import { OtpModalComponent } from "@feature/account/component/otpModalComponent";
import { PLEASE_CONFIRM_OTP_ERROR_MESSAGE } from "@common/constants";
import { PrimaryPositiveButtonUi } from "@ui/PrimaryPositiveButtonUi/PrimaryPositiveButtonUi";
import { SectionUi } from "@ui/SectionUi/SectionUi";
import {
  Trans,
  t,
} from "@lingui/macro";
import { config } from "@config";
import { confirm } from "@feature/confirm/service/confirm";
import {
  emailPattern,
  passwordPattern,
} from "@common/patterns";
import {
  router,
  useAppDispatch,
} from "@core/redux/store";
import { routes } from "@core/route";
import { toast } from "@feature/toast/slice/toastSlice";
import {
  useAccountDestroyPublicMutation,
  useLoginMutation,
  useLoginOtpMutation,
  useSignupMutation,
} from "../../api/accountApi";
import { useForm } from "react-hook-form";
import "react-phone-number-input/style.css";

type FormData = {
  firstname: string;
  lastname: string;
  phoneNumber?: string;
  email?: string;
  password?: string;
  confirmPassword?: string;
  exhibition?: string;
  marketingAccepted?: string;
  dateOfBirth?: string;
  birthPlace?: string;
}

type Props = {
  exhibition?: string;
}

export const SignupForm = (props: Props) => {
  const dispatch = useAppDispatch();

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    reset,
    setFocus,
    control,
    formState: {
      errors,
      isSubmitting,
    },
  } = useForm<FormData>();

  useFormPersist(STORE_FORM_ACCOUNT, {
    watch: watch,
    setValue: setValue,
  });

  const [ signup ] = useSignupMutation();
  const [ login ] = useLoginMutation();
  const [ destroyPublic ] = useAccountDestroyPublicMutation();

  const checkRequired = (email:string, phoneNumber:string) => {
    if (!email && !phoneNumber) {
      dispatch(toast({
        message: t`Please enter at least one of the following: email or phone number`,
        color: "danger",
      }));
      return false;
    }

    if (props.exhibition && !phoneNumber) {
      dispatch(toast({
        message: t`Please enter phone number`,
        color: "danger",
      }));
      return false;
    }
    return true;
  };

  const email = watch("email");
  const phoneNumber = watch("phoneNumber");

  const [ present ] = useIonActionSheet();

  const askPhoneConfirm = (incomingFormData: FormData) => {
    confirm({
      header: t`Are you sure the number you entered is correct?`,
      subHeader: phoneNumber,
      present: present,
      action: () => {
        onSubmit(incomingFormData);
      },
    });
  };

  const onSubmit = async(incomingFormData: FormData) => {
    if (
      props.exhibition &&
      !exhibitionAccepted
    ) {
      dispatch(toast({
        message: t`Please accept the practical test conditions.`,
        color: "danger",
      }));
      return;
    }
    const formData: AccountCreateInputType = {
      firstname: incomingFormData.firstname,
      lastname: incomingFormData.lastname,
      email: incomingFormData.email || "",
      phoneNumber: incomingFormData.phoneNumber || "",
      password: incomingFormData.password,
      confirmPassword: incomingFormData.confirmPassword,
      exhibition: props.exhibition,
      marketingAccepted: marketingAccepted ? "true" : "",
      dateOfBirth: incomingFormData.dateOfBirth,
      birthPlace: incomingFormData.birthPlace,
    };

    if (!checkRequired(formData.email, formData.phoneNumber)) {
      return;
    }
    try {
      await signup({ data: formData }).unwrap();

      const accountLoginInput : AccountLoginInputType = {
        email: null,
        phoneNumber: formData.phoneNumber,
        password: formData.password,
      };
      await login({ data: accountLoginInput }).unwrap();
    } catch (error) {
      if (error.message.includes(PLEASE_CONFIRM_OTP_ERROR_MESSAGE)) {
        setIsOtpModalVisible(true);
      }
    }
  };

  const debugAutofill = config.debug.autofill && config.debug.enabled;

  const emailInitialValue = debugAutofill ? config.debug.stubData.email : "";
  const passwordInitialValue = debugAutofill ? config.debug.stubData.password : "";

  const password = watch("password");

  const [ loginOtp ] = useLoginOtpMutation();

  const [
    isOtpModalVisible,
    setIsOtpModalVisible,
  ] = useState<boolean>(false);

  const verifyOtp = async(otp: string) => {
    const loginFormData: AccountLoginOtpInputType = {
      phoneNumber: phoneNumber,
      password: password,
      otp: otp,
    };
    const loginOtpResult = await loginOtp({ data: loginFormData });
    if (loginOtpResult.hasOwnProperty("error")) {
      return false;
    }
    router.replace(routes.dashboard.redirectPath);
    return true;
  };

  const cancelOtp = () => {
    setIsOtpModalVisible(false);
    destroyPublic({
      data: {
        email: email,
        phoneNumber: phoneNumber,
        password: password,
        notifyCustomer: false,
      },
    });
  };

  useEffect(() => {
    setFocus("email");
  }, [ setFocus ]);

  const [
    exhibitionAccepted,
    setExhibitionAccepted,
  ] = React.useState<boolean>(false);

  const [
    marketingAccepted,
    setMarketingAccepted,
  ] = React.useState<boolean>(false);

  return <>
    {
      isOtpModalVisible &&
		  <OtpModalComponent
			  onVerify={verifyOtp}
			  onCancel={cancelOtp}
		  />
    }

    <FormUi onSubmit={handleSubmit(askPhoneConfirm)}>
      <SectionUi rounded title={t`Registration form`}>

        <ListItemUi title={t`Firstname`} isRequired={true} hasSeparator={true}>
          <InputUi
            type="text"
            isValid={!Boolean(errors.firstname)}
            inputMode={"text"}
            id="firstname"
            placeholder={t`First Name`}
            autoComplete={"given-name"}
            tabIndex={3}
            {...register("firstname", {
              required: true,
            })}
          />
          {
            errors.firstname &&
		        <FormErrorUi
			        error={t`Please enter a valid first name`}
		        />
          }        </ListItemUi>

        <ListItemUi title={t`Lastname`} isRequired={true} hasSeparator={true}>
          <InputUi
            type="text"
            isValid={!Boolean(errors.lastname)}
            inputMode={"text"}
            id="lastname"
            placeholder={t`Last Name`}
            autoComplete={"family-name"}
            tabIndex={5}
            {...register("lastname", {
              required: true,
            })}
          />
          {
            errors.lastname &&
		        <FormErrorUi
			        error={t`Please enter a valid last name`}
		        />
          }
        </ListItemUi>

        {
          props.exhibition &&
          <>
            <ListItemUi title={t`Date of Birth`} isRequired={true} hasSeparator={true}>
              <InputUi
                type="text"
                isValid={!Boolean(errors.dateOfBirth)}
                inputMode={"text"}
                id="dateOfBirth"
                placeholder={t`DD/MM/YYYY`}
                autoComplete={"bday"}
                tabIndex={7}
                {...register("dateOfBirth", {
                  required: true,
                })}
              />
              {
                errors.dateOfBirth &&
		            <FormErrorUi
			            error={t`Please enter a valid date of birth`}
		            />
              }
            </ListItemUi>

            <ListItemUi title={t`Birth Place`} isRequired={true} hasSeparator={true}>
              <InputUi
                type="text"
                isValid={!Boolean(errors.birthPlace)}
                inputMode={"text"}
                id="birthPlace"
                placeholder={t`City, Province and Country`}
                autoComplete={"address-level3"}
                tabIndex={9}
                {...register("birthPlace", {
                  required: true,
                })}
              />
              {
                errors.birthPlace &&
		            <FormErrorUi
			            error={t`Please enter a valid birth place`}
		            />
              }
            </ListItemUi>
          </>
        }

        <ListItemUi title={t`Email`} isRequired={true} hasSeparator={true}>
          <InputUi
            type="email"
            isValid={!Boolean(errors.email)}
            inputMode={"email"}
            id="email"
            placeholder={t`Email`}
            autoComplete={"email"}
            tabIndex={10}
            {...register("email", {
              required: true,
              pattern: emailPattern,
              value: emailInitialValue,
            })}
          />
          {
            errors.email &&
		        <FormErrorUi
			        error={t`Please enter a valid email address`}
		        />
          }
        </ListItemUi>

        <ListItemUi title={t`Phone Number`} isRequired={true} hasSeparator={true}>
          <InputTelUi
            isValid={!Boolean(errors.phoneNumber)}
            id="phoneNumber"
            name={"phoneNumber"}
            placeholder={t`Phone number`}
            tabIndex={20}
            control={control}
            rules={{
              required: true,
            }}
          />
          {
            errors.phoneNumber &&
		        <FormErrorUi
			        error={t`Please enter a valid phone number`}
		        />
          }
        </ListItemUi>

        <ListItemUi title={t`Choose a strong Password`} isRequired={true} hasSeparator={true}>
          <InputUi
            type="password"
            isValid={!Boolean(errors.password)}
            id="password"
            placeholder={t`Choose a strong Password`}
            autoComplete={"current-password"}
            tabIndex={30}
            hasTooltip
            hasViewPassword
            tooltipMessage={t`Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character from these: !"£$%&/()=@*#?_|`}
            {...register("password", {
              required: true,
              pattern: passwordPattern,
              value: passwordInitialValue,
            })}
          />
          {
            errors.password &&
            <FormErrorUi
              error={t`Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character from these: !"£$%&/()=@*#?_|`}
            />
          }
        </ListItemUi>

        <ListItemUi title={t`Confirm Password`} isRequired={true} hasSeparator={true}>
          <InputUi
            type="password"
            isValid={!Boolean(errors.confirmPassword)}
            id="confirmPassword"
            placeholder={t`Confirm Password`}
            autoComplete={"current-password"}
            tabIndex={40}
            hasViewPassword
            {...register("confirmPassword", {
              required: true,
              pattern: passwordPattern,
              value: passwordInitialValue,
              validate: value => value === password,
            })}
          />
          {
            errors.confirmPassword &&
		        <FormErrorUi
			        error={t`Passwords do not match`}
		        />
          }
        </ListItemUi>

        {
          props.exhibition &&
			    <ListItemUi title={t`Practical test`} isRequired={true} hasSeparator={true}>
			      <a
			        href={LINK_PRECTICAL_TEST_TERMS_AND_CONDITIONS_RIMINI_WELNESS_2024}
			        target={"_blank"}
			        rel="noreferrer"
			      >
			        <Trans>Read the conditions for the practical test</Trans>
			      </a>
			      <SectionUi>
			        <ListItemUi
			          title={props.exhibition}
			          description={t`Accept the practical test conditions`}
			          itemOrder={110}
			          hasSwitch={true}
			          checked={exhibitionAccepted}
			          onClick={() => {
			            setExhibitionAccepted(!exhibitionAccepted);
			          }}
			        />
			        <input type="hidden" {...register("exhibition")} />
			      </SectionUi>
			    </ListItemUi>
        }

        <ListItemUi title={t`Marketing`} isRequired={false} hasSeparator={true}>
          <a
            href={LINK_MARKETING}
            target={"_blank"}
            rel="noreferrer"
          >
            <Trans>Read the markering terms</Trans>
          </a>
          <SectionUi>
            <ListItemUi
              title={t`Contact authorizations`}
              description={t`Accept the Contact authorizations`}
              itemOrder={200}
              hasSwitch={true}
              checked={marketingAccepted}
              onClick={() => {
                setMarketingAccepted(!marketingAccepted);
              }}
            />
            <input type="hidden" {...register("marketingAccepted")} />
          </SectionUi>
        </ListItemUi>

        <ListItemUi hasSeparator={true}>

          <IonRow>
            <small>
              <Trans>* indicates mandatory fields.</Trans>
            </small>
          </IonRow>

          <IonRow>
            <IonCol>
              <PrimaryPositiveButtonUi
                formType={"submit"}
                label={t`Sing Up`}
                isLoading={isSubmitting}
              />
            </IonCol>
          </IonRow>
        </ListItemUi>
      </SectionUi>
    </FormUi>
  </>;
};
