import {
  ButtonClick,
  Callback,
  Color,
  FilledIcons,
  HeaderBackground,
  NoFilledIcons,
} from "@types-custom";
import { IconUi } from "@ui/IconUi/IconUi";
import { LoaderUi } from "@ui/LoaderUi/LoaderUi";
import {
  ReactNode,
  useRef,
  useState,
} from "react";
import { isFilled } from "@util/isFilled";
import { router } from "@core/redux/store";
import { useStyles } from "./useStyles";

export interface ButtonUiProps {
  label?: string | ReactNode;
  width?: string;
  fullWidth?: boolean;
  type: "primary" | "secondary" | "link";
  labelColor?: Color;
  backgroundColor: Color;
  icon?: FilledIcons | NoFilledIcons | "";
  iconColor?: Color;
  iconPosition?: "left" | "top";
  fontStyle: "RR16" | "RM16" | "RM12" | "RM10" | "RB12" | "OIB18" | "OIB20"| "OIB24";
  appearance: "rounded" | "circle" | "eptagon";
  hasToggle?: boolean;
  hasPressed?: boolean;
  onClick?: ButtonClick;
  onLongPress?: Callback;
  href?: string;
  isActive?: boolean;
  hasBadge?: boolean;
  badgeBackground?: HeaderBackground;
  formType?: "button" | "reset" | "submit";
  isLoading?: boolean;
  disabled?: boolean;
  className?: string;
}

export interface PrimaryButtonProps {
  label?: string | ReactNode;
  href?: string;
  labelColor?: Color;
  onClick?: ButtonClick;
  isLoading?: boolean;
  disabled?: boolean;
  formType?: "button" | "reset" | "submit";
}

export interface IconButtonProps {
  label?: string;
  href?: string;
  icon?: FilledIcons | NoFilledIcons | "";
  onClick?: ButtonClick;
  isLoading?: boolean;
  hrefReplace?: boolean;
  disabled?: boolean;
}

export interface IconOnlyButtonProps {
  icon?: FilledIcons | NoFilledIcons | "";
  onClick?: ButtonClick;
  isLoading?: boolean;
  disabled?: boolean;
}

export const ButtonUi = (props: ButtonUiProps) => {
  const [
    isToggled,
    setToggled,
  ] = useState<boolean>(false);

  const isPressing = useRef(false);
  const longPressTimer = useRef(null);

  const handleTouchStart = e => {
    if (props.disabled) {
      return;
    }
    if (
      props.onLongPress &&
      !isPressing.current
    ) {
      isPressing.current = true;
      longPressTimer.current = setTimeout(() => {
        if (isPressing.current) {
          props.onLongPress();
          isPressing.current = false;
        }
      }, 500);
    }
  };

  const handleTouchEnd = e => {
    if (longPressTimer.current) {
      clearTimeout(longPressTimer.current);
      longPressTimer.current = null;
      isPressing.current = false;
    }
  };

  const handleClick = e => {
    isPressing.current = false;
    if (longPressTimer.current) {
      return;
    }
    if (props.onClick) {
      e.stopPropagation();
      props.onClick(e);
    }

    if (props.href) {
      e.stopPropagation();
      router.replace(props.href);
    }

    if (props.hasToggle === true) {
      setToggled(!isToggled);
    }
  };

  const {
    classes, cx,
  } = useStyles({
    label: props.label,
    type: props.type ?? "primary",
    backgroundColor: props.backgroundColor,
    labelColor: props.labelColor,
    fontStyle: props.fontStyle,
    width: props.fullWidth === true ? "100%" : props.width,
    appearance: props.appearance ?? "rounded",
    iconPosition: props.iconPosition,
    hasToggle: Boolean(props.hasToggle),
  });

  return <>
    <button
      type={props.formType ?? "button"}
      formNoValidate={props.formType === "submit"}
      className={cx({
        [classes.buttonRoot]: true,
        // [classes.isPressed]: props.hasPressed && props.appearance !== "eptagon",
        [classes.isActive]: props.isActive,
      }, props.className)}
      onClick={handleClick}
      onMouseDown={handleTouchStart}
      onMouseUp={handleTouchEnd}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      disabled={props.disabled || props.isLoading}
    >
      {
        props.isLoading &&
			  <div className={classes.isLoading}>
				  <LoaderUi
					  appearance={"default"}
					  background={"transparent"} />
			  </div>
      }

      {
        props.icon &&
        props.type !== "secondary" &&
        props.appearance !== "eptagon" &&
        !props.isLoading &&
			  <IconUi
				  className={cx({
			      [classes.buttonIcon]: true,
			      [classes.isCircle]: props.appearance === "circle",
			    })}
				  isToggled={isToggled}
				  branded={false}
				  icon={(isToggled || props.isActive) && isFilled(`${ props.icon }-fill`) ? `${ props.icon }-fill` as FilledIcons : props.icon as NoFilledIcons}
				  iconColor={props.iconColor}
				  hasBadge={props.hasBadge}
				  badgeBackground={props.badgeBackground}
			  />
      }

      {
        props.label &&
        props.appearance !== "circle" &&
        !props.isLoading &&
			  <span className={cx({
			    [classes.buttonLabel]: true,
			    [classes.isToggled]: isToggled,
			  })}>
			    {props.label}
			  </span>
      }

      {
        props.appearance === "eptagon" &&
        !props.isLoading &&
			  <div className={classes.isEptagon}>
				  <svg width="63" height="63">gti
					  <use href="#ico-eptagon" />
				  </svg>
			    {
			      props.icon &&
					  <IconUi
						  isToggled={isToggled}
						  branded={props.appearance === "eptagon"}
						  position={props.iconPosition}
						  icon={props.icon}
						  badgeBackground={props.badgeBackground}
					  />
			    }
			  </div>
      }

      {/* Hidden icon for centering purposes */}
      {
        props.icon &&
        props.label &&
        props.iconPosition === "left" &&
        props.type === "primary" &&
        props.appearance !== "eptagon" &&
        !props.isLoading &&
			  <IconUi
				  className={classes.buttonIcon}
				  isToggled={false}
				  branded={false}
				  icon={props.icon}
				  hidden={true}
				  iconColor={props.iconColor}
				  badgeBackground={props.badgeBackground}
			  />
      }
    </button>
  </>;
};
