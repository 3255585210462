import {
  ButtonUi,
  PrimaryButtonProps,
} from "@ui/ButtonUi/ButtonUi";

export const PrimaryPositiveButtonUi = (
  props: PrimaryButtonProps
) => {
  return <>
    <ButtonUi
      fontStyle="RR16"
      appearance="rounded"
      backgroundColor="black"
      hasPressed
      icon=""
      iconColor="grey"
      iconPosition="left"
      labelColor="neon"
      type="primary"
      width="100%"
      {...props}
    />
  </>;
};
