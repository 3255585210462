import { API_CACHE_TAG_CALIBRATION } from "@feature/calibration/calibrationConstants";
import { Calibration } from "@common/type-graphql/generated";
import {
  api,
  getTags,
} from "@core/api";
import {
  calibrationsQuery,
  calibrationsTransformResponse,
} from "@common/type-graphql/calibration/gql/calibration-list.g";

export const calibrationApi = api.injectEndpoints({
  endpoints: builder => ({
    calibrationList: builder.query<Calibration[], void>({
      query: () => ({ document: calibrationsQuery }),
      transformResponse: calibrationsTransformResponse,
      providesTags: (result, error, input) => getTags(API_CACHE_TAG_CALIBRATION, result),
    }),
  }),
  overrideExisting: false,
});

export const { useCalibrationListQuery } = calibrationApi;
