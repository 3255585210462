import React from "react";
import { CueMessageUi } from "@ui/CueMessageUi/CueMessageUi";
import { Trans } from "@lingui/macro";
import { chevronExpandOutline } from "ionicons/icons";

export const MotionRangeMissingCueComponent = () => {
  return <>
    <CueMessageUi
      icon={chevronExpandOutline}
      title={<Trans>Motion range missing!</Trans>}
      text={<Trans>Follow the instructions of the voice guide.</Trans>}
    />
  </>;
};
