import React, { useEffect } from "react";
import { Preferences } from "@capacitor/preferences";
import {
  RESET_APP_STATE,
  api,
} from "@core/api";
import { SplashScreenUi } from "@ui/SplashScreenUi/SplashScreenUi";
import {
  logout,
  setAsInitialized,
  setIsWelcomeDone,
} from "@feature/account/slice/accountSlice";
import {
  persistor,
  router,
  useAppDispatch,
} from "@core/redux/store";
import { routes } from "@core/route";

export const AccountLogoutPage = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const performLogout = async() => {
      dispatch(logout());
      dispatch({ type: RESET_APP_STATE });

      try {
        window.sessionStorage.clear();
      } catch (e) {
        console.error(e);
      }

      try {
        window.localStorage.clear();
      } catch (e) {
        console.error(e);
      }

      try {
        persistor.purge();
      } catch (e) {
        console.error(e);
      }

      try {
        await Preferences.clear();
      } catch (e) {
        console.error(e);
      }

      try {
        dispatch(api.util.resetApiState());
      } catch (e) {
        console.error(e);
      }

      dispatch(setAsInitialized());
      dispatch(setIsWelcomeDone());

      router.replace(routes.accountLoginOrBuy.redirectPath);
    };

    const timeoutId = setTimeout(() => {
      router.replace(routes.accountLoginOrBuy.redirectPath);
    }, 3000);

    performLogout().then(() => {
      clearTimeout(timeoutId);
    });

    return () => clearTimeout(timeoutId);
  }, [ dispatch ]);

  return <>
    <div onClick={() => {
      router.replace(routes.accountLoginOrBuy.redirectPath);
    }}>
      <SplashScreenUi />
    </div>
  </>;
};
