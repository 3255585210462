import React, { useEffect } from "react";
import { ApiData } from "@feature/api/component/ApiData";
import {
  Configuration,
  Range,
  Relation,
  Training,
} from "@common/type-graphql/generated";
import { HistorySerieSwiperViewComponent } from "@feature/history/component/historySerieSwiperViewComponent";
import { PowerForm } from "@feature/power/components/form/PowerForm";
import {
  RUN_STATUS_PREPARING,
  RUN_STATUS_READY,
  RUN_STATUS_RESTING,
  RUN_STATUS_RUNNING,
  selectRunState,
} from "@feature/run/slice/runSlice";
import { RangeFormComponent } from "@feature/run/component/RangeFormComponent";
import { SeparatorUi } from "@ui/SeparatorUi/SeparatorUi";
import { SerieComponent } from "@feature/serie/component/serieComponent";
import { currentSerie } from "@feature/run/data/currentSerie";
import { selectHistoryState } from "@feature/history/slice/historySlice";
import { useApiDataLogic } from "@feature/api/hook/useApiDataLogic";
import { useAppSelector } from "@core/redux/store";
import { useConfiguration } from "@feature/configuration/hook/useConfiguration";
import { useConfigurationListQuery } from "@feature/configuration/api/configurationApi";
import { useRangeListQuery } from "@feature/range/api/rangeApi";
import { useRelationListQuery } from "@feature/relation/api/relationApi";
import { useRunAutoUpdatePowerReference } from "@feature/run/hook/useRunAutoUpdatePowerReference";
import { useRunRange } from "@feature/run/hook/useRunRange";
import { useRunTraining } from "@feature/run/hook/useRunTraining";
import { useTrainingGetQuery } from "@feature/training/api/trainingApi";

export const RunBodyComponent = () => {
  const runState = useAppSelector(selectRunState);
  const relationListApi = useRelationListQuery();
  const configurationListApi = useConfigurationListQuery();
  const trainingApi = useTrainingGetQuery({ where: { uuid: runState.trainingUuid } }, { skip: !runState.trainingUuid });
  const rangeListApi = useRangeListQuery({ where: { uuid: trainingApi.data?.athleteUuid } }, { skip: !trainingApi.isSuccess || !trainingApi.data.athleteUuid || !trainingApi.data.exerciseUuid });

  return <>
    <ApiData endpoint={[
      relationListApi,
      configurationListApi,
      trainingApi,
      rangeListApi,
    ]}>
      <RunComponentChild
        relationList={relationListApi.data}
        configurationList={configurationListApi.data}
        training={trainingApi.data}
        rangeList={rangeListApi.data}
      />
    </ApiData>
  </>;
};

type PropsChild = {
  relationList: Relation[];
  configurationList: Configuration[];
  training: Training;
  rangeList: Range[];
}

export const RunComponentChild = (props: PropsChild) => {
  const runState = useAppSelector(selectRunState);
  const historyState = useAppSelector(selectHistoryState);

  const { runCheckAutoUpdatePowerReference } = useRunAutoUpdatePowerReference();

  const {
    endpoints: rangeEndpoints,
    runRangeMinMax,
  } = useRunRange();

  const {
    endpoints: runTrainingEndpoints,
    runTraining,
  } = useRunTraining();

  const {
    endpoints: configurationEndpoints,
    getRunConfigurationValues,
    getTrainingConfigurationValues,
    getRunConfigurationPowerReferenceAutoUpdate,
    getRunConfigurationLoadWeightCurrent,
    getRunConfigurationPowerReferenceCurrent,
  } = useConfiguration();

  const {
    isApiDataReady,
  } = useApiDataLogic([
    ...rangeEndpoints,
    ...runTrainingEndpoints,
    ...configurationEndpoints,
  ]);

  const runLoadWeight = getRunConfigurationLoadWeightCurrent();
  const runPowerReference = getRunConfigurationPowerReferenceCurrent();

  const serie = currentSerie;

  /**
   * This useEffect redraw afer every lastCalculation update!
   * And check if auto power reference has to be updated
   */
  useEffect(() => {
    const powerReferenceAutoUpdate = getRunConfigurationPowerReferenceAutoUpdate();

    if (
      !runState.isPowerReferenceAvailableAtStart &&
      runState.runStatus === RUN_STATUS_RUNNING &&
      powerReferenceAutoUpdate
    ) {
      runCheckAutoUpdatePowerReference({
        serie: serie,
        athleteUuid: props.training.athleteUuid,
        exerciseUuid: props.training.exerciseUuid,
        powerReference: runPowerReference,
        loadWeight: runLoadWeight,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ runState.lastCalculation ]);

  if (!isApiDataReady) {
    return null;
  }

  const workoutConfigurationValues = getTrainingConfigurationValues(runTraining);
  const runConfigurationValues = getRunConfigurationValues();

  return <>
    {
      (
        runState.runStatus === RUN_STATUS_READY ||
        runState.runStatus === RUN_STATUS_PREPARING ||
        runState.runStatus === RUN_STATUS_RUNNING
      ) &&
      <>
	      <SerieComponent
		      serie={serie}
		      protocolUuid={props.training.protocolUuid}
		      exerciseUuid={props.training.exerciseUuid}
		      profileUuid={props.training.profileUuid}
		      workoutConfigurationValues={workoutConfigurationValues}
		      serieConfigurationValues={runConfigurationValues}
		      isLive={true}
	      />
      </>
    }

    <SeparatorUi marginTop={10} />
    {
      runState.runStatus === RUN_STATUS_RESTING &&
      <>
        {
          historyState.historyTrainingSessionUuid !== null &&
          historyState.historySerieUuid !== null &&
          <HistorySerieSwiperViewComponent
            historySerieUuid={historyState.historySerieUuid}
            profileUuid={props.training.profileUuid}
            showChips={true}
          />
        }
      </>
    }

    {
      runState.runStatus !== RUN_STATUS_RUNNING &&
      <>
        <SeparatorUi marginTop={10} />
        <RangeFormComponent
          athleteUuid={props.training.athleteUuid}
          protocolUuid={props.training.protocolUuid}
          exerciseUuid={props.training.exerciseUuid}
          loadWeight={runLoadWeight}
          range={runRangeMinMax}
        />
	      <SeparatorUi marginTop={10} />
	      <PowerForm
		      athleteUuid={props.training.athleteUuid}
		      exerciseUuid={props.training.exerciseUuid}
		      loadWeight={runLoadWeight}
		      power={runPowerReference}
	      />
      </>
    }

    {/*{*/}
    {/*  settingState.isDebug &&*/}
    {/*  <>*/}
    {/*    <SeparatorUi marginTop={10} />*/}
    {/*    <TemperatureFormComponent />*/}
    {/*  </>*/}
    {/*}*/}
  </>;
};
