import { Direction } from "@common/model/Direction";

export const PHASE_IGNORE_REASON_MANUAL = "MANUAL";
export const PHASE_IGNORE_REASON_TINY = "TINY"; // For micro movements
export const PHASE_IGNORE_REASON_SHORT = "SHORT"; // Short movement
export const PHASE_IGNORE_REASON_TOO_SHORT = "TOO_SHORT"; // Too short movement
export const PHASE_IGNORE_REASON_OVER_TOP = "OVER_TOP";
export const PHASE_IGNORE_REASON_UNDER_BOTTOM = "UNDER_BOTTOM";

export type PhaseIgnoreReason =
  | typeof PHASE_IGNORE_REASON_MANUAL
  | typeof PHASE_IGNORE_REASON_TINY
  | typeof PHASE_IGNORE_REASON_SHORT
  | typeof PHASE_IGNORE_REASON_TOO_SHORT
  | typeof PHASE_IGNORE_REASON_OVER_TOP
  | typeof PHASE_IGNORE_REASON_UNDER_BOTTOM

export const PHASES_IGNORE_REASON_VISIBLE: PhaseIgnoreReason[] = [
  PHASE_IGNORE_REASON_MANUAL,
  // PHASE_IGNORE_REASON_SHORT,
];

export type PhasePhysics = {
  duration: number;
  displacement?: number;
  power?: number;
  speed?: number;
  elevation?: number;
}

export type PhasePositions = {
  isAllInRange: boolean;
  isAllOverMaxRange: boolean;
  isAllUnderMinRange: boolean;
  overcomeMaxRange: boolean;
  overcomeMinRange: boolean;
  reachedTopRange: boolean;
  reachedBottomRange: boolean;
  reachedTopMargin: boolean;
  reachedBottomMargin: boolean;
  startsFromMinRange: boolean;
  startsFromMaxRange: boolean;
  endsInMinRange: boolean;
  endsInMaxRange: boolean;
}

export type Phase = {
  fromIndex: number;
  toIndex: number;
  fromMs: number;
  toMs: number;
  fromMm: number;
  toMm: number;
  direction: Direction;
  ignoreReason?: PhaseIgnoreReason;
  physics?: PhasePhysics;
  positions?: PhasePositions;
}

export const PHASE_FEEDBACK_NULL = null;
export const PHASE_FEEDBACK_IS_GOOD_WITH_COUNTER = "GOOD_WITH_COUNTER";
export const PHASE_FEEDBACK_IS_INVALID = "INVALID";
export const PHASE_FEEDBACK_IS_GOOD = "GOOD";
export const PHASE_FEEDBACK_IS_TOO_GOOD = "TOO_GOOD";
export const PHASE_FEEDBACK_IS_HIGH = "HIGH";
export const PHASE_FEEDBACK_IS_TOO_HIGH = "TOO_HIGH";
export const PHASE_FEEDBACK_IS_LOW = "LOW";
export const PHASE_FEEDBACK_IS_TOO_LOW = "TOO_LOW";
export const PHASE_FEEDBACK_IS_SLOW = "SLOW";
export const PHASE_FEEDBACK_IS_TOO_SLOW = "TOO_SLOW";
export const PHASE_FEEDBACK_IS_FAST = "FAST";
export const PHASE_FEEDBACK_IS_TOO_FAST = "TOO_FAST";

export type PhaseFeedback =
  | typeof PHASE_FEEDBACK_NULL
  | typeof PHASE_FEEDBACK_IS_GOOD_WITH_COUNTER
  | typeof PHASE_FEEDBACK_IS_INVALID
  | typeof PHASE_FEEDBACK_IS_GOOD
  | typeof PHASE_FEEDBACK_IS_TOO_GOOD
  | typeof PHASE_FEEDBACK_IS_HIGH
  | typeof PHASE_FEEDBACK_IS_TOO_HIGH
  | typeof PHASE_FEEDBACK_IS_LOW
  | typeof PHASE_FEEDBACK_IS_TOO_LOW
  | typeof PHASE_FEEDBACK_IS_SLOW
  | typeof PHASE_FEEDBACK_IS_TOO_SLOW
  | typeof PHASE_FEEDBACK_IS_FAST
  | typeof PHASE_FEEDBACK_IS_TOO_FAST
