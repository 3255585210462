import {
  ALGORITHM_CODE_JUMP,
  ALGORITHM_CODE_SLOW_SERIE,
  ALGORITHM_CODE_SOLO_CONCENTRIC,
  ALGORITHM_CODE_SOLO_CONCENTRIC_FULL,
  AlgorithmCode,
} from "@common/model/Algorithm";
import { ConfigurationValue } from "@common/model/ConfigurationValue";
import { Serie } from "../model/Serie";
import {
  calculatePhases,
  calculatePoints,
} from "@common/service/serieService";
import {
  getConfigurationValueFormattedKalmanQ,
  getConfigurationValueFormattedKalmanR,
  getConfigurationValueFormattedLoadWeightUsed,
  getConfigurationValueFormattedMaxDistanceTooFarFromMedianSpikes,
  getConfigurationValueFormattedMergeAfterPercent,
  getConfigurationValueFormattedMergeStallTimeUnder,
  getConfigurationValueFormattedRangeMaxReductionMm,
  getConfigurationValueFormattedRangeMaxReductionPercent,
  getConfigurationValueFormattedRangeMinReductionMm,
  getConfigurationValueFormattedRangeMinReductionPercent,
  getConfigurationValueFormattedReadsPerSeconds,
  getConfigurationValueFormattedSpeedThresholdConcentric,
  getConfigurationValueFormattedSpeedThresholdEccentric,
  getRangeMinMaxReduced,
  getRangeUsedByConfigurations,
} from "@common/service/configurationService";

const CALIBRATION_STALL_SPEED_THRESHOLD = 0.2;

interface Props {
  isLive: boolean;
  isCalibrating: boolean;
  isDebug: boolean;
  algorithmCode: AlgorithmCode;
  serie: Serie;
  relationConfigurationValues: ConfigurationValue[];
  trainingOrHistoryWorkoutConfigurationValues: ConfigurationValue[];
  serieConfigurationValues: ConfigurationValue[];
}

export const calculateAlgorithm = (props: Props): boolean => {
  switch (props.algorithmCode) {
    case ALGORITHM_CODE_SOLO_CONCENTRIC:
    case ALGORITHM_CODE_SLOW_SERIE:
    case ALGORITHM_CODE_SOLO_CONCENTRIC_FULL:
    case ALGORITHM_CODE_JUMP:
      break;
    default:
      console.log("Algorithm not implemented yet", props.algorithmCode);
      return false;
  }

  const readsPerSeconds = getConfigurationValueFormattedReadsPerSeconds(props.relationConfigurationValues);
  const maxDistanceTooFarFromMedianSpikes = getConfigurationValueFormattedMaxDistanceTooFarFromMedianSpikes(props.relationConfigurationValues);
  const kalmanQ = getConfigurationValueFormattedKalmanQ(props.relationConfigurationValues);
  const kalmanR = getConfigurationValueFormattedKalmanR(props.relationConfigurationValues);
  const speedThresholdUp = props.isCalibrating ? CALIBRATION_STALL_SPEED_THRESHOLD : getConfigurationValueFormattedSpeedThresholdConcentric(props.relationConfigurationValues);
  const speedThresholdDown = props.isCalibrating ? CALIBRATION_STALL_SPEED_THRESHOLD : getConfigurationValueFormattedSpeedThresholdEccentric(props.relationConfigurationValues);

  if (
    !readsPerSeconds ||
    !speedThresholdUp ||
    !speedThresholdDown
  ) {
    return false;
  }

  // Range
  const rangeUsed = getRangeUsedByConfigurations(props.serieConfigurationValues);
  const minReductionMmPercent = getConfigurationValueFormattedRangeMinReductionPercent(props.relationConfigurationValues);
  const maxReductionMmPercent = getConfigurationValueFormattedRangeMaxReductionPercent(props.relationConfigurationValues);
  const minReductionMm = getConfigurationValueFormattedRangeMinReductionMm(props.relationConfigurationValues);
  const maxReductionMm = getConfigurationValueFormattedRangeMaxReductionMm(props.relationConfigurationValues);
  const rangeReduced = getRangeMinMaxReduced({
    rangeMinMax: rangeUsed,
    minReductionMmPercent: minReductionMmPercent,
    maxReductionMmPercent: maxReductionMmPercent,
    minReductionMm: minReductionMm,
    maxReductionMm: maxReductionMm,
  });

  if (!calculatePoints({
    serie: props.serie,
    maxDistanceTooFarFromMedianSpikes: maxDistanceTooFarFromMedianSpikes,
    maxReadsPerSecond: readsPerSeconds,
    kalmanQ: kalmanQ,
    kalmanR: kalmanR,
    speedThresholdUp: speedThresholdUp,
    speedThresholdDown: speedThresholdDown,
    range: rangeReduced,
  })) {
    return false;
  }

  const mergeStallTimeUnder = getConfigurationValueFormattedMergeStallTimeUnder(props.relationConfigurationValues);
  const mergeAfterPercent = getConfigurationValueFormattedMergeAfterPercent(props.relationConfigurationValues);
  const loadWeight = getConfigurationValueFormattedLoadWeightUsed(props.serieConfigurationValues);

  let isCalculated = false;
  switch (props.algorithmCode) {
    case ALGORITHM_CODE_SOLO_CONCENTRIC:
    case ALGORITHM_CODE_SLOW_SERIE:
    case ALGORITHM_CODE_SOLO_CONCENTRIC_FULL:
      isCalculated = calculatePhases({
        serie: props.serie,
        isLive: props.isLive,
        mergeStallTimeUnder: mergeStallTimeUnder,
        mergeAfterPercent: mergeAfterPercent,
        loadWeight: loadWeight,
        rangeMinMax: rangeReduced,
        hasJumps: false,
      });
      break;
    case ALGORITHM_CODE_JUMP:
      isCalculated = calculatePhases({
        serie: props.serie,
        isLive: props.isLive,
        mergeStallTimeUnder: mergeStallTimeUnder,
        mergeAfterPercent: mergeAfterPercent,
        loadWeight: loadWeight,
        rangeMinMax: rangeReduced,
        hasJumps: true,
      });
      break;
  }

  if (props.isDebug) {
    console.log(JSON.parse(JSON.stringify(props.serie)));
  }

  return isCalculated;
};
