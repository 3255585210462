export const DEVICE_RANGE_MIN = 50;
export const DEVICE_RANGE_MAX = 2700;

export const MS_AROUND_TO_CALCULATE_MEDIAN = 60;
export const MS_BEHIND_TO_CALCULATE_DENSITY = 50;
export const MS_AHEAD_TO_CALCULATE_DENSITY = 30;
export const MS_AROUND_TO_CALCULATE_SPEED = 60;

export const MS_AROUND_TO_CALCULATE_MAX = Math.max(
  MS_AROUND_TO_CALCULATE_MEDIAN,
  MS_BEHIND_TO_CALCULATE_DENSITY,
  MS_AHEAD_TO_CALCULATE_DENSITY,
  MS_AROUND_TO_CALCULATE_SPEED
);

export const DENSITY_MIN_POINTS_PER_SECOND = 10; // % // todo change to 50 when gyro is ready

export const SIMPLIFIED_MAX_DELAY_BETWEEN_POINTS = 100; // ms

// How much the range can variate between each repetition
export const RANGE_CALCULATE_TOLLERANCE_BETWEEN_EACH_REPETITION_MM = 60;

//The additional value to add/subtrace to the range to avoid sound rebounce
export const RANGE_EXTENDED_READ_MM_DEFAULT = 300; // mm

export const PHASE_MIN_DISPLACEMENT_WITHOUT_RANGE = 70; // mm

export const RANGE_MARGIN_LOW_DISPLACEMENT_MARGIN = 30; // % Default margin percentage for low displacement
export const RANGE_MARGIN_HIGH_DISPLACEMENT_MARGIN = 15; // % Default margin percentage for high displacement
export const RANGE_MARGIN_LOWER_THRESHOLD = 100; // mm Default lower threshold for displacement, below this value the low displacement margin is used
export const RANGE_MARGIN_UPPER_THRESHOLD = 450; // mm Default upper threshold for displacement, above this value the high displacement margin is used

export const PHASE_INCOMPLETE_ASCENDING_FALLING_DISPLACEMENT = 100; // mm how much if returns down to ignore previous
export const PHASE_INCOMPLETE_DESCENDING_RISING_DISPLACEMENT = 100; // mm how much if returns down to ignore previous

export const AUTOSTOP_TOLLERATION_MM = 100; // mm
