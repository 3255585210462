import { ButtonClick } from "@types-custom";
import { ButtonUi } from "@ui/ButtonUi/ButtonUi";
import {
  LegacyRef,
  forwardRef,
} from "react";
import { t } from "@lingui/macro";
import { useStyles } from "./useStyles";

interface Props {
  className: string;
  onConnectClick?: ButtonClick;
}

export const NotConnectedControlsUi = forwardRef((
  props: Props,
  ref
) => {
  const {
    classes, cx,
  } = useStyles();
  return <>
    <div
      ref={ref as LegacyRef<HTMLDivElement>}
      className={cx({
        [classes.notConnectedControls]: true,
        [props.className]: props.className !== "",
      })}
    >
      <ButtonUi
        label={t`CONNECT DEVICE`}
        width="100%"
        type="primary"
        labelColor="black"
        backgroundColor="red"
        iconPosition="left"
        fontStyle="OIB24"
        appearance="rounded"
        hasToggle={false}
        hasPressed={true}
        onClick={props.onConnectClick}
        isLoading={false}
        disabled={false}
      />
    </div>
  </>;
});
