import React, { useEffect } from "react";
import { ButtonUi } from "@ui/ButtonUi/ButtonUi";
import {
  IonContent,
  IonPage,
} from "@ionic/react";
import {
  Slide,
  WelcomeCarouselUi,
} from "@ui/WelcomeCarouselUi/WelcomeCarouselUi";
import {
  router,
  useAppDispatch,
  useAppSelector,
} from "@core/redux/store";
import { routes } from "@core/route";
import {
  selectAccountState,
  setIsWelcomeDone,
} from "@feature/account/slice/accountSlice";
import {
  t,
} from "@lingui/macro";

const slides: Slide[] = [
  {
    id: 0,
    image: "/assets/welcome/welcome1.webp",
    title: t`Connect your Bodygon`,
    text: t`Separate and attach the device to the barbell or weight stack with a simple plug & play system and kick off your workout.`,
  },
  {
    id: 1,
    image: "/assets/welcome/welcome2.webp",
    title: t`Training Unlocked`,
    text: t`Train with method and science through Bodygon technology. Elevate your performance and achieve maximum results from your workouts.`,
  },
  {
    id: 2,
    image: "/assets/welcome/welcome3.webp",
    title: t`Track and analyze your progress`,
    text: t`Review your training data to keep track of your successes.`,
  },
];

export const WelcomePage = () => {
  const dispatch = useAppDispatch();

  const accountState = useAppSelector(selectAccountState);

  const startApp = async() => {
    dispatch(setIsWelcomeDone());
    if (accountState.isLoggedIn) {
      router.replace(routes.dashboard.redirectPath);
    } else {
      router.replace(routes.accountLoginOrBuy.redirectPath);
    }
  };

  useEffect(() => {
    if (
      accountState.isInitialized &&
      accountState.isLoggedIn &&
      accountState.isWelcomeDone
    ) {
      router.replace(routes.dashboard.redirectPath);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    accountState.isInitialized,
    accountState.isLoggedIn,
  ]);

  return <>
    <IonPage>
      <IonContent
        scrollX={false}
        scrollY={false}
      >
        <div
          style={{
            position: "absolute",
            zIndex: 99,
            top: "calc(var(--ion-safe-area-top) + 50px)",
            right: 50,
          }}
        >
          <ButtonUi
            label={t`Skip`}
            width="fit-content"
            type="link"
            labelColor="neon"
            backgroundColor="transparent"
            fontStyle="RB12"
            appearance="rounded"
            onClick={startApp}
          />
        </div>
        <WelcomeCarouselUi
          slides={slides}
          nextButtonLabel="next"
          onStartApp={startApp}
        />
      </IonContent>
    </IonPage>
  </>;
};
