import React, {
  useEffect,
  useState,
} from "react";
import { AccountChangePasswordInputType } from "@common/type-graphql/account/input-type/account-change-password.input-type";
import {
  IonButton,
  IonCol,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonSpinner,
  IonText,
} from "@ionic/react";
import {
  Trans,
  t,
} from "@lingui/macro";
import { config } from "@config";
import {
  eye,
  eyeOff,
} from "ionicons/icons";
import {
  passwordPattern,
} from "@common/patterns";
import {
  router,
  useAppDispatch,
} from "@core/redux/store";
import { routes } from "@core/route";
import { toast } from "@feature/toast/slice/toastSlice";
import { useChangePasswordMutation } from "../../api/accountApi";
import { useForm } from "react-hook-form";

type FormData = {
  oldPassword?: string;
  newPassword?: string;
  newPasswordConfirmation?: string;
}

const ChangePasswordForm = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (config.debug.enabled && config.debug.autofill) {
      setValue("oldPassword", config.debug.stubData.oldPassword);
      setValue("newPassword", config.debug.stubData.newPassword);
      setValue("newPasswordConfirmation", config.debug.stubData.newPasswordConfirmation);
    }
  });

  const [
    showOldPassword,
    setShowOldPassword,
  ] = useState(false);

  const [
    showNewPassword,
    setShowNewPassword,
  ] = useState(false);

  const [
    showNewPasswordConfirmation,
    setShowNewPasswordConfirmation,
  ] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    setFocus,
    formState: {
      errors,
      isSubmitting,
    },
  } = useForm<FormData>();

  const [ changePassword ] = useChangePasswordMutation();

  const toggleOldPasswordMode = () => {
    setShowOldPassword(!showOldPassword);
  };

  const toggleNewPasswordMode = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleNewPasswordConfirmationMode = () => {
    setShowNewPasswordConfirmation(!showNewPasswordConfirmation);
  };

  const onSubmit = async(formData: FormData) => {
    if (formData.newPassword !== formData.newPasswordConfirmation) {
      dispatch(toast({
        message: t`New password and New password confirmation are not equals`,
        color: "danger",
      }));
      return;
    }

    try {
      await changePassword({ data: formData as AccountChangePasswordInputType }).unwrap();
      dispatch(toast({ message: t`Password changed` }));
      router.replace(routes.accountLogin.redirectPath);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    setFocus("oldPassword");
  }, [ setFocus ]);

  return <>
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <IonList>
        <IonItem>
          <IonLabel position="stacked" color="primary">
            <Trans>Old password</Trans>
          </IonLabel>
          {showOldPassword ?
            <IonInput
              type="text"
              {...register("oldPassword", {
                required: true,
                pattern: passwordPattern,
              })}>
            </IonInput> :
            <IonInput
              type="password"
              {...register("oldPassword", {
                required: true,
                pattern: passwordPattern,
              })}>
            </IonInput>
          }
          {showOldPassword ?
            <IonIcon icon={eye} slot="end" onClick={toggleOldPasswordMode} /> :
            <IonIcon icon={eyeOff} slot="end" onClick={toggleOldPasswordMode} />
          }
          {errors.oldPassword &&
                        <IonText color="danger">
                          <Trans>Please enter a valid oldPassword. Minimum eight characters, at least one uppercase letter,
                                one lowercase letter, one number and one special character from these: !"£$%&/()=@*#?_|</Trans>
                        </IonText>
          }
        </IonItem>
      </IonList>

      <IonItem>
        <IonLabel position="stacked" color="primary">
          <Trans>New password</Trans>
        </IonLabel>
        {showNewPassword ?
          <IonInput
            type="text"
            {...register("newPassword", {
              required: true,
              pattern: passwordPattern,
            })}>
          </IonInput> :
          <IonInput
            type="password"
            {...register("newPassword", {
              required: true,
              pattern: passwordPattern,
            })}>
          </IonInput>
        }
        {showNewPassword ?
          <IonIcon icon={eye} slot="end" onClick={toggleNewPasswordMode} /> :
          <IonIcon icon={eyeOff} slot="end" onClick={toggleNewPasswordMode} />
        }
        {errors.newPassword &&
                    <IonText color="danger">
                      <Trans>Please enter a valid newPassword. Minimum eight characters, at least one uppercase letter,
                            one lowercase letter, one number and one special character from these: !"£$%&/()=@*#?_|</Trans>
                    </IonText>
        }
      </IonItem>

      <IonItem>
        <IonLabel position="stacked" color="primary">
          <Trans>New password confirmation</Trans>
        </IonLabel>
        {showNewPasswordConfirmation ?
          <IonInput
            type="text"
            {...register("newPasswordConfirmation", {
              required: true,
              pattern: passwordPattern,
            })}>
          </IonInput> :
          <IonInput
            type="password"
            {...register("newPasswordConfirmation", {
              required: true,
              pattern: passwordPattern,
            })}>
          </IonInput>
        }
        {
          showNewPasswordConfirmation ?
            <IonIcon icon={eye} slot="end" onClick={toggleNewPasswordConfirmationMode} /> :
            <IonIcon icon={eyeOff} slot="end" onClick={toggleNewPasswordConfirmationMode} />
        }
        {
          errors.newPasswordConfirmation &&
                    <IonText color="danger">
                      <Trans>Please enter a valid password confirmation. Minimum eight characters, at least one uppercase letter,
                            one lowercase letter, one number and one special character from these: !"£$%&/()=@*#?_|</Trans>
                    </IonText>
        }
      </IonItem>

      <IonRow>
        <IonCol>
          <IonButton type="submit" expand="block">
            {isSubmitting && <IonSpinner />}
            {!isSubmitting && <Trans>Submit</Trans>}
          </IonButton>
        </IonCol>
      </IonRow>
    </form>
  </>;
};

export default ChangePasswordForm;
