import { ApiData } from "@feature/api/component/ApiData";
import {
  Athlete,
  Exercise,
  Profile,
  Protocol,
  Training,
} from "@common/type-graphql/generated";
import { ButtonClick } from "@types-custom";
import { ListItemUi } from "@ui/ListItemUi/ListItemUi";
import { getExeciseTitleByCode } from "@feature/exercise/service/execiseService";
import { getProtocolNameByCode } from "@feature/protocol/service/protocolService";
import { i18n } from "@lingui/core";
import { useAthleteGetQuery } from "@feature/athlete/api/athleteApi";
import { useExerciseListQuery } from "@feature/exercise/api/exerciseApi";
import { useProfileGetQuery } from "@feature/profile/api/profileApi";
import { useProtocolListQuery } from "@feature/protocol/api/protocolApi";

type Props = {
  training: Training;
  href?: string;
  onClick?: ButtonClick;
  position?: number;
}

export const TrainingListItemComponent = (props: Props) => {
  const exerciseListApi = useExerciseListQuery();
  const protocolListApi = useProtocolListQuery();
  const profileApi = useProfileGetQuery({ where: { uuid: props.training.profileUuid } });
  const athleteApi = useAthleteGetQuery({ where: { uuid: props.training.athleteUuid } });

  return <>
    <ApiData endpoint={[
      exerciseListApi,
      protocolListApi,
      athleteApi,
      profileApi,
    ]}>
      <TrainingListItemComponentChild
        {...props}
        exerciseList={exerciseListApi.data}
        protocolList={protocolListApi.data}
        athlete={athleteApi.data}
        profile={profileApi.data}
      />
    </ApiData>
  </>;
};

type PropsChild = Props & {
  exerciseList: Exercise[];
  protocolList: Protocol[];
  athlete: Athlete;
  profile: Profile;
}

const TrainingListItemComponentChild = (props: PropsChild) => {
  const protocol = props.protocolList.find(protocol => protocol.uuid === props.training.protocolUuid);
  const exerciseCode = `${ getExeciseTitleByCode(props.exerciseList.find(exercise => exercise.uuid === props.training.exerciseUuid).code) }`;
  const description = `${ getProtocolNameByCode(protocol.code) } - ${ i18n._(props.athlete.name) } - ${ i18n._(props.profile.name) }`;
  const exerciseName = getExeciseTitleByCode(exerciseCode);

  return <>
    <ListItemUi
      title={exerciseName}
      description={description}
      href={props.href}
      onClick={props.onClick}
    />
  </>;
};
