import React, {
  useEffect,
  useMemo,
  useState,
} from "react";
import useFormPersist from "react-hook-form-persist";
import { AddItemButtonUi } from "@ui/AddItemButtonUi/AddItemButtonUi";
import { ButtonUi } from "@ui/ButtonUi/ButtonUi";
import { ColorPickerColors } from "@ui/ColorPickerUi/useLogic";
import { ColorPickerUi } from "@ui/ColorPickerUi/ColorPickerUi";
import { FormErrorUi } from "@ui/FormErrorUi/FormErrorUi";
import { FormUi } from "@ui/FormUi/FormUi";
import { InputUi } from "@ui/InputUi/InputUi";
import {
  IonIcon,
  IonItem,
  IonLabel,
  IonReorder,
  IonReorderGroup,
  useIonActionSheet,
} from "@ionic/react";
import { ListItemUi } from "@ui/ListItemUi/ListItemUi";

import { PrimaryAccentPositiveButtonUi } from "@ui/PrimaryAccentPositiveButtonUi/PrimaryAccentPositiveButtonUi";
import { PrimaryButtonUi } from "@ui/PrimaryButtonUi/PrimaryButtonUi";
import { PrimaryDestructiveButtonUi } from "@ui/PrimaryDestructiveButtonUi/PrimaryDestructiveButtonUi";
import { PrimaryPositiveButtonUi } from "@ui/PrimaryPositiveButtonUi/PrimaryPositiveButtonUi";
import { STORE_FORM_SHEET } from "@feature/sheet/sheetConstants";
import { SectionUi } from "@ui/SectionUi/SectionUi";
import { SheetCreateInput } from "@common/type-graphql/generated";
import { TrainingListItemComponent } from "@feature/training/component/trainingListItemComponent";
import { clearTrainingFormPreferences } from "@feature/training/service/trainingService";
import { confirmDelete } from "@feature/confirm/service/delete";
import { confirmDestructive } from "@feature/confirm/service/confirmDestructive";
import { deletePayloadInput } from "@util/deletePayloadInput";
import {
  getRouteUuidPlaceholder,
  routes,
} from "@core/route";
import { reorderThreeOutline } from "ionicons/icons";
import { resetCurrentSerie } from "@feature/run/data/currentSerie";
import {
  router,
  useAppDispatch,
  useAppSelector,
} from "@core/redux/store";
import {
  runSetSheetUuid,
  runSetTrainingUuid,
  runUnsetSheetUuid,
  selectRunState,
} from "@feature/run/slice/runSlice";
import { t } from "@lingui/macro";
import { toast } from "@feature/toast/slice/toastSlice";
import { useForm } from "react-hook-form";
import {
  useSheetCreateMutation,
  useSheetDuplicateMutation,
  useSheetEditMutation,
  useSheetGetQuery,
} from "@feature/sheet/api/sheetApi";
import { useStyles } from "@feature/sheet/component/form/useStyles";
import {
  useTrainingListQuery,
  useTrainingUpdateMutation,
} from "@feature/training/api/trainingApi";
import { wrapSetInput } from "@util/wrapSetInput";

type Props = {
  uuid?: string;
}

type FormData = {
  name?: string;
  color?: string;
}

export const SheetForm = (props: Props) => {
  const dispatch = useAppDispatch();
  const runState = useAppSelector(selectRunState);

  const { classes } = useStyles();

  const [ create ] = useSheetCreateMutation();
  const [ edit ] = useSheetEditMutation();
  const { data } = useSheetGetQuery({ where: { uuid: props.uuid } }, { skip: !props.uuid });
  const trainingListApi = useTrainingListQuery({ where: { uuid: props.uuid } }, { skip: !props.uuid });
  const [ update ] = useTrainingUpdateMutation();
  const [ duplicate ] = useSheetDuplicateMutation();

  const [
    trainings,
    setTrainings,
  ] = useState(trainingListApi.data);

  const {
    handleSubmit,
    register,
    setValue,
    watch,
    reset,
    formState: {
      errors,
      isSubmitting,
    },
    setFocus,
  } = useForm<FormData>({
    defaultValues: useMemo(() => {
      return data;
    }, [ data ]),
  });

  useEffect(() => {
    reset(data);
    if (!data) {
      setTimeout(() => {
        setFocus("name");
      }, 100);
    }
  }, [
    data,
    reset,
    setFocus,
  ]);

  useEffect(() => {
    if (trainingListApi.data) {
      setTrainings(trainingListApi.data);
    }
  }, [ trainingListApi.data ]);

  useFormPersist(`${ STORE_FORM_SHEET }.uuid:${ props.uuid ?? "crete" }`, {
    watch: watch,
    setValue: setValue,
  });

  const isCurrentSelected = (): boolean => {
    return runState.sheetUuid === props.uuid;
  };

  const terminate = () => {
    confirmDestructive({
      header: t`There is an active workout session! Are you sure?`,
      subHeader: t`If you select "Yes" then the active workout session will be closed.`,
      present: present,
      action: async() => {
        dispatch(runUnsetSheetUuid());
        resetCurrentSerie();
      },
    });
  };

  const startClick = () => {
    if (isCurrentSelected()) { // Chose the current one
      router.replace(routes.run.redirectPath);
    } else if (!runState.hasSheetUuid) { // Chose a new one
      resetCurrentSerie();
      setSheetUuidAndRun();
    } else { // Chose a different one from the current one
      confirmDestructive({
        header: t`There is already an active workout session! Are you sure?`,
        subHeader: t`If you select "Yes" then the active workout session will be closed and the selected will start.`,
        present: present,
        action: async() => {
          resetCurrentSerie();
          setSheetUuidAndRun();
        },
      });
    }
  };

  const setSheetUuidAndRun = () => {
    dispatch(runSetSheetUuid(props.uuid));
    const training = trainingListApi.data[0];
    dispatch(runSetTrainingUuid(training.uuid));
    router.replace(routes.run.redirectPath);
    resetCurrentSerie();
  };

  const duplicateClick = async() => {
    if (!isCurrentSelected()) {
      const sheetUuid = props.uuid;
      await duplicate({ where: { uuid: sheetUuid } }).unwrap();
      router.replace(routes.sheetList.routePath);
      dispatch(toast({ message: t`Sheet duplicated` }));
    }
  };

  const onSubmit = async(formData: FormData) => {
    try {
      if (data) {
        await edit({
          data: wrapSetInput(formData),
          where: { uuid: props.uuid },
        }).unwrap();
        dispatch(toast({ message: t`Sheet edited successfully.` }));
        router.replace(routes.sheetEdit.routePath.replace(getRouteUuidPlaceholder(), props.uuid));
      } else {
        const newSheedData: SheetCreateInput = {
          name: formData.name,
          color: "neon",
        };
        const result = await create({ data: newSheedData }).unwrap();
        dispatch(toast({ message: t`Sheet created successfully.` }));
        reset();
        router.replace(routes.trainingCreate.routePath.replace(getRouteUuidPlaceholder("sheet"), result.uuid));
      }
    } catch (err) {
      console.error(err);
    }
  };

  const [ present ] = useIonActionSheet();
  const onDelete = async() => {
    confirmDelete({
      header: t`Delete sheet`,
      present: present,
      action: async() => {
        await edit({
          data: deletePayloadInput(),
          where: { uuid: props.uuid },
        }).unwrap();
        dispatch(toast({ message: t`Sheet deleted successfully.` }));
        router.replace(routes.sheetList.redirectPath);
      },
    });
  };

  const goToTrainingCreate = async() => {
    await clearTrainingFormPreferences();
    router.replace(routes.trainingCreate.routePath.replace(getRouteUuidPlaceholder("sheet"), props.uuid));
  };

  const color = data && data.color !== undefined ? data.color : "neon";

  const doReorder = async event => {
    const newTrainings = [ ...trainings ];
    const itemToMove = newTrainings.splice(event.detail.from, 1)[0];
    newTrainings.splice(event.detail.to, 0, itemToMove);

    setTrainings(newTrainings);
    event.detail.complete();

    for (let i = 0; i < newTrainings.length; i++) {
      try {
        const sortOrder = i + 1;
        await update({
          data: { sortOrder: { set: sortOrder.toString() } },
          where: { uuid: newTrainings[i].uuid },
        }).unwrap();
      } catch (error) {
        console.error("Errore durante l'aggiornamento dell'ordine dei trainings", error);
      }
    }
  };

  return <>
    <FormUi onSubmit={handleSubmit(onSubmit)}>
      <SectionUi title={t`Informations`} rounded>
        <ListItemUi title={t`Sheet name`}>
          <InputUi
            type="text"
            id="sheet-edit-name"
            placeholder={t`Enter the Sheet name...`}
            autoComplete="off"
            isValid={!Boolean(errors.name)}
            {...register("name", { required: true })}
          />
          <FormErrorUi error={errors.name} />
        </ListItemUi>

        {
          !props.uuid &&
          <ListItemUi hasSeparator={false}>
            <PrimaryPositiveButtonUi
              formType={"submit"}
              label={t`Create`}
            />
          </ListItemUi>
        }

        {
          props.uuid &&
        <ListItemUi title={t`Color`}>
          <ColorPickerUi
            colors={[
              "neon",
              "yellow",
              "orange",
              "purple",
              "darkPurple",
              "cyan",
            ]}
            currentColor={color as ColorPickerColors}
            onChange={color => {
              setValue("color", color);
            }}
          />
          <FormErrorUi error={errors.color} />
          <input
            type="hidden"
            {...register("color", {
              required: true,
              value: color,
            })} />
        </ListItemUi>
        }
      </SectionUi>

      {
        props.uuid &&
        <SectionUi title={t`Trainings`} rounded>
          <AddItemButtonUi label={t`Training`} onClick={goToTrainingCreate} />
          <IonReorderGroup disabled={false} onIonItemReorder={doReorder}>
            {
              trainingListApi.data &&
              trainingListApi.data.map((training, i) =>
                <IonItem key={training.uuid} className={classes.listWrapper}>
                  <IonLabel className={classes.reorderlistLabelWrapper}>
                    <TrainingListItemComponent
                      training={training}
                      position={i + 1}
                      href={routes.trainingEdit.routePath
                        .replace(getRouteUuidPlaceholder("sheet"), props.uuid)
                        .replace(getRouteUuidPlaceholder(), training.uuid)}
                    />
                  </IonLabel>
                  <IonReorder className={classes.reorder} slot="start">
                    <IonIcon icon={reorderThreeOutline} size="small" />
                  </IonReorder>
                </IonItem>
              )
            }
          </IonReorderGroup>
        </SectionUi>
      }

      {
        props.uuid &&
        <SectionUi
          rounded
        >
          <ListItemUi hasSeparator={false}>
            <PrimaryPositiveButtonUi
              formType={"submit"}
              isLoading={isSubmitting}
              label={t`Save`}
            />
          </ListItemUi>
          <ListItemUi hasSeparator={false}>
            <PrimaryButtonUi
              label={t`Duplicate Sheet`}
              onClick={duplicateClick} />
          </ListItemUi>
          <ListItemUi>
            <PrimaryDestructiveButtonUi
              label={t`Delete sheet`}
              onClick={onDelete} />
          </ListItemUi>
        </SectionUi>
      }

      {
        trainingListApi.data &&
        trainingListApi.data[0] &&
		    <SectionUi
			    rounded
			    title={t`Lights on! It's Time to start.`}
		    >
			    <ListItemUi hasSeparator={false}>
				    <PrimaryAccentPositiveButtonUi
					    label={isCurrentSelected() ? t`Resume` : t`Start`}
					    onClick={startClick} />
			    </ListItemUi>
		      {
		        runState.sheetUuid &&
				    <ListItemUi hasSeparator={false}>
					    <ButtonUi
						    label={t`End training`}
						    width="100%"
						    type="primary"
						    labelColor="black"
						    backgroundColor="red"
						    iconPosition="left"
						    fontStyle="OIB24"
						    appearance="rounded"
						    hasToggle={false}
						    hasPressed={true}
						    onClick={terminate}
						    isLoading={false}
						    disabled={false}
					    />
				    </ListItemUi>
		      }
		    </SectionUi>
      }
    </FormUi>
  </>;
};
