import { Point } from "@common/model/Point";
import { config } from "@config";

const INITIAL_TIME = -1;

class DeviceReadsService {
  private reads: Point[] = [];
  private startTime: number = INITIAL_TIME;

  public getDataAndEmpty(): Point[] {
    const reads = this.reads;
    this.reads = [];
    return reads;
  }

  public add(read: Point) {
    if (this.startTime === INITIAL_TIME) {
      this.startTime = read.x;
    }
    if (this.startTime < INITIAL_TIME) {
      if (config.debug.enabled) {
        console.error("Invalid start time");
      }
      this.startTime = read.x;
    }
    if (read.x < this.startTime) {
      if (config.debug.enabled) {
        // debugger; @todo uncomment
        console.error("Invalid read time");
      }
      this.startTime = read.x;
    }

    const time = read.x - this.startTime;
    if (time > 300000) {
      if (config.debug.enabled) {
        console.error("Invalid read time, time and read:", time, read);
      }
    } else {
      this.reads.push({
        x: time,
        y: read.y,
      });
    }
  }

  public clear() {
    this.reads = [];
    this.startTime = INITIAL_TIME;
  }
}

export const deviceReadsService = new DeviceReadsService();
