import React, {
  useEffect,
  useMemo,
} from "react";
import filterObjectByClass from "@util/filterObjectByClass";
import useFormPersist from "react-hook-form-persist";
import { FormErrorUi } from "@ui/FormErrorUi/FormErrorUi";
import { FormUi } from "@ui/FormUi/FormUi";
import { InputUi } from "@ui/InputUi/InputUi";
import { ListItemUi } from "@ui/ListItemUi/ListItemUi";
import { PrimaryDestructiveButtonUi } from "@ui/PrimaryDestructiveButtonUi/PrimaryDestructiveButtonUi";
import { PrimaryPositiveButtonUi } from "@ui/PrimaryPositiveButtonUi/PrimaryPositiveButtonUi";
import { ProfileCreateInput } from "@common/type-graphql/generated";
import { STORE_FORM_PROFILE } from "@feature/profile/profileConstants";
import { SectionUi } from "@ui/SectionUi/SectionUi";
import { confirmDelete } from "@feature/confirm/service/delete";
import { deletePayloadInput } from "@util/deletePayloadInput";
import {
  getRouteUuidPlaceholder,
  routes,
} from "@core/route";
import {
  router,
  useAppDispatch,
} from "@core/redux/store";
import { t } from "@lingui/macro";
import { toast } from "@feature/toast/slice/toastSlice";
import { useForm } from "react-hook-form";
import { useIonActionSheet } from "@ionic/react";
import {
  useProfileCreateMutation,
  useProfileEditMutation,
  useProfileGetQuery,
} from "@feature/profile/api/profileApi";
import { wrapSetInput } from "@util/wrapSetInput";

type Props = {
  athleteUuid: string;
  uuid?: string;
}

class FormData {
  name?: string;
  Athlete: object;
}

export const ProfileForm = (props: Props) => {
  const dispatch = useAppDispatch();

  const [ create ] = useProfileCreateMutation();
  const [ edit ] = useProfileEditMutation();
  const { data } = useProfileGetQuery({ where: { uuid: props.uuid } }, { skip: !props.uuid });

  const {
    handleSubmit,
    register,
    setValue,
    watch,
    reset,
    setFocus,
    formState: {
      errors,
      isSubmitting,
    },
  } = useForm<FormData>({
    defaultValues: useMemo(() => {
      return data;
    }, [ data ]),
  });

  useEffect(() => {
    reset(data);
    if (!data) {
      setTimeout(() => {
        setFocus("name");
      }, 100);
    }
  }, [
    data,
    reset,
    setFocus,
  ]);

  useFormPersist(`${ STORE_FORM_PROFILE }.uuid:${ props.uuid ?? "create" }`, {
    watch: watch,
    setValue: setValue,
  });

  const onSubmit = async(rawFormData: FormData) => {
    try {
      const formData = filterObjectByClass(rawFormData, new FormData());
      if (data) {
        await edit({
          data: wrapSetInput(formData),
          where: { uuid: props.uuid },
        }).unwrap();
        dispatch(toast({ message: t`Profile created successfully.` }));
      } else {
        formData.Athlete = { "connect": { "uuid": `${ props.athleteUuid }` } };
        await create({ data: formData as ProfileCreateInput }).unwrap();
        dispatch(toast({ message: t`Profile updated successfully.` }));
      }
      reset();
      router.replace(routes.profileList.routePath.replace(getRouteUuidPlaceholder("athlete"), props.athleteUuid));
    } catch (err) {
      console.error(err);
    }
  };

  const [ present ] = useIonActionSheet();
  const onDelete = async() => {
    confirmDelete({
      header: t`Delete profile: ${ data.name }`,
      subHeader: t`By deleting this profile you'll delete also the data related to workouts done with this profile`,
      present: present,
      action: async() => {
        const deleteResult = await edit({
          data: deletePayloadInput(),
          where: { uuid: props.uuid },
        });

        if ("error" in deleteResult) {
          return;
        }

        dispatch(toast({ message: t`Profile deleted successfully.` }));
        reset();
        router.replace(routes.profileList.routePath.replace(getRouteUuidPlaceholder("athlete"), props.athleteUuid));
      },
    });
  };

  return <>
    <FormUi onSubmit={handleSubmit(onSubmit)}>
      <SectionUi title={t`Informations`} rounded>
        <ListItemUi title={t`Name`} isRequired={true}>
          <InputUi
            type="text"
            id="profile-edit-name"
            placeholder={t`type here...`}
            autoComplete="name"
            isValid={!Boolean(errors.name)}
            {...register("name", { required: true })}
          />
          <FormErrorUi error={errors.name} />
        </ListItemUi>
        {/*<ListItemUi title={t`Profile image`}>*/}
        {/*  <ImageUploaderUi buttonLabel={t`upload`} onButtonClick={dispatchTodoToast}/>*/}
        {/*</ListItemUi>*/}
        {/*<ListItemUi title={t`Birth date`}>*/}
        {/*  <DateTimePickerUi />*/}
        {/*</ListItemUi>*/}
        {/*<ListItemUi title={t`Description`}>*/}
        {/*  <TextareaUi*/}
        {/*    placeholder={t`Type here...`}*/}
        {/*    {...register("description")}*/}
        {/*    isValid={!Boolean(errors.description)}*/}
        {/*  />*/}
        {/*</ListItemUi>*/}
        <ListItemUi>
          <PrimaryPositiveButtonUi
            isLoading={isSubmitting}
            formType={"submit"}
            label={t`Save`}
          />
        </ListItemUi>
      </SectionUi>
      {
        props.uuid &&
        <SectionUi rounded>
			    <ListItemUi
				    title={t`Delete profile`}
				    description={t`By deleting this profile you'll delete also the data related to workouts done with this profile`}>
				    <PrimaryDestructiveButtonUi
              label={t`Delete profile`}
              onClick={onDelete} />
			    </ListItemUi>
		    </SectionUi>
      }
    </FormUi>
  </>;
};
