import { IonButton } from "@ionic/react";
import { Trans } from "@lingui/macro";
import {
  router,
} from "@core/redux/store";
import { routes } from "@core/route";

export const RouteNotFoundComponent = () => {
  return <>
    <div style={{
      backgroundColor: "black",
      color: "white",
      padding: "2rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      flexDirection: "column",
      height: "100vh",
      width: "100vw",

    }}>
      <Trans>Page not found!</Trans>
      <br />
      <br />
      <Trans>If nothing happens, please click here and go the Dashboard</Trans>
      <br />
      <br />
      <IonButton onClick={() => {
        router.replace(routes.dashboard.redirectPath);
      }}>
        <Trans>Go to Dashboard</Trans>
      </IonButton>
      <br />
      <br />
      <IonButton onClick={() => {
        router.replace(routes.accountLogout.redirectPath);
        setTimeout(() => {
          router.replace(routes.accountLogin.redirectPath);
        }, 1000);
      }}>
        <Trans>Logout</Trans>
      </IonButton>
    </div>
  </>;
};
