import {
  Direction,
} from "@common/model/Direction";
import { KalmanFilter } from "@common/service/kalmanFilter";
import {
  Phase,
} from "@common/model/Phase";
import { Point } from "@common/model/Point";

export class Serie {
  // Points
  originalPoints: Point[] = []; // the points received from the device
  refining: number[] = []; // the points the calculatePoints() method is working on
  refined: number[] = []; // the poits the calculatePoints() method already worked on
  finalPoints: Point[] = []; // the points received from the device with virtual points
  simplifiedPoints: Point[] = []; // the points simplified starting from the refiningPoints with less points
  refinedSpeed: number[] = [];
  median: number[] = [];
  density: number[] = [];
  speed: number[] = [];
  directions: Direction[] = [];

  // Phases
  originalPhases: Phase[] = []; // the phases generated by the speedPoints
  mergedPhases: Phase[] = []; // the phases merged by the originalPhases
  finalPhases: Phase[] = []; // the phases the calculatePhases() method is working on

  // This data is used during the live calculation
  live: SerieLive = new SerieLive();
}

export class SerieLive {
  isCalculating = false; // this track if there is a calculatePoints() method working on the serie

  kalman?: KalmanFilter = undefined;
  kalmanSpeed?: KalmanFilter = undefined;

  // Used in the calculatePoints() method
  originalPointsLastCalculatedIndex = 0; // this tracks the last calculated index
  finalPointsLastCalculatedIndex = 0; // this tracks the last calculated index

  // Used in the calculateMergedPhases() method
  originalPhasesLastCalculatedIndex = -1; // this tracks the last calculated index for originalPhases

  // Used in the calculateMergedPhases() method
  mergedPhasesLastCalculatedIndex = -1; // this tracks the last calculated index for mergedPhases

  // Used in the calculateFinalPhases() method
  finalPhasesLastCalculatedIndex = -1; // this tracks the last calculated index for finalPhases
  // finalPhasesLastDirection = DIRECTION_UNKNOWN; // this tracks the last direction

  // Feedbacks
  feedbackPhasesDoneCount = 0; // this tracks the ascending phases done count
}
