import { CalibrationCode } from "@common/model/Calibration";
import {
  PayloadAction,
  createSlice,
} from "@reduxjs/toolkit";
import { RESET_APP_STATE } from "@core/api";
import { RootState } from "@core/redux/store";
import { STORE_RUN_CALIBRATION_KEY } from "@feature/run/runConstants";

export const RUN_CALIBRATION_PHASE_INITIAL = "initial";
export const RUN_CALIBRATION_PHASE_PREPEARING = "prepearing";
export const RUN_CALIBRATION_PHASE_READING = "reading";
export const RUN_CALIBRATION_PHASE_COMPLETED = "completed";
export const RUN_CALIBRATION_PHASE_ERROR = "error";

export type RunCalibrationPhase =
  | typeof RUN_CALIBRATION_PHASE_INITIAL
  | typeof RUN_CALIBRATION_PHASE_PREPEARING
  | typeof RUN_CALIBRATION_PHASE_READING
  | typeof RUN_CALIBRATION_PHASE_COMPLETED
  | typeof RUN_CALIBRATION_PHASE_ERROR;

export type CalibrationReadPayload = {
  code: CalibrationCode;
  value: number;
}

type InitialStateModelInterface = {
  isCalibrating: boolean;
  runCalibrationPhase: RunCalibrationPhase;
  runCalibrations: CalibrationCode[];
  runCalibrationReads: CalibrationReadPayload[];
}

const initialState: InitialStateModelInterface = {
  runCalibrationPhase: RUN_CALIBRATION_PHASE_INITIAL,
  isCalibrating: false,
  runCalibrations: [],
  runCalibrationReads: [],
};

export const runCalibrationSlice = createSlice({
  name: STORE_RUN_CALIBRATION_KEY,
  initialState: initialState,
  reducers: {
    runCalibrationSetPhase: (state: InitialStateModelInterface, action: PayloadAction<RunCalibrationPhase>) => {
      state.runCalibrationPhase = action.payload;
    },
    runCalibrationSetCalibrations: (state: InitialStateModelInterface, action: PayloadAction<CalibrationCode[]>) => {
      state.isCalibrating = true;
      state.runCalibrationPhase = RUN_CALIBRATION_PHASE_PREPEARING;
      state.runCalibrations = action.payload;
      state.runCalibrationReads = [];
    },
    runCalibrationAddRead: (state: InitialStateModelInterface, action: PayloadAction<CalibrationReadPayload>) => {
      state.runCalibrationReads.push(action.payload);
      state.runCalibrations.shift();
      if (state.runCalibrations.length === 0) {
        state.runCalibrationPhase = RUN_CALIBRATION_PHASE_COMPLETED;
        state.isCalibrating = false;
      } else {
        state.runCalibrationPhase = RUN_CALIBRATION_PHASE_INITIAL;
      }
    },
    runCalibrationStop: (state: InitialStateModelInterface) => {
      state.runCalibrationPhase = RUN_CALIBRATION_PHASE_INITIAL;
      state.isCalibrating = false;
      state.runCalibrations = [];
    },
  },
  extraReducers: builder => {
    builder.addCase(RESET_APP_STATE, () => initialState);
  },
});

export const {
  runCalibrationSetPhase,
  runCalibrationSetCalibrations,
  runCalibrationAddRead,
  runCalibrationStop,
} = runCalibrationSlice.actions;

export const selectRunCalibrationState = (state: RootState) => state[STORE_RUN_CALIBRATION_KEY];
