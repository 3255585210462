import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import React from "react";
import { ButtonClick } from "@types-custom";
import { ButtonUi } from "@ui/ButtonUi/ButtonUi";
import { Control } from "react-hook-form";
import { Value } from "react-phone-number-input";
import { useStyles } from "./useStyles";

export interface InputTelUiProps {
  id: string;
  name?: string;
  rules?: object;
  placeholder?: string;
  control: Control<any>;
  onChange?: (value?: Value) => void;
  onFocus?: (value?: Value) => void;
  onBlur?: (value?: Value) => void;
  hasClean?: boolean;
  onClean?: () => void;
  isValid?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  hasViewPassword?: boolean;
  hasTooltip?: boolean;
  autoFocus?: boolean;
  tabIndex?: number;
  minValue?: number;
  maxValue?: number;
  tooltipMessage?: string;
  handleViewPasswordClick?: ButtonClick;
  handleTooltipClick?: ButtonClick;
}

export const InputTelUi = (props: InputTelUiProps) => {
  const {
    classes,
    cx,
  } = useStyles();

  const handleClean = () => {
    if (props.onClean !== undefined) {
      props.onClean();
    }
  };

  return <>
    <div className={classes.inputWrapper}>
      <PhoneInputWithCountry
        tabIndex={props.tabIndex}
        defaultCountry={"IT"}
        name={props.name ? props.name : props.id}
        rules={props.rules}
        placeholder={props.placeholder}
        control={props.control}
        {...(props.onFocus ? { onChange: props.onFocus } : {})}
        {...(props.onBlur ? { onChange: props.onBlur } : {})}
        {...(props.onChange ? { onChange: props.onChange } : {})}
        className={cx({
          [classes.inputRoot]: true,
          [classes.isInvalid]: props.isValid === false,
        })}
        disabled={props.disabled}
        readOnly={props.readOnly}
      />

      {
        props.hasClean === true &&
		    <ButtonUi
		      className={classes.cleanButton}
			    label=""
			    width="fit-content"
			    type="primary"
			    labelColor="neon"
			    backgroundColor="transparent"
			    icon="close-round"
			    iconColor="grey"
			    iconPosition="left"
			    fontStyle="RB12"
			    appearance="rounded"
			    hasPressed={true}
			    onClick={handleClean}
			    isActive={false}
		    />
      }
    </div>
  </>;
};
