import reportWebVitals from "./reportWebVitals";
import { App } from "@core/app";
import {
  StrictMode,
} from "react";
import { createRoot } from "react-dom/client";

import * as serviceWorkerRegistration from "./core/service-worker/serviceWorkerRegistration";

window.addEventListener("DOMContentLoaded", async() => {
  const container = document.getElementById("root");
  const root = createRoot(container);
  root.render(
    // StrictMode will render twice all the components
    // @see https://stackoverflow.com/questions/61254372/my-react-component-is-rendering-twice-because-of-strict-mode
    <StrictMode>
      <App />
    </StrictMode>
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://cra.link/PWA
  serviceWorkerRegistration.unregister();

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
});
