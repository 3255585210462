import { ApiData } from "@feature/api/component/ApiData";
import { HeaderRunUi } from "@ui/HeaderRunUi/HeaderRunUi";
import {
  RUN_STATUS_PREPARING,
  RUN_STATUS_RESTING,
  RUN_STATUS_RUNNING,
  selectRunState,
  setIsAutostartEnabled,
} from "@feature/run/slice/runSlice";
import {
  Range,
  Training,
} from "@common/type-graphql/generated";
import {
  router,
  useAppDispatch,
  useAppSelector,
} from "@core/redux/store";
import { routes } from "@core/route";
import { selectDeviceState } from "@feature/device/slice/deviceSlice";
import { selectTimerState } from "@feature/run/slice/runTimerSlice";
import {
  t,
} from "@lingui/macro";
import { toast } from "@feature/toast/slice/toastSlice";
import { useApiDataLogic } from "@feature/api/hook/useApiDataLogic";
import { useConfiguration } from "@feature/configuration/hook/useConfiguration";
import {
  useEffect,
  useMemo,
} from "react";
import { useRangeListQuery } from "@feature/range/api/rangeApi";
import { useRunColors } from "@feature/run/hook/useRunColors";
import { useRunFeedback } from "@feature/run/hook/useRunFeedback";
import { useRunRange } from "@feature/run/hook/useRunRange";
import { useRunStart } from "@feature/run/hook/useRunStart";
import { useTrainingGetQuery } from "@feature/training/api/trainingApi";

export const RunTopComponent = () => {
  const dispatch = useAppDispatch();
  const runState = useAppSelector(selectRunState);

  const trainingApi = useTrainingGetQuery({ where: { uuid: runState.trainingUuid } }, { skip: !runState.trainingUuid });
  const rangeListApi = useRangeListQuery({ where: { uuid: trainingApi.data?.athleteUuid } }, { skip: !trainingApi.data || !trainingApi.data.athleteUuid });

  /**
   * If no trainingUuid, redirect to history
   */
  useEffect(() => {
    if (!runState.trainingUuid) {
      router.replace(routes.historyList.redirectPath);
    }
  }, [
    dispatch,
    runState.trainingUuid,
  ]);

  return <>
    <ApiData endpoint={[ trainingApi ]}>
      <RunTopComponentChild
        rangeList={rangeListApi.data}
        training={trainingApi.data}
      />
    </ApiData>
  </>;
};

type PropsChild = {
  rangeList: Range[];
  training: Training;
}

const RunTopComponentChild = (props: PropsChild) => {
  const dispatch = useAppDispatch();

  const runState = useAppSelector(selectRunState);
  const timerState = useAppSelector(selectTimerState);
  const deviceState = useAppSelector(selectDeviceState);

  const {
    endpoints: configurationEndpoints,
    getRunConfigurationRecoveryTimeUsed,
  } = useConfiguration();

  const {
    isApiDataReady,
  } = useApiDataLogic([ ...configurationEndpoints ]);

  const {
    runGetHeaderTextColor,
    runGetHeaderBackgroundColor,
    runGetFeedbackTextColor,
  } = useRunColors();

  const {
    runGetFeedbackText,
  } = useRunFeedback();

  const { runStart } = useRunStart();
  const { runRangeMinMax } = useRunRange();
  const {
    getRunConfigurationUsePower,
    getRunConfigurationPowerReferenceCurrent,
  } = useConfiguration();

  const recoverTime = getRunConfigurationRecoveryTimeUsed();
  const headerTextColor = runGetHeaderTextColor(runState.runStatus, runState.phaseFeedback);
  const feedbackTextColor = runGetFeedbackTextColor(runState.runStatus, runState.phaseFeedback);
  const backgroundColor = runGetHeaderBackgroundColor(runState.runStatus, runState.phaseFeedback);
  const isAutoStartEnabled = useMemo(() => {
    if (!runRangeMinMax) {
      return false;
    }
    if (
      !getRunConfigurationUsePower() ||
      !getRunConfigurationPowerReferenceCurrent()
    ) {
      return false;
    }
    return true;
  }, [
    getRunConfigurationUsePower,
    getRunConfigurationPowerReferenceCurrent,
    runRangeMinMax,
  ]);

  return <>
    <HeaderRunUi
      headerTextColor={headerTextColor}
      feedbackTextColor={feedbackTextColor}
      timerDate={timerState.timerDate}
      runStatus={runState.runStatus}
      backgroundColor={backgroundColor}
      isRecoverPhase={runState.runStatus === RUN_STATUS_RESTING}
      isDeviceConnected={Boolean(deviceState.connectedDevice)}
      isRunning={runState.runStatus === RUN_STATUS_RUNNING || runState.runStatus === RUN_STATUS_PREPARING}
      recoverAmount={recoverTime}
      feedbackText={runGetFeedbackText()}
      // subMenuItems={<TrainingListComponent sheetUuid={runState.sheetUuid} />}
      onDeviceButtonClick={() => {
        router.replace(routes.deviceList.redirectPath);
      }}
      onAutoButtonClick={() => {
        !runState.isAutoStartEnabled ?
          dispatch(toast({ message: t`Autostart enabled` })) :
          dispatch(toast({ message: t`Autostart disabled` }));
        dispatch(setIsAutostartEnabled(!runState.isAutoStartEnabled));
      }}
      isAutoStartEnabled={runState.isAutoStartEnabled}
      timerCallback={isAutoStartEnabled ? () => {
        runStart({
          training: props.training,
          rangeList: props.rangeList,
          wait: 10,
        });
      } : undefined}
    />
  </>;
};
