import { ApiDataList } from "@feature/api/component/ApiDataList";
import { HeaderUi } from "@ui/HeaderUi/HeaderUi";
import {
  IonContent,
  IonPage,
} from "@ionic/react";
import { ProfileListComponent } from "@feature/profile/component/profileListComponent";
import {
  getRouteUuidPlaceholder,
  routes,
} from "@core/route";
import { getUniqueId } from "@util/getUniqueId";
import { i18n } from "@lingui/core";
import { router } from "@core/redux/store";
import { useParams } from "react-router-dom";
import { useProfileListQuery } from "@feature/profile/api/profileApi";

type Params = {
  athleteUuid?: string;
}

export const ProfileListPage = () => {
  const params = useParams<Params>();
  const profileListApi = useProfileListQuery({ where: { uuid: params.athleteUuid } });

  const goToProfileCreate = () => {
    router.replace(routes.profileCreate.routePath.replace(getRouteUuidPlaceholder("athlete"), params.athleteUuid));
  };

  return <>
    <IonPage>
      <HeaderUi
        pageTitle={i18n._(routes.profileList.title)}
        backToTitle={i18n._(routes.athleteView.title)}
        backToPath={routes.athleteView.routePath.replace(getRouteUuidPlaceholder(), params.athleteUuid)} />
      <IonContent>
        <ApiDataList
          endpoint={profileListApi}
          useRefresher={true}
          onButtonClick={goToProfileCreate}>
          <ProfileListComponent
            athleteUuid={params.athleteUuid}
            data={profileListApi.data}
            key={getUniqueId()} />
        </ApiDataList>
      </IonContent>
    </IonPage>
  </>;
};
