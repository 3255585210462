import { ERROR_DANGER_TYPE } from "@common/error/GraphQLErrorDanger";
import { ERROR_FORM_TYPE } from "@common/error/GraphQLErrorForm";
import { ERROR_MAINTENANCE_TYPE } from "@common/error/GraphQLErrorMaintenance";
import { ERROR_SILENT_TYPE } from "@common/error/GraphQLErrorSilent";
import { ERROR_UNAUTHORIZED_TYPE } from "@common/error/GraphQLErrorUnauthorized";
import { ERROR_VERSION_MANDATORY_TYPE } from "@common/error/GraphQLErrorVersionMandatory";
import { ERROR_WARNING_TYPE } from "@common/error/GraphQLErrorWarning";
import {
  Middleware,
  MiddlewareAPI,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import { Network } from "@capacitor/network";
import { PLEASE_CONFIRM_OTP_ERROR_MESSAGE } from "@common/constants";
import { router } from "@core/redux/store";
import { routes } from "@core/route";
import { t } from "@lingui/macro";
import {
  toast,
  toastUntilOk,
} from "@feature/toast/slice/toastSlice";

const ignoredErrors = [ PLEASE_CONFIRM_OTP_ERROR_MESSAGE ];

type ErrorType =
  | typeof ERROR_UNAUTHORIZED_TYPE
  | typeof ERROR_VERSION_MANDATORY_TYPE
  | typeof ERROR_MAINTENANCE_TYPE
  | typeof ERROR_DANGER_TYPE
  | typeof ERROR_WARNING_TYPE
  | typeof ERROR_FORM_TYPE
  | typeof ERROR_SILENT_TYPE

function extractErrorType(action: any): ErrorType | null {
  return action.meta?.baseQueryMeta?.response?.errors?.[0]?.extensions?.code || null;
}

export const errorHandlerMiddleware: Middleware = ({ dispatch }: MiddlewareAPI) => next => action => {
  const retryActions = [];

  if (isRejectedWithValue(action)) {
    if (ignoredErrors.some(ignoredError => action.payload.message.includes(ignoredError))) {
      return next(action);
    }

    const errorType = extractErrorType(action);
    switch (errorType) {
      case ERROR_UNAUTHORIZED_TYPE:
        dispatch(toast({
          message: t`Content inaccessible, please login again`,
          color: "danger",
        }));
        router.replace(routes.accountLogout.redirectPath);
        break;
      case ERROR_MAINTENANCE_TYPE:
        router.replace(routes.maintenance.redirectPath);
        break;
      case ERROR_VERSION_MANDATORY_TYPE:
        router.replace(routes.maintenanceVersion.redirectPath);
        break;
      case ERROR_DANGER_TYPE:
        action.meta.baseQueryMeta.response.errors.forEach((error: any) => {
          const message = error.message;
          dispatch(toastUntilOk({
            message: message,
            color: "danger",
          }));
        });
        break;
      case ERROR_SILENT_TYPE:
        break;
      case ERROR_WARNING_TYPE:
        action.meta.baseQueryMeta.response.errors.forEach((error: any) => {
          const message = error.message;
          dispatch(toastUntilOk({
            message: message,
            color: "warning",
          }));
        });
        break;
      case ERROR_FORM_TYPE:
        action.meta.baseQueryMeta.response.errors.forEach((error: any) => {
          const message = error.message;
          dispatch(toastUntilOk({
            message: message,
            color: "danger",
          }));
        });
        break;
      default:
        if (action.meta.baseQueryMeta.response.hasOwnProperty("errors")) {
          action.meta.baseQueryMeta.response.errors.forEach((error: any) => {
            const message = error.message;
            dispatch(toastUntilOk({
              message: message,
              color: "danger",
            }));
          });
        }
    }
  }

  if (
    action.error &&
    action.error.message === "Network request failed"
  ) {
    Network.getStatus().then(networkStatus => {
      retryActions.push(action);
      if (networkStatus.connected) {
        dispatch(toastUntilOk({
          message: t`The Bodygon's server is not responding, please try again later.`,
          color: "warning",
        }));
      } else {
        dispatch(toastUntilOk({
          message: t`Please check your internet connection`,
          color: "warning",
        }));
      }
    });
  }
  return next(action);
};
