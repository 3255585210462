import {
  AUDIO_FILE_POWER_REFERENCE,
  AUDIO_FILE_START,
  audioPlayCountDown,
} from "@feature/audio/service/audioService";
import {
  CALIBRATION_CODE_MAX,
  CALIBRATION_CODE_MIN,
  CalibrationCode,
} from "@common/model/Calibration";
import { CalibrationReadPayload } from "@feature/run/slice/runCalibrationSlice";
import {
  Range,
  Training,
} from "@common/type-graphql/generated";
import { RangeMinMax } from "@common/model/Range";
import { RunPageScrollContext } from "@feature/run/page/RunPage";
import {
  router,
  useAppDispatch,
} from "@core/redux/store";
import { routes } from "@core/route";
import {
  runCalibrationCalibrateInitThunk,
  runCalibrationCalibrateThunk,
} from "@feature/run/thunk/runCalibrationThunk";
import { runStartThunk } from "@feature/run/thunk/runThunk";
import {
  timerSetTime,
  timerStop,
} from "@feature/run/slice/runTimerSlice";
import {
  useCallback,
  useContext,
} from "react";
import { useConfiguration } from "@feature/configuration/hook/useConfiguration";
import { useRange } from "@feature/range/hook/useRange";
import { useRunRange } from "@feature/run/hook/useRunRange";

export type StartProps = {
  rangeList: Range[];
  training: Training;
  wait: number;
}

export const useRunStart = () => {
  const dispatch = useAppDispatch();
  const { scrollToTop } = useContext(RunPageScrollContext);
  const { setRange } = useRange();
  const { runRangeMinMax } = useRunRange();
  const {
    getRelationCalibrationsByProtocolAndExercise,
    getRunConfigurationUsePower,
    getRunConfigurationPowerReferenceCurrent,
  } = useConfiguration();

  const start = useCallback(async(props: StartProps) => {
    scrollToTop();
    router.replace(routes.run.redirectPath);

    if (runRangeMinMax) {
      setRange({
        athleteUuid: props.training.athleteUuid,
        protocolUuid: props.training.protocolUuid,
        exerciseUuid: props.training.exerciseUuid,
        rangeMinMax: runRangeMinMax,
      });

      if (
        getRunConfigurationUsePower() &&
        getRunConfigurationPowerReferenceCurrent() === null
      ) {
        dispatch(runStartThunk({
          wait: props.wait,
          saySomethingBefore: AUDIO_FILE_POWER_REFERENCE,
        }));
      } else {
        dispatch(runStartThunk({
          wait: props.wait,
          saySomethingAfter: AUDIO_FILE_START,
        }));
      }
    } else {
      const calibrationList = getRelationCalibrationsByProtocolAndExercise(props.training.protocolUuid, props.training.exerciseUuid);
      const calibrationsCode: CalibrationCode[] = calibrationList.map(c => c.code as CalibrationCode);
      await dispatch(runCalibrationCalibrateInitThunk({
        calibrationsCode: calibrationsCode,
      }));
      if (props.wait) {
        dispatch(timerSetTime(props.wait));
        await audioPlayCountDown(props.wait);
        dispatch(timerStop());
      }

      const runCalibration = await dispatch(runCalibrationCalibrateThunk());

      const calibrations = runCalibration.payload as CalibrationReadPayload[];
      if (calibrations.length) {
        const rangeMinMax: RangeMinMax = {
          min: calibrations.find(c => c.code === CALIBRATION_CODE_MIN).value,
          max: calibrations.find(c => c.code === CALIBRATION_CODE_MAX).value,
        };

        setRange({
          athleteUuid: props.training.athleteUuid,
          protocolUuid: props.training.protocolUuid,
          exerciseUuid: props.training.exerciseUuid,
          rangeMinMax: rangeMinMax,
        });
      }
    }
  }, [ scrollToTop ]);

  return {
    runStart: start,
  };
};
