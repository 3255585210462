import {
  DEVICE_RANGE_MAX,
  DEVICE_RANGE_MIN,
} from "@common/service/constants";

export type RangeMinMax = {
  min: number;
  max: number;
}

export const DEVICE_RANGE_MIN_MAX_DEFAULT : RangeMinMax = {
  min: DEVICE_RANGE_MIN,
  max: DEVICE_RANGE_MAX,
};
