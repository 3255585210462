import { ApiData } from "@feature/api/component/ApiData";
import { ListButtonUi } from "@ui/ListButtonUi/ListButtonUi";
import { getExeciseTitleByCode } from "@feature/exercise/service/execiseService";
import { useExerciseListQuery } from "@feature/exercise/api/exerciseApi";

type Props = {
  uuid?: string;
  onClick: () => void;
}

export const ExerciseListElementComponent = (props: Props) => {
  const exerciseListApi = useExerciseListQuery();
  const exerciseCode = exerciseListApi.data?.find(exercise => exercise.uuid === props.uuid)?.code;
  const exerciseCodeText = getExeciseTitleByCode(exerciseCode);

  return <>
    <ApiData endpoint={exerciseListApi}>
      <ListButtonUi
        title={exerciseCodeText}
        onClick={props.onClick}
      />
    </ApiData>
  </>;
};
