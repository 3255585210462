import { ApiData } from "@feature/api/component/ApiData";
import {
  Athlete,
  Exercise,
  Profile,
  Protocol,
  Training,
} from "@common/type-graphql/generated";
import { TrainingInfoUi } from "@ui/TrainingInfoUi/TrainingInfoUi";
import { getExeciseTitleByCode } from "@feature/exercise/service/execiseService";
import { getProtocolNameByCode } from "@feature/protocol/service/protocolService";
import { selectRunState } from "@feature/run/slice/runSlice";
import { useAppSelector } from "@core/redux/store";
import { useAthleteGetQuery } from "@feature/athlete/api/athleteApi";
import { useExerciseListQuery } from "@feature/exercise/api/exerciseApi";
import { useProfileGetQuery } from "@feature/profile/api/profileApi";
import { useProtocolListQuery } from "@feature/protocol/api/protocolApi";
import { useTrainingGetQuery } from "@feature/training/api/trainingApi";

export const TrainingSummaryComponent = () => {
  const runState = useAppSelector(selectRunState);

  const trainingApi = useTrainingGetQuery({ where: { uuid: runState.trainingUuid } }, { skip: !runState.trainingUuid });

  return <>
    <ApiData endpoint={trainingApi}>
      <TrainingSummaryComponentChild training={trainingApi.data} />
    </ApiData>
  </>;
};

type PropsChild = {
  training: Training;
}
export const TrainingSummaryComponentChild = (props: PropsChild) => {
  const athleteApi = useAthleteGetQuery({ where: { uuid: props.training.athleteUuid } });
  const profileApi = useProfileGetQuery({ where: { uuid: props.training.profileUuid } });
  const protocolListApi = useProtocolListQuery();
  const exerciseListApi = useExerciseListQuery();

  return <>
    <ApiData endpoint={[
      athleteApi,
      profileApi,
      protocolListApi,
      exerciseListApi,
    ]}>
      <TrainingSummaryInfoComponent
        athlete={athleteApi.data}
        profile={profileApi.data}
        protocolList={protocolListApi.data}
        exerciseList={exerciseListApi.data}
        training={props.training}
      />
    </ApiData>
  </>;
};

type TrainingSummaryInfoProps = {
  athlete: Athlete;
  profile: Profile;
  protocolList: Protocol[];
  exerciseList: Exercise[];
  training: Training;
}
const TrainingSummaryInfoComponent = (props: TrainingSummaryInfoProps) => {
  const protocol = props.protocolList.find(protocol => protocol.uuid === props.training.protocolUuid);
  const exercise = props.exerciseList.find(exercise => exercise.uuid === props.training.exerciseUuid);

  return <>
    <TrainingInfoUi
      infoTopLeft={props.athlete.name}
      infoTopRight={props.profile.name}
      infoBottomLeft={getProtocolNameByCode(protocol.code)}
      infoBottomRight={getExeciseTitleByCode(exercise.code)}
    />
  </>;
};
