import {
  ButtonUi,
  PrimaryButtonProps,
} from "@ui/ButtonUi/ButtonUi";

export const AddItemButtonUi = (
  props: PrimaryButtonProps
) => {
  return <>
    <ButtonUi
      width="fit-content"
      type="primary"
      labelColor="grey"
      backgroundColor="transparent"
      icon="add-round"
      iconColor="grey"
      iconPosition="left"
      fontStyle="RR16"
      appearance="rounded"
      hasToggle={false}
      hasPressed={true}
      isActive={false}
      {...props}
    />
  </>;
};
