import React, { SyntheticEvent } from "react";
import { FormUi } from "@ui/FormUi/FormUi";
import { PrimaryPositiveButtonUi } from "@ui/PrimaryPositiveButtonUi/PrimaryPositiveButtonUi";
import { TextareaUi } from "@ui/TextareaUi/TextareaUi";
import { t } from "@lingui/macro";
import { useForm } from "react-hook-form";

export interface CommentFormData {
  comment?: string;
}

type Props = {
  onSubmit: (formData: CommentFormData) => void;
}

export const CommentForm = (props: Props) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: {
      errors,
      isSubmitting,
    },
  } = useForm<CommentFormData>();

  const submit = (e: SyntheticEvent) => {
    handleSubmit(props.onSubmit)();
    reset();
    e.preventDefault();
  };

  return <>
    <FormUi onSubmit={submit}>
      <TextareaUi
        isValid={!Boolean(errors.comment)}
        placeholder={t`Comment...`}
        {...register("comment", { required: true })}
      />
      <PrimaryPositiveButtonUi
        formType={"submit"}
        isLoading={isSubmitting}
        label={t`Add comment`}
      />
    </FormUi>
  </>;
};
