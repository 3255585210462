import { STORE_RUN_KEY } from "@feature/run/runConstants";
import {
  audioPlay,
  audioPlayCountDown,
  audioStopAll,
} from "@feature/audio/service/audioService";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  deviceBleSendStart,
  deviceBleSendStopThunk,
  deviceBleSetBatteryLevelThunk,
} from "@feature/device/thunk/deviceThunk";
import { deviceReadsService } from "@feature/device/service/deviceReadsService";
import { resetCurrentSerie } from "@feature/run/data/currentSerie";
import {
  runPrepare,
  runStart,
  runStop,
} from "@feature/run/slice/runSlice";
import {
  timerSetTime,
  timerStart,
  timerStop,
  timerZero,
} from "@feature/run/slice/runTimerSlice";

export const runPrepeareThunk = createAsyncThunk(
  `${ STORE_RUN_KEY }/prepeareThunk`,
  async(args, {
    dispatch,
    getState,
  }) => {
    dispatch(timerZero());
    resetCurrentSerie();
    deviceReadsService.clear();
    dispatch(runStart());
  }
);

type RunStartThunkArgs = {
  wait: number;
  saySomethingBefore?: string;
  saySomethingAfter?: string;
}
export const runStartThunk = createAsyncThunk<void, RunStartThunkArgs>(
  `${ STORE_RUN_KEY }/startThunk`,
  async(args, {
    dispatch,
    getState,
  }) => {
    resetCurrentSerie();
    if (
      args.saySomethingBefore ||
      args.wait > 0
    ) {
      dispatch(timerStop());
      dispatch(runPrepare(args.wait));
    }
    if (args.saySomethingBefore) {
      await audioPlay(args.saySomethingBefore);
    }

    if (args.wait > 0) {
      await audioPlayCountDown(args.wait);
    }

    if (args.saySomethingAfter) {
      await audioPlay(args.saySomethingAfter);
    }

    deviceReadsService.clear();
    dispatch(runStart());
    dispatch(timerStart());
    dispatch(deviceBleSendStart());
  }
);

type RunStopThunkArgs = {
  recoverAmount: number;
  stopCalibration?: boolean;
}
export const runStopThunk = createAsyncThunk<void, RunStopThunkArgs>(
  `${ STORE_RUN_KEY }/stopThunk`,
  async(args, { dispatch }) => {
    audioStopAll();
    dispatch(deviceBleSendStopThunk());
    dispatch(runStop());
    dispatch(deviceBleSetBatteryLevelThunk());
    dispatch(timerSetTime(args.recoverAmount));
  }
);
