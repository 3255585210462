import {
  API_CACHE_TAG_ACCOUNT,
  STORE_ACCOUNT_KEY,
} from "@feature/account/accountConstants";
import { API_CACHE_TAG_ALGORITHM } from "@feature/algorithm/algorithmConstants";
import {
  API_CACHE_TAG_ATHLETE,
  API_CACHE_TAG_ATHLETE_LIST_LINKED,
} from "@feature/athlete/athleteConstants";
import { API_CACHE_TAG_CALIBRATION } from "@feature/calibration/calibrationConstants";
import { API_CACHE_TAG_CHART } from "@feature/chart/chartConstants";
import { API_CACHE_TAG_CONFIGURATION } from "@feature/configuration/configurationConstants";
import { API_CACHE_TAG_EXERCISE } from "@feature/exercise/exerciseConstants";
import {
  API_CACHE_TAG_HISTORY_SERIE,
  API_CACHE_TAG_HISTORY_SERIE_COMMENT,
  API_CACHE_TAG_HISTORY_SERIE_LIST_LINKED,
  API_CACHE_TAG_HISTORY_TRAINING_SESSION,
  API_CACHE_TAG_HISTORY_TRAINING_SESSION_LINKED,
  API_CACHE_TAG_HISTORY_WORKOUT,
} from "@feature/history/historyConstants";
import { API_CACHE_TAG_POWER } from "@feature/power/powerConstants";
import { API_CACHE_TAG_PROFILE } from "@feature/profile/profileConstants";
import { API_CACHE_TAG_PROTOCOL } from "@feature/protocol/protocolConstants";
import { API_CACHE_TAG_RANGE } from "@feature/range/rangeConstants";
import { API_CACHE_TAG_RELATION } from "@feature/relation/relationConstants";
import { API_CACHE_TAG_SHEET } from "@feature/sheet/sheetConstants";
import { API_CACHE_TAG_TRAINING } from "@feature/training/trainingConstants";
import { HEADER_VERSION_REQUEST } from "@common/constants";
import { OfflinePayload } from "@feature/offline/types-custom";
import { REHYDRATE } from "redux-persist";
import { RootState } from "@core/redux/store";
import { config } from "@config";
import { createApi } from "@reduxjs/toolkit/query/react";
import { getAppVersion } from "@feature/api/service/apiService";
import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";
import {
  historyOffline,
  historyOnline,
} from "@feature/history/api/historyOffline";

/**
 * Represents the action type for resetting the application state.
 */
export const RESET_APP_STATE = "RESET_APP_STATE";

export const API_CACHE_TAG_LIST_VALUE = "LIST";

const API_BASE_URL = config.server.baseUrl + config.server.graphqlEndpoint;

export const tagTypes = [
  API_CACHE_TAG_ACCOUNT,
  API_CACHE_TAG_ATHLETE,
  API_CACHE_TAG_ATHLETE_LIST_LINKED,
  API_CACHE_TAG_PROFILE,
  API_CACHE_TAG_SHEET,
  API_CACHE_TAG_PROTOCOL,
  API_CACHE_TAG_EXERCISE,
  API_CACHE_TAG_RELATION,
  API_CACHE_TAG_TRAINING,
  API_CACHE_TAG_CONFIGURATION,
  API_CACHE_TAG_CALIBRATION,
  API_CACHE_TAG_CHART,
  API_CACHE_TAG_ALGORITHM,
  API_CACHE_TAG_HISTORY_TRAINING_SESSION,
  API_CACHE_TAG_HISTORY_TRAINING_SESSION_LINKED,
  API_CACHE_TAG_HISTORY_WORKOUT,
  API_CACHE_TAG_HISTORY_SERIE,
  API_CACHE_TAG_HISTORY_SERIE_LIST_LINKED,
  API_CACHE_TAG_RANGE,
  API_CACHE_TAG_POWER,
  API_CACHE_TAG_HISTORY_SERIE_COMMENT,
];

export const offlineMiddlewares: OfflinePayload[] = [ historyOffline ];
export const onlineMiddlewares: OfflinePayload[] = [ historyOnline ];

export const api = createApi({
  keepUnusedDataFor: 64800, // 18 hours
  baseQuery: graphqlRequestBaseQuery({
    url: API_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const state : RootState = getState() as RootState;
      const isLoggedIn = state[STORE_ACCOUNT_KEY].isLoggedIn;
      if (isLoggedIn) {
        const token = state[STORE_ACCOUNT_KEY].token;
        if (token) {
          headers.set("authorization", `Bearer ${ token }`);
        }
      }
      headers.set(HEADER_VERSION_REQUEST, getAppVersion().toString());
      return headers;
    },
  }),
  tagTypes: tagTypes,
  extractRehydrationInfo: (action, { reducerPath }) => {
    if (action.type === REHYDRATE) {
      return action.payload?.[reducerPath];
    }
    return undefined;
  },
  endpoints: () => ({}),
});

export const getTags = <T extends string, R extends { uuid: string }[] | { uuid: string } | undefined>(
  tagType: T,
  resultsWithIds?: R | undefined
) => {
  if (resultsWithIds === undefined) {
    return [
      {
        type: tagType,
        id: API_CACHE_TAG_LIST_VALUE,
      },
    ];
  }

  if (resultsWithIds instanceof Array) {
    return [
      {
        type: tagType,
        id: API_CACHE_TAG_LIST_VALUE,
      },
      ...resultsWithIds.map(({ uuid }) => ({
        type: tagType,
        id: uuid,
      })),
    ];
  }

  return resultsWithIds ? [
    {
      type: tagType,
      id: API_CACHE_TAG_LIST_VALUE,
    },
    {
      type: tagType,
      id: resultsWithIds.uuid,
    },
  ] : [];
};
